import { triviaTeams } from 'api';
import { toast } from 'react-toastify';
import { GET_CHANNEL_USER_COUNT, SET_GOTCHA_LOADING } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const GetChannelUsersCount = (tenantId, serviceUrl, conversationId) => async (dispatch) => {
  dispatch({ type: SET_GOTCHA_LOADING, payload: true });
  const data = await triviaTeams.get(
    `/task-module/channel-users-count?tenantId=${tenantId}&serviceUrl=${serviceUrl}&conversationId=${conversationId}`
  );
  if (data.ok) {
    dispatch({
      type: GET_CHANNEL_USER_COUNT,
      payload: data.usersCount
    });
  } else {
    toast.error(data.message || 'Failed to get channel user count');
    dispatch({ type: SET_GOTCHA_LOADING, payload: false });
  }
};
