const dropdowns = {
  difficulty: [
    { content: 'Random', key: 'Random' },
    { content: 'Easy', key: 'Easy' },
    { content: 'Medium', key: 'Medium' },
    { content: 'Hard', key: 'Hard' }
  ],
  negativemarking: ['Yes', 'No'],
  speed: [
    { content: '10s per question', key: '10' },
    { content: '20s per question', key: '20' },
    { content: '30s per question', key: '30' },
    { content: '40s per question', key: '40' }
  ],
  virtualCoffeeFrequency: [
    { content: 'Every two weeks', key: '2' },
    { content: 'Every four weeks', key: '4' },
    { content: 'Once a quarter', key: '12' }
  ],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
};

export default dropdowns;
