import {
  GET_ALL_ADMINS,
  PLAN_NAME,
  SUBSCRIPTION_DATA,
  USER_DETAILS,
  ENGAGEMENT_DATA
} from '../types';

const INITIAL_STATE = {
  planName: null,
  admins: [],
  subscriptionDetails: {},
  enagagementData: {},
  userDetails: {}
};

const homeReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case PLAN_NAME:
      return { ...state, planName: action.payload };
    case SUBSCRIPTION_DATA:
      return { ...state, subscriptionDetails: action.payload };
    case ENGAGEMENT_DATA:
      return { ...state, enagagementData: action.payload };
    case USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case GET_ALL_ADMINS:
      return { ...state, admins: action.payload };
    default:
      return state;
  }
};

export default homeReducer;
