import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mantine/core';
import './styles.scss';
import { ReactComponent as Cracker } from 'assets/images/cracker.svg';
import Avatar from 'assets/images/avatar.svg';

const GameCenterTags = ({ showPlayed, isPastActivity }) => (
  <div className='user-tags'>
    <div className='myrank-tag'>Your rank</div>
    <div className='right'>
      {showPlayed && (
        <div className='played-tag'>{isPastActivity ? 'Correct answers' : 'Played'}</div>
      )}
      <div className='score-tag'>
        <span className='hide'>Your</span> Score
      </div>
    </div>
  </div>
);

GameCenterTags.propTypes = {
  showPlayed: PropTypes.bool,
  isPastActivity: PropTypes.bool
};

GameCenterTags.defaultProps = {
  showPlayed: true,
  isPastActivity: false
};

const UserRank = ({ userData, isPastActivity, ...props }) => {
  const { rank, photo, name, data, error } = userData;
  const { gameCenter, isResult } = props;
  let message = '';

  if (error) {
    return (
      <div className={`myRankWrapper ${gameCenter && 'gameCenter'}`}>
        {gameCenter ? (
          <GameCenterTags showPlayed={props.showPlayed} isPastActivity={isPastActivity} />
        ) : (
          <div className='myrank-tag'>Your rank</div>
        )}
        <div className='myrank-content'>
          <div className='photo-text-wrapper'>{error}</div>
        </div>
      </div>
    );
  }

  switch (rank) {
    // eslint-disable-next-line
    case 1:
    case 2:
    case 3:
      if (parseFloat(data?.score).toFixed(2) > 0) {
        message = (
          <div data-testid='myrank-text' className='myrank-text'>
            <Cracker className='cracker' />
            <div>
              {isResult ? (
                <div>
                  Congratulations, {rank === 1 ? 'you came in first.' : 'you are on the podium.'}
                </div>
              ) : (
                <div>
                  Congratulations,{' '}
                  {rank === 1 ? "you're currently first." : 'you are on the podium.'}
                </div>
              )}
            </div>
            <Cracker className='cracker' />
          </div>
        );
        break;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      message = (
        <Grid>
          <Grid.Col span={1} style={{ justifyContent: 'flex-start' }}>
            {rank}
          </Grid.Col>

          <Grid.Col span={6} style={{ textAlign: 'center', justifyContent: 'center' }}>
            <div className='user-phone-name'>
              <img
                src={photo || Avatar || `https://ui-avatars.com/api/?name=${encodeURI(name)}`}
                alt='user'
              />
              <div>{name || `My name`}</div>
            </div>
          </Grid.Col>

          <Grid.Col span={2} className='played' style={{ textAlign: 'center' }}>
            {data?.played}
          </Grid.Col>

          <Grid.Col span={2} style={{ textAlign: 'center' }}>
            {parseFloat(data?.score).toFixed(2)}
          </Grid.Col>
        </Grid>
      );
  }

  return (
    <div className={`myRankWrapper ${gameCenter && 'gameCenter'}`}>
      {gameCenter ? (
        <GameCenterTags showPlayed={props.showPlayed} isPastActivity={isPastActivity} />
      ) : (
        <div className='myrank-tag'>Your rank</div>
      )}
      <div className='myrank-content'>
        <div className='photo-text-wrapper'>{message}</div>
      </div>
    </div>
  );
};

UserRank.propTypes = {
  userData: PropTypes.shape().isRequired,
  gameCenter: PropTypes.bool,
  isResult: PropTypes.bool,
  showPlayed: PropTypes.bool,
  isPastActivity: PropTypes.bool
};

UserRank.defaultProps = {
  gameCenter: false,
  isResult: false,
  showPlayed: true,
  isPastActivity: false
};

export default UserRank;
