import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Image } from '@fluentui/react-components';
import assignLicense from 'assets/images/assign-license.svg';
import arrowWhite from 'assets/images/arrow-white.svg';
import requestLicense from 'assets/images/request-license.svg';
import useWindowSize from 'utils/hooks';
import styles from './License.module.scss';

const UnassignedLicense = (props) => {
  const { darkTheme, isAdmin, quantity, userName, admins } = props;
  const { width } = useWindowSize();
  const assignLicenseUrl = `${process.env.REACT_APP_DASHBOARD_URL}billing`;
  const titleMessage = isAdmin
    ? `Hooray, you're almost set ${userName}!`
    : 'Your admin has not assigned you a License';

  const adminsNames = admins.map((admin, index) => (
    <React.Fragment key={admin.id}>
      {index > 0 && (index === admins.length - 1 ? ' or ' : ', ')}
      <span className={styles.red}>{admin.name}</span>
    </React.Fragment>
  ));

  return (
    <div className={classNames(styles.wrapper, { [styles.darkTheme]: darkTheme })}>
      <div>
        <div className={styles.licenses} style={{ borderColor: isAdmin ? '#9277ff' : 'red' }}>
          {isAdmin
            ? `${quantity} ${quantity === 1 ? 'License' : 'Licenses'} Unassigned`
            : 'Request License'}
        </div>
        <div className={styles.contentDiv} style={{ borderColor: isAdmin ? '#9277ff' : 'red' }}>
          <div className={styles.textDiv}>
            <div>{titleMessage}</div>
            <div className={styles.subTitle}>
              {isAdmin ? (
                `To active Trivia for your teammates, assign them Licenses from here.`
              ) : (
                <div>Request a Trivia admin - {adminsNames} - to assign you a License.</div>
              )}
            </div>
          </div>
          {isAdmin && (
            <Button
              className={styles.assignbutton}
              onClick={() => window.open(assignLicenseUrl)}
              appearance='primary'
              data-testid='assign-licenses-btn-1'
            >
              Assign Licenses
            </Button>
          )}
        </div>
      </div>
      <div className={styles.subContent}>
        <Image
          className={styles.image}
          src={isAdmin ? assignLicense : requestLicense}
          alt='license'
        />
        <div className={styles.title}>
          {isAdmin
            ? `Assign Licenses to activate Trivia`
            : `Request License to activate Trivia for you`}
        </div>
        <div className={styles.subTitle}>
          {isAdmin ? (
            <div>
              Your subscription is set up! To activate Trivia for your teammates,
              {width > 768 && <br />}
              head over to the Dashboard and assign Licenses with ease.
            </div>
          ) : (
            <div>
              Get a License to enjoy playing unlimited Trivia, meet-ups over VirtualCoffee, learn
              fun
              <br />
              facts about your teammates, make lasting bonds and more!
              <br />
              <div>Ask a Trivia admin - {adminsNames} - to assign a License.</div>
            </div>
          )}
        </div>
        {isAdmin && (
          <Button
            icon={<Image src={arrowWhite} alt='arrow' />}
            iconPosition='after'
            className={styles.assignButtonWithArrow}
            onClick={() => window.open(assignLicenseUrl)}
            appearance='primary'
            data-testid='assign-licenses-btn-2'
          >
            Assign Licenses
          </Button>
        )}
      </div>
    </div>
  );
};

UnassignedLicense.propTypes = {
  darkTheme: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  )
};

UnassignedLicense.defaultProps = {
  darkTheme: false,
  admins: []
};
export default UnassignedLicense;
