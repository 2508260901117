import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Pagination = ({ pages, currentPage, onNext, onPrev, pagesToShow, onPageClick }) => {
  let shownPages =
    currentPage > pages.length - pagesToShow
      ? pages.slice(pages.length - pagesToShow, pages.length)
      : pages.slice(currentPage - 1, currentPage - 1 + pagesToShow);
  if (pages.length <= pagesToShow) {
    shownPages = pages;
  }
  return (
    <div className='pagination-container'>
      <div
        className={`prev-page arrow${currentPage === 1 ? ' disabled' : ''}`}
        onClick={onPrev}
        data-testid='prev-page'
      />
      <div className='pages'>
        {shownPages.map((page) => (
          <div
            className={`page${currentPage === page ? ' active' : ''}`}
            key={page}
            onClick={() => onPageClick(page)}
            data-testid='page'
          >
            {page}
          </div>
        ))}
      </div>
      <div
        className={`next-page arrow${currentPage > pages.length - 1 ? ' disabled' : ''}`}
        onClick={onNext}
        data-testid='next-page'
      />
    </div>
  );
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  pagesToShow: PropTypes.number,
  onPageClick: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  pages: [1],
  currentPage: 1,
  pagesToShow: 5
};

export default Pagination;
