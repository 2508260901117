import React, { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GetHomePageData } from 'redux/actions/home';
import HomeView from './HomeView';

const Home = (props) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const {
    planName,
    admins,
    subscriptionDetails,
    enagagementData,
    userDetails,
    GetHomePageData: getHomePageData
  } = props;

  useEffect(() => {
    microsoftTeams.app.initialize();
    const getContextPromise = () =>
      new Promise((resolve, reject) => {
        microsoftTeams.app.getContext((error, context) => {
          if (error) {
            reject(error);
          } else {
            resolve(context);
          }
        });
      });

    getContextPromise().then(async (context) => {
      const { tid: tenantId, userObjectId } = context;
      setDarkTheme(context.theme === 'dark');
      await getHomePageData(tenantId, userObjectId);
      microsoftTeams.app.notifySuccess();
    });
  }, []);

  return (
    <div>
      <HomeView
        darkTheme={darkTheme}
        userDetails={userDetails}
        subscriptionDetails={subscriptionDetails}
        admins={admins}
        planName={planName}
        enagagementData={enagagementData}
      />
    </div>
  );
};

Home.propTypes = {
  GetHomePageData: PropTypes.func.isRequired,
  planName: PropTypes.string.isRequired,
  admins: PropTypes.array.isRequired,
  subscriptionDetails: PropTypes.shape({}).isRequired,
  userDetails: PropTypes.shape({}).isRequired,
  enagagementData: PropTypes.shape({}).isRequired
};

const mapStateToProps = ({
  home: { planName, admins, subscriptionDetails, userDetails, enagagementData }
}) => ({
  planName,
  admins,
  subscriptionDetails,
  userDetails,
  enagagementData
});

export default connect(mapStateToProps, { GetHomePageData })(Home);
