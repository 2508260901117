import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@fluentui/react-components';
import leftIndicator from 'assets/images/left-blue-indicator.svg';
import rightIndicator from 'assets/images/right-blue-indicator.svg';
import * as microsoftTeams from '@microsoft/teams-js';
import classNames from 'classnames';
import styles from './CarouselPlaylist.module.scss';

const CarouselPlayList = (props) => {
  const { images } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const openTriviaUrl = process.env.REACT_APP_TRIVIA_URL;

  const handlePreviousClick = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToSlide = (slideIndex) => {
    setActiveIndex(slideIndex);
  };

  return (
    <div className={styles.carouselPlaylist}>
      <div className={styles.carouselSlide}>
        <img src={images[activeIndex].image} alt='' height='100%' width='100%' />
      </div>
      <div className={styles.bottomContainer}>
        <Button
          appearance='outline'
          className={styles.carouselBottombutton}
          onClick={() => microsoftTeams.app.openLink(openTriviaUrl)}
        >
          Open Trivia
        </Button>
        <div className={styles.carouseldotContainer}>
          <div onClick={handlePreviousClick} className={styles.carouselLeftarrow}>
            <img src={leftIndicator} alt='' />
          </div>
          {images.map((slide, slideIndex) => (
            <div
              className={classNames({
                [styles.carouseldotActive]: slideIndex === activeIndex,
                [styles.carouselDot]: slideIndex !== activeIndex
              })}
              key={slide.id}
              onClick={() => goToSlide(slideIndex)}
            />
          ))}
          <div onClick={handleNextClick} className={styles.carouselRightarrow}>
            <img src={rightIndicator} alt='right indicator' />
          </div>
        </div>
      </div>
    </div>
  );
};

CarouselPlayList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CarouselPlayList;
