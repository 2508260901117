import React from 'react';
import { Button } from '@fluentui/react-components';
import { useAppContext } from 'context';
import { useNavigate } from 'react-router-dom';
import ErrorTape from 'assets/images/errortape.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const VirtualCoffeeError = () => {
  const { theme, chatId, meetingId } = useAppContext();
  const navigate = useNavigate();

  const getErrorContext = () => {
    if (meetingId) {
      return 'meetings';
    }
    if (chatId) {
      return 'group DM';
    }
    return 'my DM'; // For personal chats
  };

  return (
    <div
      className={classNames(styles.virtualCoffeeErrorContainer, {
        [styles.darkTheme]: theme === 'dark'
      })}
    >
      <img src={ErrorTape} alt='error' />
      <div className={styles.errorText}>
        Uh-oh, you cannot launch a VirtualCoffee in {getErrorContext()}, you can only launch in
        Teams.
      </div>
      <div className={styles.bottomButtons}>
        <Button onClick={() => navigate('/task-module/shortcuts')}>Go back</Button>
        <Button
          appearance='primary'
          onClick={() =>
            window.open(
              'https://support.springworks.in/portal/en/kb/trivia/trivia-for-microsoft-teams/virtualcoffee'
            )
          }
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};

export default VirtualCoffeeError;
