import React from 'react';
import { Provider } from 'react-redux';
import { AppWrapper } from 'context';
import { MantineProvider } from '@mantine/core';
import makeServer from 'mirage/api';
import store from './redux/store';
import Routes from './routes';

if (process.env.REACT_APP_USE_MOCK === 'true') {
  makeServer({ environment: 'localhost' });
}

const App = () => (
  <AppWrapper>
    <MantineProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </MantineProvider>
  </AppWrapper>
);

export default App;
