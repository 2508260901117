import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import Section from 'components/Section';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import localStorage from 'utils/localStorage';
import Loading from 'components/Loading';
import { Logout } from 'redux/actions/user';
import Standings from './Standings';
import './styles.scss';

const Leaderboard = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    microsoftTeams.app.initialize();

    const userId = localStorage.get('userId');
    microsoftTeams.app.getContext().then(async (context) => {
      if (context.user?.id !== userId) {
        // Need to login the new user to get their own flag.
        localStorage.deleteFlag();
        microsoftTeams.app.notifySuccess();
        window.location.href = `${process.env.REACT_APP_BASE_URL}/tabs/login?origin=tab`;
      } else if (!localStorage.hasFlag()) {
        microsoftTeams.app.notifyFailure('AuthFailed');
        window.location.href = `${process.env.REACT_APP_BASE_URL}/tabs/login?origin=tab`;
      } else {
        microsoftTeams.app.notifySuccess();
        setLoading(false);
      }
    });
  }, [location.search]);

  const { Logout: logout } = props;
  return (
    <div className='leaderboard-tab-container'>
      <div className='top-bar'>
        <div className='title-container'>Trivia Leaderboard</div>
        <div className='logout-button' onClick={logout}>
          Sign Out
        </div>
      </div>
      <div className='standings-container'>
        <Section>
          {loading ? (
            <Loading height={100} width={100} />
          ) : (
            <>
              <div className='heading'>
                <CrownIcon width={17} height={15} /> Leaderboard
              </div>
              <div className='standings-wrapper'>
                <Standings isTab />
              </div>
            </>
          )}
        </Section>
      </div>
    </div>
  );
};

Leaderboard.propTypes = {
  Logout: PropTypes.func.isRequired
};

export default connect(null, { Logout })(Leaderboard);
