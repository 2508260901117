import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GetUpcomingSchedules } from 'redux/actions/schedule';
import { useAppContext } from 'context';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from 'components/Loading';
import Form from './Form';

const ImageQuiz = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { automations, GetUpcomingSchedules: getUpcomingSchedules } = props;
  const {
    channelId,
    ts,
    setTs,
    startedVia,
    setStartedVia,
    plan,
    setPlan,
    setEntryPoint,
    setServiceUrl,
    chatId,
    setCurrentUserName
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [autoScheduleOptIn, setAutoScheduleOptIn] = useState(false);
  const [isAutomationPresent, setAutomationPresent] = useState(false);
  const [formFields, setFormFields] = useState({
    plan: null
  });

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setEntryPoint((prev) => prev || params.get('entryPoint') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setPlan, setEntryPoint, setServiceUrl, setCurrentUserName]);

  useEffect(() => {
    const channelAutomations = automations?.filter((a) =>
      [channelId, chatId].includes(a.channelId)
    );
    if (channelAutomations.length) {
      setAutoScheduleOptIn(false);
      setAutomationPresent(true);
    }
  }, [automations, channelId, chatId]);

  const fetchData = async (tenantId, teamId, chat, userId) => {
    await getUpcomingSchedules(tenantId, teamId, chat, userId);
    setLoading(false);
  };

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      if (!context.team?.groupId && !context.chat?.id && !context.meeting?.id) {
        return navigate('/error?cause=quiz_in_personal_chat');
      }
      setFormFields({
        ...formFields,
        plan: plan || params.get('plan') || null
      });
      fetchData(context.user.tenant.id, context.team?.groupId, context.chat?.id, context.user?.id);
      if (context?.meeting?.id) {
        setAutoScheduleOptIn(false);
        setAutomationPresent(true);
      }
      return null;
    });
    microsoftTeams.app.notifySuccess();
    microsoftTeams.app.notifySuccess();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    // disable the launch
    setDisable(true);

    // prepare the data to be sent to the task-module
    const formData = {};

    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (autoScheduleOptIn) {
      formData.autoScheduleOptIn = autoScheduleOptIn;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }

    // sending the form data to backend
    microsoftTeams.dialog.url.submit(
      { value: 'imageQuiz_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  // render the loader
  if (loading) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      disable={disable}
      showCheckbox={!isAutomationPresent}
      autoScheduleOptIn={autoScheduleOptIn}
      onCheckboxClick={() => setAutoScheduleOptIn(!autoScheduleOptIn)}
    />
  );
};

ImageQuiz.propTypes = {
  GetUpcomingSchedules: PropTypes.func.isRequired,
  automations: PropTypes.arrayOf(PropTypes.object)
};

ImageQuiz.defaultProps = {
  automations: false
};

const mapStateToProps = ({ schedules: { upcoming } }) => ({
  automations: upcoming
});

export default connect(mapStateToProps, {
  GetUpcomingSchedules
})(ImageQuiz);
