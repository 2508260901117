import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context';
import { connect } from 'react-redux';
import { GetChannelUsersCount } from 'redux/actions/gotcha';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from 'components/Loading';
import Form from './Form';

const Gotcha = (props) => {
  const { usersCount, loading, GetChannelUsersCount: fetchChannelUsersCount } = props;
  const location = useLocation();
  const { ts, startedVia, setStartedVia, setTs, serviceUrl, setServiceUrl, setCurrentUserName } =
    useAppContext();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [disable, setDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formFields, setFormFields] = useState({
    number: null
  });

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setServiceUrl, setCurrentUserName]);

  const fetchData = async (tenantId, url, conversationId) => {
    await fetchChannelUsersCount(tenantId, url, conversationId);
  };

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      fetchData(
        context.user.tenant.id,
        serviceUrl || params.get('serviceUrl'),
        context.team?.groupId || context.chat?.id || context.meeting?.id || context.channel?.id
      );
    });
    microsoftTeams.app.notifySuccess();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    // disable the launch
    setDisable(true);

    // prepare the data to be sent to the task-module
    const formData = {};

    if (formFields.number) {
      formData.answerSize = formFields.number === 0 ? 2 : formFields.number;
    } else {
      setErrorMessage(`Enter a response in the range 1-${usersCount < 20 ? usersCount : 20}`);
      setDisable(true);
      return;
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    // sending the form data to backend
    microsoftTeams.dialog.url.submit(
      { value: 'gotcha_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  const handleChange = (value) => {
    setFormFields((prev) => ({ ...prev, number: value }));
    if (value > usersCount || !_.inRange(value, 1, 21)) {
      setErrorMessage(
        `Enter a response in the range 1${
          usersCount > 1 ? `-${usersCount < 20 ? usersCount : 20}` : ''
        }`
      );
      setDisable(true);
    } else {
      setDisable(false);
      setErrorMessage('');
    }
  };

  //   render the loader
  if (loading) {
    return (
      <div style={{ marginTop: '15%' }} data-testid='loading'>
        <Loading height={100} width={100} />
      </div>
    );
  }

  return (
    <Form
      handleChange={handleChange}
      formFields={formFields}
      handleSubmit={handleSubmit}
      disable={disable}
      erroMessage={errorMessage}
      usersCount={usersCount}
    />
  );
};

Gotcha.propTypes = {
  usersCount: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  GetChannelUsersCount: PropTypes.func.isRequired
};

const mapStateToProps = ({ gotcha: { usersCount, loading } }) => ({ usersCount, loading });

export default connect(mapStateToProps, {
  GetChannelUsersCount
})(Gotcha);
