// To return the required form of data needed for the select dropdown
const serializeTeamsChannels = (teams, withInitialState) => {
  const channelOptions = [];
  if (withInitialState) {
    channelOptions.push({
      label: 'All conversations',
      value: 0
    });
  }
  teams?.forEach((team) => {
    if (team.isGroupChat) {
      channelOptions.push({ label: team.name, value: team.id, group: 'Group chats', teamId: null });
    } else {
      team.channels.forEach((channel) => {
        channelOptions.push({
          label: channel.name,
          value: channel.id,
          group: team.name,
          teamId: team.id
        });
      });
    }
  });
  return channelOptions;
};

export default serializeTeamsChannels;
