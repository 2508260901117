import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { GetUpcomingSchedules, AddSchedule } from 'redux/actions/schedule';
import Loading from 'components/Loading';
import { useAppContext } from 'context';
import Layout from 'components/TaskModule/Layout';
import Card from './Card';
import Form from './Form/index';
import EmptyState from './EmptyState';
import './styles.scss';

const initialSuccess = async ({ getDataFromRedux, tenantId, teamId, chatId, userId }) => {
  microsoftTeams.app.notifySuccess();
  getDataFromRedux(tenantId, teamId, chatId, userId);
};

let tid;
let userObjectId;
const Scheduler = (props) => {
  const [state, setState] = useState({ teamSelected: '', gameTypeSelected: 'all' });
  const [loading, setLoading] = useState(false);
  const { theme, setTs, setStartedVia, setPlan, setServiceUrl, setEntryPoint, entryPoint } =
    useAppContext();
  const [showForm, setShowForm] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [errorData, setErrorData] = useState(false);
  const location = useLocation();
  const { schedules, channelData } = props;
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const showEmptyState = schedules?.upcoming?.length <= 0;

  // Updating the context param variables
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setEntryPoint((prev) => prev || params.get('entryPoint') || null);
  }, [params, setTs, setStartedVia, setPlan, setEntryPoint, setServiceUrl]);

  const getDataFromRedux = async (tenantId, teamId, chatId, userId) => {
    // checks if teams and schedules are present in redux to stop unnecessary api call.
    if (channelData?.allChannels?.length > 0 && schedules?.upcoming?.length) {
      setLoading(false);
      return;
    }

    const { GetUpcomingSchedules: getUpcomingSchedules } = props;
    setLoading(true);
    let isSchedulePresent = false;
    if (teamId || chatId) {
      isSchedulePresent = await getUpcomingSchedules(tenantId, teamId, chatId, userId);
    }
    if (!isSchedulePresent.ok) {
      setErrorData(isSchedulePresent.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (errorData === 'Team info not found') {
      navigate('/error?cause=bot_not_added');
    }
    // eslint-disable-next-line
  }, [errorData]);

  useEffect(() => {
    if (location.search.includes('gameType=watercooler')) {
      setState({ ...state, gameTypeSelected: 'watercooler' });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then(async (context) => {
      tid = context.user.tenant.id;
      const tenantId = context.user.tenant.id;
      const teamId = context.team?.groupId;
      const channelId = context.channel?.id;
      const userId = context.user?.id;
      const chatId = context.chat?.id;
      const meetingId = context.meeting?.id;

      userObjectId = userId;
      if (!teamId && !chatId && !meetingId) {
        return navigate('/error?cause=scheduler_in_personal_chat');
      }
      if (meetingId) {
        return navigate('/error?cause=scheduler_in_meeting');
      }

      initialSuccess({
        props,
        params,
        getDataFromRedux,
        tenantId,
        teamId,
        userId,
        channelId,
        chatId
      });
      return null;
    });

    // eslint-disable-next-line
  }, []);

  const filteredSchedules = schedules?.upcoming;

  const formProps = {
    theme,
    gameFormat: state.gameTypeSelected === 'all' ? null : state.gameTypeSelected,
    team: schedules?.teamName,
    chat: schedules?.chatName,
    setShowForm,
    currentSchedule,
    setCurrentSchedule,
    type: currentSchedule.id ? 'edit' : 'add'
  };

  if (showForm) return <Form {...formProps} data-testid='form' />;

  return loading ? (
    <div style={{ marginTop: '15%' }} data-testid='loading'>
      <Loading height={100} width={100} />
    </div>
  ) : (
    <Layout
      route='shortcuts'
      actionButton={schedules?.isAdmin}
      handleClick={() => {
        setShowForm(true);
      }}
      hideChannelName
      backButtonText={entryPoint === 'Automate Trivia' ? 'Open Trivia Hub' : 'Back'}
      buttonText={showEmptyState ? 'Create' : 'Add New Automation'}
    >
      <div className={`schedule-container ${theme}`}>
        <div className='scheduler-body-wrapper'>
          {showEmptyState ? (
            <EmptyState isAdmin={schedules?.isAdmin} admins={schedules?.admins} />
          ) : (
            <>
              <div className={`pageTitle ${theme}`}>Your Automations</div>

              <div className={`schedulerBody ${theme}`}>
                {filteredSchedules.map((schedule) => (
                  <Card
                    team={schedules?.teamName}
                    channel={schedule.channelName}
                    repeats={schedule.days}
                    format={schedule.gameType}
                    createdBy={schedule.createdBy}
                    schedule={schedule}
                    theme={theme}
                    type='edit'
                    tenantId={tid}
                    formProps={formProps}
                    isAdmin={schedules?.isAdmin}
                    userId={userObjectId}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

Scheduler.propTypes = {
  GetUpcomingSchedules: PropTypes.func.isRequired,
  AddSchedule: PropTypes.func.isRequired,
  channelData: PropTypes.object.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  schedules: PropTypes.shape({
    schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
    teamName: PropTypes.string,
    upcoming: PropTypes.arrayOf(PropTypes.object).isRequired,
    chatName: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    admins: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired
};

const mapStateToProps = ({ channelData, schedules }) => {
  const teams = [];
  channelData?.teams?.forEach((team) => {
    teams.push({ value: team.id, name: team.name });
  });
  return {
    teams,
    schedules,
    channelData
  };
};

export default connect(mapStateToProps, {
  GetUpcomingSchedules,
  AddSchedule
})(Scheduler);
