import { triviaTeams } from 'api';
import { toast } from 'react-toastify';
import { GET_UNPOPULAR_OPINION_DATA, SET_UNPOPULAR_OPINION_LOADING } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const GetUpoData = (tenantId) => async (dispatch) => {
  dispatch({ type: SET_UNPOPULAR_OPINION_LOADING, payload: true });
  const data = await triviaTeams.get(`/task-module/unpopular-opinion?tenantId=${tenantId}`);
  if (data.ok) {
    dispatch({
      type: GET_UNPOPULAR_OPINION_DATA,
      payload: {
        unpopularOpinion: data.unpopularOpinion,
        isCustomPollAllowed: data.isCustomPollAllowed
      }
    });
  } else {
    toast.error(data.message || 'Failed to get unpopular opinion data');
    dispatch({ type: SET_UNPOPULAR_OPINION_LOADING, payload: false });
  }
};
