import { cloneDeep } from 'lodash';
import {
  GET_CHANNELS,
  GET_TEAMS,
  ADD_SCHEDULE,
  MODIFY_SCHEDULE,
  REMOVE_SCHEDULE,
  GET_ALL_TEAMS,
  GET_UPCOMING_SCHEDULES,
  GET_ALL_SCHEDULER_CHANNELS
} from '../types';

const INITIAL_STATE = {
  allChannels: [],
  channels: [],
  allowedChannels: [],
  teams: [],
  allTeams: []
};

const channelReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_ALL_SCHEDULER_CHANNELS:
      return { ...state, allChannels: action.payload };
    case GET_CHANNELS:
      return { ...state, channels: action.payload };
    case GET_TEAMS:
      return { ...state, teams: action.payload };
    case GET_ALL_TEAMS:
      return { ...state, allTeams: action.payload };
    case ADD_SCHEDULE: {
      const aChannels = cloneDeep(state.allowedChannels);
      const channelIndex = aChannels.findIndex(
        (c) => c.name === action.payload.channel || (c.team && c.team === action.payload.team)
      );
      if (channelIndex > -1) {
        aChannels[channelIndex].schedules.push({
          ...action.payload
        });
        aChannels[channelIndex].schedulesCount += 1;
      }
      return { ...state, allowedChannels: aChannels };
    }
    case MODIFY_SCHEDULE: {
      const alChannels = cloneDeep(state.allowedChannels);
      let chIndex = -1;

      chIndex = alChannels.findIndex((c) => c.id === action.payload.teamId);

      if (chIndex > -1) {
        const sIndex = alChannels[chIndex].schedules.findIndex((s) => s.id === action.payload.id);
        if (sIndex > -1) alChannels[chIndex].schedules[sIndex] = action.payload;
        else {
          alChannels[chIndex].schedules.push(action.payload);
          alChannels[chIndex].schedulesCount += 1;
        }
      }
      return { ...state, allowedChannels: alChannels };
    }
    case REMOVE_SCHEDULE: {
      const _aChannels = cloneDeep(state.allowedChannels);
      const cIndex = _aChannels.findIndex(
        (c) => !!c.schedules.find((s) => s.id === action.payload)
      );
      if (cIndex > -1) {
        const sIndex = _aChannels[cIndex].schedules.findIndex((s) => s.id === action.payload);
        _aChannels[cIndex].schedules.splice(sIndex, 1);
        _aChannels[cIndex].schedulesCount -= 1;
      }
      return { ...state, allowedChannels: _aChannels };
    }
    case GET_UPCOMING_SCHEDULES: {
      const _alC = cloneDeep(state.allowedChannels);
      const fieldToMatch = 'teamId';
      action.payload.forEach((schedule) => {
        const cIdx = _alC.findIndex((c) => c.id === schedule[fieldToMatch]);
        if (cIdx > -1) {
          const sIndex = _alC[cIdx].schedules.findIndex((s) => s.id === schedule.id);
          if (sIndex === -1) {
            _alC[cIdx].schedules.push(schedule);
            _alC[cIdx].schedulesCount += 1;
          }
        }
      });
      return { ...state, allowedChannels: _alC };
    }
    default:
      return state;
  }
};

export default channelReducer;
