import { triviaTeams } from 'api';
import { toast } from 'react-toastify';
import {
  CREATE_VIRTUAL_COFFEE,
  CREATE_VIRTUAL_COFFEE_LOADING,
  DELETE_VIRTUAL_COFFEE,
  DELETE_VIRTUAL_COFFEE_LOADING,
  GET_CHANNEL_MEMBERS,
  GET_VIRTUAL_COFFEE_DATA,
  SET_CHANNEL_MEMBERS_LOADING,
  SET_VIRTUAL_COFFEE_LOADING,
  TOGGLE_VIRTUAL_COFFEE,
  TOGGLE_VIRTUAL_COFFEE_LOADING,
  UPDATE_VIRTUAL_COFFEE,
  UPDATE_VIRTUAL_COFFEE_LOADING
} from 'redux/types';
import localStorage from 'utils/localStorage';

export const GetVirtualCoffee = (tenantId, teamId, userId) => async (dispatch) => {
  dispatch({ type: SET_VIRTUAL_COFFEE_LOADING, payload: true });
  const response = await triviaTeams.get('/task-module/virtual-coffee', {
    params: { tenantId, teamId, userId }
  });
  if (response.ok) {
    dispatch({
      type: GET_VIRTUAL_COFFEE_DATA,
      payload: response.data
    });
  } else {
    toast.error(response.message || 'Failed to get virtualCoffee data');
  }
  dispatch({ type: SET_VIRTUAL_COFFEE_LOADING, payload: false });
  return Promise.resolve();
};

export const GetChannelMembers = (conversationId, serviceUrl, tenantId) => async (dispatch) => {
  dispatch({ type: SET_CHANNEL_MEMBERS_LOADING, payload: true });
  let response;
  const now = new Date();

  const getData = async () => {
    response = await triviaTeams.get('/task-module/conversation-members', {
      params: { conversationId, serviceUrl, tenantId }
    });
    if (response.ok) {
      response.expiry = now.getTime() + 60 * 60 * 1000;
      response.conversationId = conversationId;
      localStorage.setConversationMembers(response);
    }
  };

  if (localStorage.getConversationMembers()) {
    const { expiry, conversationId: id } = localStorage.getConversationMembers();
    if (!conversationId === id || now.getTime() > expiry) {
      localStorage.clear('conversationMembers');
      await getData();
    } else {
      response = localStorage.getConversationMembers();
    }
  } else {
    await getData();
  }

  if (response.ok) {
    dispatch({
      type: GET_CHANNEL_MEMBERS,
      payload: response.users
    });
  } else {
    toast.error(response.message || 'Failed to get channel members');
  }

  dispatch({ type: SET_CHANNEL_MEMBERS_LOADING, payload: false });
  return Promise.resolve();
};

export const SetupVirtualCoffee = (tenantId, formData) => async (dispatch) => {
  dispatch({ type: CREATE_VIRTUAL_COFFEE_LOADING, payload: true });
  const response = await triviaTeams.post('/task-module/virtual-coffee', formData, {
    params: { tenantId }
  });
  if (response.ok) {
    dispatch({
      type: CREATE_VIRTUAL_COFFEE,
      payload: response.data.virtualCoffee
    });
  } else {
    toast.error(response.message || 'Failed to create virtualCoffee');
  }
  dispatch({ type: CREATE_VIRTUAL_COFFEE_LOADING, payload: false });
  return response;
};

export const DeleteVirtualCoffee = (tenantId, virtualCoffeeId) => async (dispatch) => {
  dispatch({ type: DELETE_VIRTUAL_COFFEE_LOADING, payload: true });
  const response = await triviaTeams.delete('/task-module/virtual-coffee', {
    params: { tenantId, virtualCoffeeId }
  });
  if (response.ok) {
    dispatch({ type: DELETE_VIRTUAL_COFFEE });
    toast.success('VirtualCoffee deleted successfully');
  } else {
    toast.error(response.message || 'Failed to delete virtualCoffee');
  }
  dispatch({ type: DELETE_VIRTUAL_COFFEE_LOADING, payload: false });
  return response;
};

export const UpdateVirtualCoffee = (tenantId, virtualCoffeeId, formData) => async (dispatch) => {
  dispatch({ type: UPDATE_VIRTUAL_COFFEE_LOADING, payload: true });
  const response = await triviaTeams.patch('/task-module/virtual-coffee', formData, {
    params: { tenantId, virtualCoffeeId }
  });
  if (response.ok) {
    dispatch({ type: UPDATE_VIRTUAL_COFFEE, payload: response.data.virtualCoffee });
  } else {
    toast.error(response.message || 'Failed to update virtualCoffee');
  }
  dispatch({ type: UPDATE_VIRTUAL_COFFEE_LOADING, payload: false });
  return response;
};

export const ToggleVirtualCoffee = (tenantId, virtualCoffeeId) => async (dispatch) => {
  dispatch({ type: TOGGLE_VIRTUAL_COFFEE_LOADING, payload: true });
  const response = await triviaTeams.patch('/task-module/virtual-coffee/toggle', null, {
    params: { tenantId, virtualCoffeeId }
  });
  if (response.ok) {
    dispatch({ type: TOGGLE_VIRTUAL_COFFEE, payload: response.data.virtualCoffee });
    const { active } = response.data.virtualCoffee;
    toast.success(`VirtualCoffee ${active ? 'resumed' : 'paused'} successfully`);
  } else {
    toast.error(response.message || 'Failed to toggle virtualCoffee');
  }
  dispatch({ type: TOGGLE_VIRTUAL_COFFEE_LOADING, payload: false });
  return response;
};
