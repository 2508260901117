const formats = [
  {
    id: 1,
    value: 'imageQuiz',
    name: 'Image Quizzes'
  },
  {
    id: 2,
    value: 'kyt',
    name: 'Know Your Team Quiz'
  },
  {
    id: 3,
    value: 'trivia',
    name: 'Trivia Quizzes'
  },
  {
    id: 4,
    value: 'selfPacedQuiz',
    name: 'Self-paced Quizzes'
  },
  {
    id: 5,
    value: 'waterCooler',
    name: 'Water Cooler'
  },
  {
    id: 6,
    value: 'trueOrFalse',
    name: 'True or False'
  },
  {
    id: 7,
    value: 'gotcha',
    name: 'Gotcha!'
  },
  {
    id: 8,
    value: 'unpopularOpinion',
    name: 'UnPopular Opinions'
  }
];

export default formats;

export const selectGame = [
  {
    id: 1,
    value: 'random',
    name: '💫 Surprise Me!'
  },
  {
    id: 1,
    value: 'selectFormat',
    name: '🎮 Select Formats'
  }
];
