import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ThreeDots from 'assets/images/scheduler/ThreeDots';
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger
} from '@fluentui/react-components';
import { daysToNaturalLanguage } from 'data/days';
import { DeleteSchedule } from 'redux/actions/schedule';
import { toast } from 'react-toastify';
import { useAppContext } from 'context';
import './styles.scss';

const Card = ({ tenantId, team, channel, repeats, schedule, formProps, isAdmin, userId, ...rest }) => {
  const btnRef = useRef(null);
  const { theme } = useAppContext();

  const handleDelete = async () => {
    const resp = await rest.DeleteSchedule(schedule.id, tenantId, userId);
    if (resp) {
      toast.success('Automation  deleted');
    } else {
      toast.error('There was some error, please try again in some time');
    }
  };
  const openForm = () => {
    formProps.setCurrentSchedule(schedule);
    formProps.setShowForm(true);
  };

  const handleMenuItemClick = (value) => {
    if (value === 'Edit') {
      return openForm();
    }

    if (value === 'Delete') {
      return handleDelete();
    }

    return null;
  };

  return (
    <div className={`scheduler-card ${theme}`}>
      <div className='left'>
        <div className='info'>
          <div>
            Launching in:&nbsp;
            <span className='mobile-span'>
              {team && (
                <>
                  <span className='value'>{team}</span> &nbsp; &gt; &nbsp;
                </>
              )}
              <span className='value'>{channel}</span>
            </span>
          </div>
        </div>
        <div className='info'>
          Repeats:&nbsp;
          <span className='value' data-testid='daysId'>{`${daysToNaturalLanguage(repeats)}`}</span>
          &nbsp;
          <span className='value'>{schedule?.time}</span>&nbsp;
          <span className='value'>({schedule?.timezone})</span>
        </div>
        <div className='format'>
          Format:&nbsp;<span className='value'>{schedule?.gameTypes?.join(', ')}</span>
        </div>
        <div className='format'>
          Created by:&nbsp;<span className='value'>{schedule?.createdBy}</span>
        </div>
      </div>
      {isAdmin && (
        <div ref={btnRef} className='rightActions'>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button style={{ minWidth: 'auto' }}>
                <ThreeDots color={theme === 'dark' ? '#fff' : '#111'} />
              </Button>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => handleMenuItemClick('Edit')}>Edit</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  team: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  repeats: PropTypes.arrayOf(PropTypes.string),
  isAdmin: PropTypes.bool.isRequired,
  schedule: PropTypes.shape({
    category: PropTypes.arrayOf(PropTypes.object),
    channelId: PropTypes.string,
    channelName: PropTypes.string,
    createdBy: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.string),
    gameTypes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    time: PropTypes.string,
    timezone: PropTypes.string
  }).isRequired,
  formProps: PropTypes.shape({
    gameFormat: PropTypes.string,
    team: PropTypes.string,
    type: PropTypes.string,
    setShowForm: PropTypes.func,
    setCurrentSchedule: PropTypes.func,
    currentSchedule: PropTypes.objectOf(PropTypes.any)
  })
};

Card.defaultProps = {
  repeats: [],
  formProps: {}
};

export default connect(null, { DeleteSchedule })(Card);
