import trivia from 'api';
import {
  GET_LEADERBOARD_DATA,
  GET_PAGE_DATA,
  SET_ALLOWED_TO_VIEW,
  SET_INVALID_WORKSPACE,
  GET_CATEGORIES,
  GET_ADMIN_DATA
} from '../types';

export const GetLeaderboardData =
  ({ timespan, category, channel }, workspaceId) =>
  async (dispatch) => {
    let data;
    if (workspaceId)
      data = await trivia.get(`/public/leaderboard/${workspaceId}`, {
        params: { timespan, category, channel: channel === 0 ? undefined : channel }
      });
    else
      data = await trivia.get('/leaderboard', {
        params: { timespan, category, channel: channel === 0 ? undefined : channel }
      });

    if (data.ok) {
      dispatch({
        type: GET_LEADERBOARD_DATA,
        payload: data.data,
        workspaceName: data.workspaceName
      });
    } else if (!data.ok && data.message === 'Workspace on BASIC plan') {
      dispatch({ type: SET_ALLOWED_TO_VIEW });
    } else {
      dispatch({ type: SET_INVALID_WORKSPACE });
    }
  };

export const GetPageData = (page) => (dispatch) => {
  dispatch({ type: GET_PAGE_DATA, payload: page });
};

export const GetCategories =
  ({ workspaceId }) =>
  async (dispatch) => {
    let data;
    if (workspaceId) data = await trivia.get(`/public/categories/${workspaceId}`);
    else data = await trivia.get('/categories');
    if (data.ok) {
      dispatch({ type: GET_CATEGORIES, payload: data.categories });
    } else {
      dispatch({ type: SET_INVALID_WORKSPACE });
    }
  };

export const GetAdmins =
  (all = false) =>
  async (dispatch) => {
    const data = await trivia.get(`/dashboard/admin?all=${all}`);
    if (data.ok) {
      dispatch({ type: GET_ADMIN_DATA, payload: { admins: data.admins, users: data.members } });
    }
  };
