import { triviaTeams } from 'api';
import { toast } from 'react-toastify';
import { GET_WATERCOOLER_PROMPT, SET_WATERCOOLER_LOADING } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const GetWatercoolerPrompt = (tenantId) => async (dispatch) => {
  dispatch({ type: SET_WATERCOOLER_LOADING, payload: true });
  const data = await triviaTeams.get(`/task-module/watercooler?tenantId=${tenantId}`);
  if (data.ok) {
    dispatch({
      type: GET_WATERCOOLER_PROMPT,
      payload: data.prompt
    });
  } else {
    toast.error(data.message || 'Failed to get watercooler prompt');
    dispatch({ type: SET_WATERCOOLER_LOADING, payload: false });
  }
};
