import { triviaTeams } from 'api';
import { GET_GROUPED_CATEGORIES, SET_INVALID_WORKSPACE } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const GetGroupedCategories = (tenantId, userId) => async (dispatch) => {
  const data = await triviaTeams.get(`/task-module/categories?tenantId=${tenantId}&userId=${userId}`);
  if (data.ok) {
    dispatch({
      type: GET_GROUPED_CATEGORIES,
      payload: { categories: data.categories, specialCategory: data.availableSpecialCategory, customCategory: data.customCategories }
    });
  } else {
    dispatch({ type: SET_INVALID_WORKSPACE });
  }
};
