import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAppContext } from 'context';
import { GetVirtualCoffee } from 'redux/actions/virtualCoffee';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from 'components/Loading';
import VirtualCoffeePage from './VirtualCoffeePage';
import Form from './Form';

const VirtualCoffee = (props) => {
  const { loading, GetVirtualCoffee: fetchVirtualCoffee } = props;
  const { setTs, setStartedVia, setPlan, setEntryPoint, setServiceUrl, setCurrentUserName } =
    useAppContext();
  const [openVCSetupModal, setOpenVCSetupModal] = useState(false);
  const [showDoneCTA, setShowDoneCTA] = useState(false);
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updating the context param variables when the page is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setEntryPoint((prev) => prev || params.get('entryPoint') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setPlan, setEntryPoint, setServiceUrl, setCurrentUserName]);

  const fetchData = async (tenantId, teamId, userId) => {
    fetchVirtualCoffee(tenantId, teamId, userId);
  };

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      fetchData(context.user.tenant.id, context.team?.groupId, context.user.id);
      return null;
    });
    microsoftTeams.app.notifySuccess();
  }, []);

  if (loading) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  if (openVCSetupModal) {
    return <Form setOpenVCSetupModal={setOpenVCSetupModal} setShowDoneCTA={setShowDoneCTA} />;
  }

  return <VirtualCoffeePage setOpenVCSetupModal={setOpenVCSetupModal} showDoneCTA={showDoneCTA} />;
};

VirtualCoffee.propTypes = {
  loading: PropTypes.bool.isRequired,
  GetVirtualCoffee: PropTypes.func.isRequired
};

const mapStateToProps = ({ virtualCoffee: { loading } }) => ({
  loading
});

export default connect(mapStateToProps, { GetVirtualCoffee })(VirtualCoffee);
