/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

const ThreeDots = ({ color }) => (
  <svg width='4' height='14' viewBox='0 0 4 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 13C2.19891 13 2.38968 12.921 2.53033 12.7803C2.67098 12.6397 2.75 12.4489 2.75 12.25C2.75 12.0511 2.67098 11.8603 2.53033 11.7197C2.38968 11.579 2.19891 11.5 2 11.5C1.80109 11.5 1.61032 11.579 1.46967 11.7197C1.32902 11.8603 1.25 12.0511 1.25 12.25C1.25 12.4489 1.32902 12.6397 1.46967 12.7803C1.61032 12.921 1.80109 13 2 13V13ZM2 7.75C2.19891 7.75 2.38968 7.67098 2.53033 7.53033C2.67098 7.38968 2.75 7.19891 2.75 7C2.75 6.80109 2.67098 6.61032 2.53033 6.46967C2.38968 6.32902 2.19891 6.25 2 6.25C1.80109 6.25 1.61032 6.32902 1.46967 6.46967C1.32902 6.61032 1.25 6.80109 1.25 7C1.25 7.19891 1.32902 7.38968 1.46967 7.53033C1.61032 7.67098 1.80109 7.75 2 7.75V7.75ZM2 2.5C2.19891 2.5 2.38968 2.42098 2.53033 2.28033C2.67098 2.13968 2.75 1.94891 2.75 1.75C2.75 1.55109 2.67098 1.36032 2.53033 1.21967C2.38968 1.07902 2.19891 1 2 1C1.80109 1 1.61032 1.07902 1.46967 1.21967C1.32902 1.36032 1.25 1.55109 1.25 1.75C1.25 1.94891 1.32902 2.13968 1.46967 2.28033C1.61032 2.42098 1.80109 2.5 2 2.5V2.5Z'
      stroke={color}
      stroke-width='1.5'
      stroke-linecap='round'
    />
  </svg>
);

ThreeDots.propTypes = {
  color: PropTypes.string
};

ThreeDots.defaultProps = {
  color: '#fff'
};

export default ThreeDots;
