import { triviaTeams } from 'api';
import { toast } from 'react-toastify';
import localStorage from 'utils/localStorage';

import {
  ADD_SCHEDULE,
  MODIFY_SCHEDULE,
  REMOVE_SCHEDULE,
  GET_UPCOMING_SCHEDULES,
  GET_ALL_SCHEDULER_CHANNELS,
  TEAM_NAME,
  GET_ALL_ADMINS,
  IS_USER_ADMIN,
  CHAT_NAME,
  GET_TEAMS
} from '../types';

export const AddSchedule = (form, tenantId) => async (dispatch) => {
  const data = await triviaTeams.post('/task-module/schedule', form, {
    params: {
      tenantId
    }
  });
  if (data.ok) {
    localStorage.clear('schedules');
    dispatch({ type: ADD_SCHEDULE, payload: data.schedule });
    return true;
  }
  toast.error(data.message || 'Failed to add schedule');
  return false;
};

export const EditSchedule = (form, id, tenantId) => async (dispatch) => {
  const data = await triviaTeams.patch(`/task-module/schedule?scheduleId=${id}`, form, {
    params: {
      tenantId
    }
  });
  if (data.ok) {
    localStorage.clear('schedules');
    dispatch({ type: MODIFY_SCHEDULE, payload: data.schedule });
    return true;
  }
  toast.error(data.message || 'Failed to edit schedule');
  return false;
};

export const DeleteSchedule = (scheduleId, tenantId, userId) => async (dispatch) => {
  const data = await triviaTeams.delete(`/task-module/schedule?scheduleId=${scheduleId}`, {
    params: {
      tenantId,
      userId
    }
  });
  if (data.ok) {
    localStorage.clear('schedules');
    dispatch({ type: REMOVE_SCHEDULE, payload: scheduleId });
    return true;
  }
  toast.error(data.message || 'Failed to delete schedule');
  return false;
};

export const GetUpcomingSchedules =
  (tenantId, teamId = null, chatId = null, userId = null) =>
  async (dispatch) => {
    let data;
    const now = new Date();

    const getData = async () => {
      if (chatId) {
        data = await triviaTeams.get('/task-module/chat-details', {
          params: {
            tenantId,
            chatId
          }
        });
      } else {
        data = await triviaTeams.get('/task-module/team-details', {
          params: {
            tenantId,
            teamId,
            userId
          }
        });
      }
      data.expiry = now.getTime() + 5 * 60 * 1000;
      data.id = teamId ?? chatId;
      localStorage.setSchedules(data);
    };
    if (localStorage.getSchedules()) {
      const { expiry, id } = localStorage.getSchedules();
      if (![teamId, chatId].includes(id) || now.getTime() > expiry) {
        localStorage.clear('schedules');
        await getData();
      } else {
        data = localStorage?.getSchedules();
      }
    } else {
      await getData();
    }
    const isAdmin = !!data.admins.find((user) => user.userId === userId);
    if (data?.ok) {
      dispatch({ type: IS_USER_ADMIN, payload: isAdmin });
      if (data.channels) {
        dispatch({ type: GET_ALL_SCHEDULER_CHANNELS, payload: data.channels });
      }
      if (data.chatName) {
        dispatch({ type: CHAT_NAME, payload: data.chatName });
      }
      if (data.teamName) {
        dispatch({ type: TEAM_NAME, payload: data.teamName });
      }
      if (data.admins) {
        dispatch({ type: GET_ALL_ADMINS, payload: data.admins });
      }
      if (data.schedules) {
        dispatch({ type: GET_UPCOMING_SCHEDULES, payload: data.schedules });
      }
    } else {
      toast.error(data.message || 'Failed to get schedules');
    }
    return data;
  };

export const GetTeams = (all) => async (dispatch) => {
  try {
    const response = await triviaTeams.get(`/teams${all ? '?all=true' : ''}`);
    if (response.ok) {
      dispatch({ type: GET_TEAMS, payload: response.data });
    } else {
      toast.error(response.message);
    }
    return Promise.resolve();
  } catch (error) {
    toast.error('Failed to fetch channels.');
    return error;
  }
};
