import { LOGOUT, LOADING_USER, GET_USER } from '../types';

const INITIAL_STATE = {
  hideTrialWarning: true,
  plan: null,
  shouldFetchData: true,
  loadingUser: false,
  globalAdmin: false,
  promotions: {},
  basicPlanDetails: {}
};

const dashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LOGOUT:
      return INITIAL_STATE;
    case GET_USER:
      return {
        ...state,
        ...action.payload
      };
    case LOADING_USER:
      return { ...state, loadingUser: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
