import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@fluentui/react-components';
import ErrorTape from 'assets/images/errortape.svg';
import HomeTabButton from 'components/HomeTabButton';
import VirtualCoffeeError from './VirtualCoffeeError';
import styles from './Error.module.scss';

const getErrorMessage = (cause, navigate) => {
  switch (cause) {
    case 'no-app':
      return `You don't have access to this page`;
    case 'expired-session':
      return 'Your session has expired.';
    case 'consent_required':
      return 'Please grant the required permissions before using Trivia';
    case 'scheduler_in_meeting':
      return (
        <div className={styles.schedulerWrongContext}>
          You cannot Automate Trivia in a meeting. You can only Automate in channels and group
          chats.
          <br />
          <br />
          To play Trivia in this meeting, launch a Trivia now instead of automating it.
          <Button appearance='primary' onClick={() => navigate('/task-module/all-games')}>
            Launch Trivia Now
          </Button>
        </div>
      );
    case 'scheduler_in_personal_chat':
      return (
        <div className={styles.schedulerWrongContext}>
          <div>
            Uh-oh, you cannot automate Trivia in my DM. Open a channel or chat, and automate Trivia
            for your team there!
          </div>
          <br />
          <br />
          <HomeTabButton title='Learn more' />
        </div>
      );
    case 'trivia_hub_in_personal_chat':
    case 'quiz_in_personal_chat':
      return (
        <div className={styles.schedulerWrongContext}>
          <div>
            Uh-oh, you cannot launch a Trivia format in my DM. Open a channel or chat, and play
            Trivia with your team there!
          </div>
          <br />
          <br />
          <HomeTabButton title='Learn more' />
        </div>
      );
    case 'bot_not_added':
      return 'The bot is not added in the team.';
    default:
      return 'Caution! This Page is Cordoned Off';
  }
};

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const cause = params.get('cause');

  if (cause === 'virtualCoffee') {
    return <VirtualCoffeeError />;
  }

  return (
    <div className={styles.errorContainer}>
      <img src={ErrorTape} alt='error' />
      {getErrorMessage(cause, navigate)}
    </div>
  );
};

export default ErrorPage;
