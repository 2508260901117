import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';
import './standings.scss';

const LeaderboardCard = ({ users, position }) => (
  <div className='leaderboard-data'>
    <div>
      <span style={{ display: 'flex' }}>
        <span className='desktop-view-text'>Times </span>
        Played
      </span>
      <div>{users[position]?.played}</div>
    </div>
    <div>
      <span style={{ textAlign: 'center', marginLeft: 10, display: 'flex' }}>
        <span className='desktop-view-text'>Total </span>
        Score
      </span>
      <div>{users[position]?.score}</div>
    </div>
  </div>
);

const Card = ({ users }) => (
  <div className='leaderboard-card-container'>
    {users[1] && (
      <div className={`leaderboard-second length-${users.length}`}>
        <Badge position='second' image={users[1]?.photo} />
        <p className='leaderboard-name'>{users[1]?.name}</p>
        <LeaderboardCard users={users} position={1} />
      </div>
    )}
    <div className='leaderboard-first'>
      <Badge position='first' image={users[0]?.photo} />
      <p className='leaderboard-name'>{users[0]?.name}</p>
      <LeaderboardCard users={users} position={0} />
    </div>
    {users[2] && (
      <div className='leaderboard-third'>
        <Badge position='third' image={users[2]?.photo} />
        <p className='leaderboard-name'>{users[2]?.name}</p>
        <LeaderboardCard users={users} position={2} />
      </div>
    )}
  </div>
);

Card.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired
};

LeaderboardCard.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  position: PropTypes.number.isRequired
};

export default Card;
