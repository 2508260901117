import React from 'react';
import { useAppContext } from 'context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, Label } from '@fluentui/react-components';
import styles from './Styles.module.scss';

const CustomPoll = (props) => {
  const { formFields, handleChange, errorState } = props;
  const { theme } = useAppContext();

  return (
    <div className={classNames(styles.formWrapper)}>
      <div
        className={classNames(styles.pageTitle, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        Create Custom Poll
      </div>
      <div
        className={classNames(styles.formContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.formItem}>
          <Label className={styles.pollLabels}>Poll Question</Label>
          <Input
            data-testid='poll-question'
            fluid
            value={formFields.opinion}
            placeholder='Enter the poll questions'
            error={errorState === 'opinion'}
            errorIndicator='Please complete this required field'
            onChange={(e, { value }) => handleChange('opinion', value)}
            className={classNames(styles.input, {
              backgroundColor: theme === 'dark' ? styles.inputStylesDark : styles.inputStylesWhite
            })}
          />
        </div>
        <div className={styles.formItem}>
          <Label className={styles.pollLabels}>Option 1</Label>
          <Input
            data-testid='option-1'
            fluid
            value={formFields.answers[0]}
            placeholder='Enter first option'
            error={errorState === 'answers' && !formFields.answers[0]}
            errorIndicator='Please complete this required field'
            onChange={(e, { value }) => handleChange('answers', value, 0)}
            maxLength={30}
            className={classNames(styles.input, {
              backgroundColor: theme === 'dark' ? styles.inputStylesDark : styles.inputStylesWhite
            })}
          />
        </div>
        <div className={styles.formItem}>
          <Label className={styles.pollLabels}>Option 2</Label>
          <Input
            data-testid='option-2'
            fluid
            value={formFields.answers[1]}
            multiple
            placeholder='Enter second option'
            error={errorState === 'answers' && !formFields.answers[1]}
            errorIndicator='Please complete this required field'
            onChange={(e, { value }) => handleChange('answers', value, 1)}
            maxLength={30}
            className={classNames(styles.input, {
              backgroundColor: theme === 'dark' ? styles.inputStylesDark : styles.inputStylesWhite
            })}
          />
        </div>
        <div className={styles.formItem}>
          <Label className={styles.pollLabels}>Option 3 (Optional)</Label>
          <Input
            data-testid='option-3'
            placeholder='Enter third option'
            fluid
            clearable
            value={formFields.answers[2]}
            onChange={(e, { value }) => handleChange('answers', value, 2)}
            maxLength={30}
            className={classNames(styles.input, {
              backgroundColor: theme === 'dark' ? styles.inputStylesDark : styles.inputStylesWhite
            })}
          />
        </div>
        <div className={styles.formItem}>
          <Label className={styles.pollLabels}>Option 4 (Optional)</Label>
          <Input
            data-testid='option-4'
            placeholder='Enter fourth option'
            fluid
            clearable
            value={formFields.answers[3]}
            onChange={(e, { value }) => handleChange('answers', value, 3)}
            maxLength={30}
            className={classNames(styles.input, {
              backgroundColor: theme === 'dark' ? styles.inputStylesDark : styles.inputStylesWhite
            })}
          />
        </div>
      </div>
    </div>
  );
};

CustomPoll.propTypes = {
  formFields: PropTypes.shape({
    opinion: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  errorState: PropTypes.string.isRequired
};

export default CustomPoll;
