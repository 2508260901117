import React from 'react';
import dropdownBlack from 'assets/images/dropdown-black.svg';
import dropdownWhite from 'assets/images/dropdown-white.svg';
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image
} from '@fluentui/react-components';
import TriviaLogo from 'assets/images/logo.svg';
import WhiteTriviaLogo from 'assets/images/white-trivia-logo.svg';
import phone from 'assets/images/phone.svg';
import lightDashboard from 'assets/images/light-dashboard.svg';
import lightPhone from 'assets/images/light-phone.svg';
import dashboard from 'assets/images/dashboard.svg';
import successStories from 'assets/images/success-stories.svg';
import lightSuccessStories from 'assets/images/light-success-stories.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BASIC } from 'redux/types';
import styles from './MobileHeader.module.scss';

const MobileHeader = (props) => {
  const { darkTheme, plan, freeTrial } = props;
  const dashboardUrl = process.env.REACT_APP_DASHBOARD_URL;
  const demoUrl = 'https://calendly.com/trivia-1/trivia-demo?month=2023-04';
  const handleMenuItemClick = (event, { content }) => {
    if (content === 'Dashboard') {
      window.open(dashboardUrl);
    } else if (content === 'Success Stories') {
      window.open(`${process.env.REACT_APP_HOME_PAGE}customers`);
    } else {
      window.open(demoUrl);
    }
  };

  const menuItems = [
    freeTrial || plan === BASIC ? (
      <>
        <Image
          src={darkTheme ? lightDashboard : dashboard}
          alt='Dashboard'
          className={styles.img}
        />
        Dashboard
      </>
    ) : (
      <>
        <Image src={darkTheme ? lightPhone : phone} alt='Book a demo' className={styles.img} />
        Book a demo
      </>
    ),
    <>
      <Image
        src={darkTheme ? lightSuccessStories : successStories}
        alt='Dashboard'
        className={styles.img}
      />
      Success Stories
    </>
  ];

  return (
    <div className={classNames(styles.headerWrapper, { [styles.darkTheme]: darkTheme })}>
      <div className={styles.flexContainer}>
        <div className={styles.logo}>
          <img src={darkTheme ? WhiteTriviaLogo : TriviaLogo} alt='trivia' />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            icon={
              freeTrial || plan === BASIC ? (
                <Image src={lightPhone} alt='phone icon' />
              ) : (
                <Image src={lightDashboard} alt='dashboard icon' />
              )
            }
            className={styles.button}
            onClick={() =>
              freeTrial || plan === BASIC ? window.open(demoUrl) : window.open(dashboardUrl)
            }
            appearance='primary'
          >
            {freeTrial || plan === BASIC ? 'Book a demo' : 'View Dashboard'}
          </Button>
          <Menu>
            <MenuTrigger className={styles.menu}>
              <Image src={darkTheme ? dropdownWhite : dropdownBlack} alt='menu' />
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {menuItems.map((item) => (
                  <MenuItem key={item} onClick={handleMenuItemClick}>
                    {item}
                  </MenuItem>
                ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </div>
    </div>
  );
};

MobileHeader.propTypes = {
  darkTheme: PropTypes.bool,
  plan: PropTypes.string.isRequired,
  freeTrial: PropTypes.bool.isRequired
};

MobileHeader.defaultProps = {
  darkTheme: false
};
export default MobileHeader;
