import { combineReducers } from 'redux';
import dashboard from './dashboardReducer';
import leaderboard from './leaderboardReducer';
import channelData from './channelReducer';
import schedules from './schedulesReducer';
import categories from './categoriesReducer';
import watercooler from './watercoolerReducer';
import gotcha from './gotchaReducer';
import unpopularOpinion from './unpopularOpinionReducer';
import virtualCoffee from './virtualCoffeeReducer';
import home from './homeReducers';

import { LOGOUT } from '../types';

const appReducers = combineReducers({
  dashboard,
  leaderboard,
  channelData,
  schedules,
  categories,
  watercooler,
  gotcha,
  unpopularOpinion,
  virtualCoffee,
  home
});

const rootReducers = (state, action) => {
  let stateCopy = state;
  if (action.type === LOGOUT) {
    stateCopy = undefined;
  }

  return appReducers(stateCopy, action);
};
export default rootReducers;
