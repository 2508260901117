import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAppContext } from 'context';
import { Divider, Checkbox } from '@fluentui/react-components';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const AutomateTrivia = (props) => {
  const { value, handleChange, isAdmin } = props;
  const { theme } = useAppContext();

  return (
    isAdmin && (
      <div
        className={classNames(styles.automateTriviaWrapper, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <Divider className={styles.divider} />
        <Checkbox
          data-testid='Checkbox-id'
          checked={value}
          onChange={(e, { checked }) => handleChange(checked)}
          className={classNames(styles.checkboxWrapper)}
          label={
            <span>
              <span className={styles.label}>Automate Trivia</span>
              <br />
              <span className={styles.description}>
                Let me auto-launch games on Mon, Wed &amp; Fri to improve team engagement 🥳
              </span>
            </span>
          }
        />
      </div>
    )
  );
};

AutomateTrivia.propTypes = {
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired
};

const mapStateToProps = ({ schedules }) => {
  const isAdmin = schedules?.isAdmin;
  return { isAdmin };
};

export default connect(mapStateToProps, null)(AutomateTrivia);
