import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Spinner } from '@fluentui/react-components';
import shuffleIcon from 'assets/images/shuffle-icon.svg';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const Form = (props) => {
  const { handleSubmit, disable, prompt, gif, handleShuffle, loading } = props;
  const { theme } = useAppContext();

  return (
    <Layout
      showChannelDetails
      route='all-games'
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.formContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch Water Cooler</div>
        <div className={styles.promptWrapper}>
          {loading && <Spinner className={styles.promptLoader} data-testid='loader' />}
          {gif && <Image src={gif} className={styles.gif} />}
          {prompt}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            loading={loading}
            disable={loading}
            onClick={handleShuffle}
            icon={<Image src={shuffleIcon} alt='suffle icon' />}
          >
            Shuffle
          </Button>
        </div>
      </div>
    </Layout>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  prompt: PropTypes.string,
  gif: PropTypes.string,
  handleShuffle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

Form.defaultProps = {
  prompt: '',
  gif: ''
};

export default Form;
