import { GET_WATERCOOLER_PROMPT, SET_WATERCOOLER_LOADING } from '../types';

const initState = {
  prompt: {},
  loading: true
};

const watercoolerReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case GET_WATERCOOLER_PROMPT:
      return { ...state, prompt: action.payload, loading: false };
    case SET_WATERCOOLER_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default watercoolerReducer;
