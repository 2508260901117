/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import classNames from 'classnames';
import gameFormats, { selectGame } from 'data/formats';
import days from 'data/days';
import { alphabeticalTimezones as timezones } from 'data/timezones';
import { Dropdown, Combobox, Option, Persona, makeStyles, Input } from '@fluentui/react-components';
import moment from 'moment';
import styles from './styles.module.scss';

const useStyles = makeStyles({
  listbox: {
    maxHeight: '300px'
  }
});

const Form = (props) => {
  const {
    data,
    handleSelectChange,
    buttonTitle,
    categories,
    handleSubmit,
    type,
    theme,
    channelData,
    checkData,
    difficultyData,
    handleCategorySelection,
    maxCategories,
    disable,
    chatName
  } = props;
  const { chatId } = useAppContext();
  const isRandomOrSurprise = data?.gameTypes?.some((t) => ['random', 'Surprise Me!'].includes(t));

  const initialGameType = isRandomOrSurprise ? ['random'] : ['selectFormat'];
  const [selectGameType, setSelectGameType] = useState(initialGameType);

  const initialGameFormat = isRandomOrSurprise ? ['Trivia Quizzes'] : data?.gameTypes;
  const [selectGameFormat, setSelectGameFormat] = useState(initialGameFormat);

  useEffect(() => {
    if (selectGameType.length === 0) {
      handleSelectChange([], 'gameTypes');
    } else if (selectGameType[0] === 'random') {
      handleSelectChange(['random'], 'gameTypes');
    } else {
      handleSelectChange(selectGameFormat, 'gameTypes');
    }
  }, [selectGameType, selectGameFormat]);

  const classes = useStyles();

  return (
    <Layout
      route='shortcuts'
      showChannelDetails
      currentChannel={data.channel}
      actionButton
      buttonText={buttonTitle(type)}
      buttonDisabled={disable}
      handleClick={handleSubmit}
      chatName={chatName}
    >
      <div
        className={classNames(styles.pageTitle, {
          [styles.dark]: theme === 'dark'
        })}
      >
        {type === 'edit' ? 'Edit' : 'Create'} automation
      </div>

      <div
        className={classNames(styles.formContainer, {
          dark: theme === 'dark',
          [styles.overflow]: data.gameType === 'trivia'
        })}
      >
        {checkData && !selectGameType && (
          <div className={styles.error}>⚠️ Please Select a format</div>
        )}
        <label>Select Game</label>
        <Dropdown
          placeholder='Pick an option'
          defaultValue={selectGame.find((format) => selectGameType.includes(format.value))?.name}
          defaultSelectedOptions={[
            selectGame.find((format) => selectGameType.includes(format.value))?.name
          ]}
          onOptionSelect={(e, value) => {
            setSelectGameType(
              value.optionValue.includes('Select Formats')
                ? [value.optionValue]
                : [selectGame.find((format) => format.name.includes(value.optionValue)).value]
            );
          }}
        >
          {selectGame.map((format) => (
            <Option key={format.name}>{format.name}</Option>
          ))}
        </Dropdown>

        {selectGameType[0] !== 'random' && (
          <>
            <label>Formats</label>
            {checkData && !selectGameFormat.length && (
              <div className={styles.error}>⚠️ Please Select a format</div>
            )}
            <Dropdown
              placeholder='Pick an option'
              defaultValue={selectGameFormat.join(', ')}
              defaultSelectedOptions={selectGameFormat.map((format) => format)}
              onOptionSelect={(e, value) => {
                setSelectGameFormat(value.selectedOptions);
              }}
              multiselect
            >
              {gameFormats.map((format) => (
                <Option key={format.value}>{format.name}</Option>
              ))}
            </Dropdown>
          </>
        )}

        {!chatId && (
          <>
            <label>Channel</label>
            {checkData && !data.channel && (
              <div className={styles.error}>⚠️ Please Select a channel</div>
            )}
            <Dropdown
              placeholder='Select channel'
              listbox={{ className: classes.listbox }}
              defaultValue={data?.channel}
              defaultSelectedOptions={[data?.channel]}
              onOptionSelect={(e, value) => handleSelectChange(value, 'channel')}
            >
              {channelData?.allChannels.map((channel) => (
                <Option>{channel.name}</Option>
              ))}
            </Dropdown>
          </>
        )}

        <label>Frequency</label>
        {checkData && !data.daysOfTheWeek.length && (
          <div className={styles.error}>⚠️ Please Select a day</div>
        )}
        <Dropdown
          placeholder='Select frequency'
          multiselect
          defaultValue={data?.daysOfTheWeek.join(', ')}
          defaultSelectedOptions={data?.daysOfTheWeek}
          onOptionSelect={(e, value) => handleSelectChange(value, 'daysOfTheWeek')}
        >
          {days.map((option) => (
            <Option key={option.name}>{option.value}</Option>
          ))}
        </Dropdown>

        {checkData && !data.time && <div className={styles.error}>⚠️ Please Select a time</div>}

        {checkData && !data.timezone && (
          <div className={styles.error}>⚠️ Please Select a timezone</div>
        )}

        <div className={styles.timeContainer}>
          <div>
            <label>Time</label>
            <div className={styles.dropdownMobileView}>
              <Input
                type='time'
                value={moment(data.time, ['h:mm A']).format('HH:mm')}
                onChange={(e, value) => handleSelectChange(value, 'time')}
              />
            </div>
          </div>
          <div>
            <label>Select Timezone</label>
            <div className={styles.dropdownMobileView}>
              <Dropdown
                listbox={{ className: classes.listbox }}
                placeholder='Asia/kolkata'
                defaultValue={timezones.find((timezone) => timezone.value === data.timezone)?.name}
                defaultSelectedOptions={[
                  timezones.find((timezone) => timezone.value === data.timezone)?.name
                ]}
                onOptionSelect={(e, value) =>
                  handleSelectChange(
                    timezones.find((timezone) => timezone.name === value.optionValue).value,
                    'timezone'
                  )
                }
              >
                {timezones.map((timezone) => (
                  <Option key={timezone.value}>{timezone.name}</Option>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>

        {(data.gameTypes.includes('Trivia Quizzes') ||
          data.gameTypes.includes('Self-paced Quizzes')) && (
          <>
            <label>Quiz Categories (Optional)</label>
            <Combobox
              placeholder='Select Categories (upto 5)'
              multiselect
              listbox={{ className: classes.listbox }}
              onOptionSelect={(e, value) => {
                handleCategorySelection.onAdd(value, 'category');
              }}
            >
              {categories.map((option) =>
                option.content ? (
                  <Option text={option.header}>
                    <Persona
                      size='large'
                      presenceOnly
                      name={option.header}
                      secondaryText={option.content}
                    />
                  </Option>
                ) : (
                  <Option key={option.header}>{option.header}</Option>
                )
              )}
            </Combobox>
            {maxCategories && (
              <div className={styles.error}>⚠️ You cannot select more than 5 categories.</div>
            )}

            <label>Quiz Difficulty (optional)</label>
            <Dropdown
              placeholder='Select difficulty'
              defaultValue={data?.difficulty}
              defaultSelectedOptions={[data?.difficulty]}
              onOptionSelect={(e, value) => handleSelectChange(value, 'difficulty')}
            >
              {difficultyData.map((option) => (
                <Option key={option.value}>{option.name}</Option>
              ))}
            </Dropdown>
          </>
        )}
      </div>
    </Layout>
  );
};

Form.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCategorySelection: PropTypes.func.isRequired,
  buttonTitle: PropTypes.func.isRequired,
  theme: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  type: PropTypes.string,
  channelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  difficultyData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  checkData: PropTypes.bool,
  maxCategories: PropTypes.bool,
  disable: PropTypes.bool,
  chatName: PropTypes.string
};

Form.defaultProps = {
  categories: [],
  type: 'add',
  theme: 'light',
  difficultyData: [],
  checkData: false,
  maxCategories: false,
  disable: false,
  chatName: null
};

export default Form;
