import React from 'react';
import { ReactComponent as FirstPlace } from 'assets/images/gold-medal.svg';
import { ReactComponent as SecondPlace } from 'assets/images/silver-medal.svg';
import { ReactComponent as ThirdPlace } from 'assets/images/bronze-medal.svg';
import { Option } from '@fluentui/react-components';

export const getMedalWithRank = (rank, score) => {
  if (score === 0) return rank;
  switch (rank) {
    case 1:
      return (
        <>
          <FirstPlace />
          {rank}
        </>
      );
    case 2:
      return (
        <>
          <SecondPlace />
          {rank}
        </>
      );
    case 3:
      return (
        <>
          <ThirdPlace />
          {rank}
        </>
      );

    default:
      return rank;
  }
};

// this function return the string with lock icon if the field is locked
export const getDropdownArray = (key, value, plan) =>
  key.map((item) => {
    if (plan === 'BASIC' && item.content !== value) {
      return <Option item={item.key}>{`${item.content} 🔒`}</Option>;
    }
    return <Option item={item.key}>{item.content}</Option>;
  });

/**
 * Function for getting singular or plural form of verb
 *
 * @example
 * maybePluralize(1, 'user')  ==> user
 * maybePluralize(5, 'user')  ==> users
 * maybePluralize(3, 'fox', [''],'es') ==> foxes
 * maybePluralize(4, 'teammate', ['is','are'])  ==> teamates are
 * maybePluralize(1, 'teammate', ['is','are'])  ==> teamate is

 */

const getSuffix = (count, suffix) => (count !== 1 ? suffix : '');

export const maybePluralize = (count, noun, auxillaryVerb = [''], suffix = 's') =>
  `${noun}${getSuffix(count, suffix)} ${
    auxillaryVerb.length === 2 && count !== 1 ? auxillaryVerb[1] : auxillaryVerb[0]
  }`;
