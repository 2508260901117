import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { GetWatercoolerPrompt } from 'redux/actions/watercooler';
import { useAppContext } from 'context';
import Form from './Form';

const Watercooler = (props) => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { prompt, GetWatercoolerPrompt: getRandomPrompt, loading } = props;
  const { tenantId, ts, startedVia, setTs, setStartedVia, setCurrentUserName } = useAppContext();
  const [disableButton, setDisableButton] = useState(false);

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia]);

  const fetchWatercoolerPrompt = (tid = null) => {
    getRandomPrompt(tenantId || tid);
  };

  const handleSubmit = () => {
    setDisableButton(true);
    const formData = {};
    if (prompt) {
      formData.prompt = prompt;
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    microsoftTeams.dialog.url.submit(
      { value: 'watercooler_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      fetchWatercoolerPrompt(context.user.tenant.id);
    });
    microsoftTeams.app.notifySuccess();
  }, []);

  return (
    <Form
      prompt={prompt.text}
      gif={prompt.imageUrl}
      loading={loading}
      disable={disableButton || loading}
      handleSubmit={handleSubmit}
      handleShuffle={fetchWatercoolerPrompt}
    />
  );
};

Watercooler.propTypes = {
  prompt: PropTypes.shape({
    text: PropTypes.string,
    _id: PropTypes.string,
    imageUrl: PropTypes.string
  }).isRequired,
  GetWatercoolerPrompt: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = ({ watercooler: { prompt, loading } }) => ({ prompt, loading });

export default connect(mapStateToProps, {
  GetWatercoolerPrompt
})(Watercooler);
