import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';

const DefaultTooltip = ({ children, ...props }) => (
  <Tooltip placement='bottom' {...props}>
    {children}
  </Tooltip>
);

const PlanDetailsTooltip = ({ children, ...props }) => (
  <Tooltip
    placement='bottom-start'
    styles={{
      tooltip: {
        backgroundColor: '#4E4B66',
        color: 'rgba(252, 252, 252, 0.8)'
      }
    }}
    {...props}
  >
    {children}
  </Tooltip>
);

const GameCenterTooltip = ({ children, ...props }) => (
  <Tooltip
    placement='right'
    styles={{
      tooltip: {
        backgroundColor: '#4E4B66',
        color: 'rgba(252, 252, 252, 0.8)',
        borderRadius: '16px'
      },
      arrow: {
        borderTop: '30px solid transparent',
        borderBottom: '30px solid transparent',
        borderRight: '25px solid #4e4b66'
      }
    }}
    {...props}
  >
    {children}
  </Tooltip>
);

const BillingTooltip = ({ children, ...props }) => (
  <Tooltip
    placement='top'
    styles={{
      tooltip: {
        backgroundColor: '#4E4B66',
        color: 'rgba(252, 252, 252, 0.8)',
        borderRadius: '16px',
        fontSize: '14px',
        padding: '12px'
      },
      arrow: {
        color: '#4E4B66'
      }
    }}
    {...props}
  >
    {children}
  </Tooltip>
);

DefaultTooltip.propTypes = {
  children: PropTypes.node.isRequired
};

PlanDetailsTooltip.propTypes = {
  children: PropTypes.node.isRequired
};
GameCenterTooltip.propTypes = {
  children: PropTypes.node.isRequired
};
BillingTooltip.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultTooltip;
export { PlanDetailsTooltip, GameCenterTooltip, BillingTooltip };
