import React from 'react';
import Lottie from 'lottie-react';
import animationData from 'assets/animation/loading.json';
import PropTypes from 'prop-types';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const style = {
  height: 100
};

const Loading = ({ height, width }) => (
  <Lottie
    animationData={animationData}
    options={defaultOptions}
    style={style}
    height={height}
    width={width}
  />
);

Loading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

Loading.defaultProps = {
  height: 50,
  width: 50
};

export default Loading;
