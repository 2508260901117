import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetUpcomingSchedules } from 'redux/actions/schedule';
import { useAppContext } from 'context';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from 'components/Loading';
import dropdowns from 'data/dropdowns';
import Form from './Form';

const TrueOrFalse = (props) => {
  const { automations, GetUpcomingSchedules: getUpcomingSchedules } = props;
  const location = useLocation();
  const {
    channelId,
    ts,
    setTs,
    startedVia,
    setStartedVia,
    plan,
    setPlan,
    chatId,
    setCurrentUserName
  } = useAppContext();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [limitFlag, setLimitFlag] = useState('');
  const [autoScheduleOptIn, setAutoScheduleOptIn] = useState(false);
  const [isAutomationPresent, setAutomationPresent] = useState(false);
  const [formFields, setFormFields] = useState({
    ts: null,
    plan: null,
    startedVia: null,
    speed: '20s per question'
  });

  const fetchData = async (tenantId, teamId, chat, userId) => {
    setLoading(true);
    if (teamId || chat) {
      await getUpcomingSchedules(tenantId, teamId, chat, userId);
    }
    setLoading(false);
  };

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setPlan, setCurrentUserName]);

  useEffect(() => {
    const channelAutomations = automations?.filter((a) =>
      [channelId, chatId].includes(a.channelId)
    );
    if (channelAutomations.length) {
      setAutoScheduleOptIn(false);
      setAutomationPresent(true);
    }
  }, [automations, channelId, chatId]);

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      setFormFields({
        ...formFields,
        plan: plan || params.get('plan') || null
      });
      fetchData(context.user?.tenant.id, context.team?.groupId, context.chat?.id, context.user.id);
      if (context?.meeting?.id) {
        setAutoScheduleOptIn(false);
        setAutomationPresent(true);
      }
    });
    microsoftTeams.app.notifySuccess();
  }, []);

  const handleSubmit = () => {
    // disable the launch
    setDisable(true);

    // prepare the data to be sent to the task-module
    const formData = {};

    if (formFields.speed) {
      formData.speed = dropdowns.speed.find((d) => d.content === formFields.speed)?.key;
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (autoScheduleOptIn) {
      formData.autoScheduleOptIn = autoScheduleOptIn;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    // sending the form data to backend
    microsoftTeams.dialog.url.submit(
      { value: 'trueOrFalse_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  const handleChange = (value) => {
    if (formFields.plan === 'BASIC') {
      setLimitFlag(true);
      setFormFields({ ...formFields, speed: value.optionValue });
      // this is to show the warning message which removes after 3 seconds
      setTimeout(() => {
        setLimitFlag(false);
      }, 3000);
    } else {
      setFormFields({ ...formFields, speed: value.optionValue });
    }
  };

  //   render the loader
  if (loading) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  return (
    <Form
      handleChange={handleChange}
      formFields={formFields}
      limitFlag={limitFlag}
      handleSubmit={handleSubmit}
      disable={disable}
      showCheckbox={!isAutomationPresent}
      autoScheduleOptIn={autoScheduleOptIn}
      onCheckboxClick={(value) => setAutoScheduleOptIn(value)}
    />
  );
};

TrueOrFalse.propTypes = {
  GetUpcomingSchedules: PropTypes.func.isRequired,
  automations: PropTypes.array
};

TrueOrFalse.defaultProps = {
  automations: false
};

const mapStateToProps = ({ schedules: { upcoming } }) => ({
  automations: upcoming
});

export default connect(mapStateToProps, {
  GetUpcomingSchedules
})(TrueOrFalse);
