/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Route, Routes as Switch, BrowserRouter as Router } from 'react-router-dom';
import Config from 'pages/tabs/Config';
import AuthStart from 'pages/tabs/Auth/AuthStart';
import AuthEnd from 'pages/tabs/Auth/AuthEnd';
import LeaderboardTab from 'pages/tabs/Leaderboard';
import Home from 'pages/tabs/Home';
import Login from 'pages/tabs/Login';
import Shortcuts from 'pages/task.modules/Shortcuts';
import Scheduler from 'pages/task.modules/Scheduler';
import TriviaQuizzes from 'pages/task.modules/TriviaQuizzes';
import TrueOrFalse from 'pages/task.modules/TrueOrFalse';
import Watercooler from 'pages/task.modules/Watercooler';
import Pictionary from 'pages/task.modules/Pictionary';
import Gotcha from 'pages/task.modules/Gotcha';
import CustomPoll from 'pages/task.modules/CustomPoll';
import ErrorPage from 'pages/Error';
import LandingPage from 'pages/LandingPage';
import UnpopularOpinion from 'pages/task.modules/UnpopularOpinion';
import VirtualCoffee from 'pages/task.modules/VirtualCoffee';
import AllGames from 'pages/task.modules/AllGames';
import ImageQuiz from 'pages/task.modules/ImageQuiz';
import history from './createHistory';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path='/tabs/config' element={<Config />} />
      <Route exact path='/tabs/auth-start' element={<AuthStart />} />
      <Route exact path='/tabs/auth-end' element={<AuthEnd />} />
      <Route exact path='/tabs/leaderboard' element={<LeaderboardTab />} />
      <Route exact path='/tabs/home' element={<Home />} />
      <Route exact path='/tabs/login' element={<Login />} />
      <Route exact path='/task-module/shortcuts' element={<Shortcuts />} />
      <Route exact path='/task-module/scheduler' element={<Scheduler />} />
      <Route exact path='/task-module/trivia-quizzes' element={<TriviaQuizzes />} />
      <Route exact path='/task-module/image-quiz' element={<ImageQuiz />} />
      <Route exact path='/task-module/true-or-false' element={<TrueOrFalse />} />
      <Route exact path='/task-module/watercooler' element={<Watercooler />} />
      <Route exact path='/task-module/pictionary' element={<Pictionary />} />
      <Route exact path='/task-module/gotcha' element={<Gotcha />} />
      <Route exact path='/task-module/unpopular-opinion' element={<UnpopularOpinion />} />
      <Route exact path='/task-module/custom-poll' element={<CustomPoll />} />
      <Route exact path='/task-module/virtual-coffee' element={<VirtualCoffee />} />
      <Route exact path='/task-module/all-games' element={<AllGames />} />
      <Route exact path='/landing-page' element={<LandingPage />} />
      <Route exact path='/error' element={<ErrorPage />} />
      <Route exact path='*' element={<ErrorPage />} />
    </Switch>
  </Router>
);

export default Routes;
