import { range } from 'lodash';
import moment from 'moment';

const times = () => {
  const hours = range(0, 24);
  const minutes = range(0, 60, 1);
  const time = [];
  hours.forEach((hour) => {
    minutes.forEach((minute) => {
      time.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
    });
  });
  return time;
};

export const to24h = (_12h) => {
  const [time, meridian] = _12h.split(' ');
  const [hour, minutes] = time.split(':');
  if (meridian === 'am') {
    if (hour === '12') {
      return `00:${minutes}`;
    }
    return time;
  }
  if (hour === '12') {
    return time;
  }
  return `${parseInt(hour, 10) + 12}:${minutes}`;
};

export const to12h = (time) => moment(time, 'HHmm').format('hh:mm a');

// eslint-disable-next-line no-underscore-dangle
export const _12hFormats = times().map((time) => to12h(time));

export default times;
