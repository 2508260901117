import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import backArrow from 'assets/images/arrow-back.svg';
import infoIcon from 'assets/images/info-icon.svg';
import rightArrow from 'assets/images/arrow-right.svg';
import { Image, Tooltip, Button } from '@fluentui/react-components';
import { useAppContext } from 'context';
import styles from './Layout.module.scss';

const Layout = (props) => {
  const {
    children,
    className,
    route,
    actionButton,
    handleClick,
    buttonDisabled,
    showChannelDetails,
    currentChannel,
    hideChannelName,
    buttonText,
    backButtonText,
    customBackButtonAction,
    backButtonAction,
    chatName,
    showSecondaryButton,
    secondaryButtonText,
    handleSecondaryButtonClick
  } = props;
  const { theme, teamName, channelName, meetingId, chatId } = useAppContext();
  const navigate = useNavigate();
  const handleBack = () => {
    if (customBackButtonAction) {
      backButtonAction();
    } else {
      navigate(`/task-module/${route}`);
    }
  };

  return (
    <div
      className={classNames(styles.layoutContainer, {
        [styles.darkTheme]: theme === 'dark'
      })}
    >
      <div className={classNames(styles.contentWrapper, className)}>
        <div className={styles.subHeaderWrapper}>
          {(route || customBackButtonAction) && (
            <div className={classNames(styles.backButton, styles.boldText)} onClick={handleBack}>
              <Image src={backArrow} className={styles.backArrow} />
              {backButtonText}
            </div>
          )}
          {showChannelDetails && (
            <div className={styles.targetChannelWrapper}>
              <div className={styles.launchTextAndIcon}>
                <Image src={infoIcon} />
                <span className={styles.boldText}>&nbsp;Launching in:&nbsp;</span>
              </div>
              <div className={styles.teamDetailsWrapper}>
                {(teamName || chatName) && (
                  <Tooltip withArrow relationship='description' content={teamName ?? chatName}>
                    <span className={styles.teamNameWrapper}>{teamName ?? chatName}</span>
                  </Tooltip>
                )}
                {!hideChannelName && teamName && channelName && (
                  <Image src={rightArrow} className={styles.rightArrow} data-testid='right-arrow' />
                )}
                {!hideChannelName && channelName && (
                  <Tooltip withArrow relationship='description' content={channelName}>
                    <span className={styles.channelNameWrapper}>
                      {currentChannel || channelName}
                    </span>
                  </Tooltip>
                )}
                {((chatId && !chatName) || meetingId) && (
                  <span className={styles.teamNameWrapper}>Chat</span>
                )}
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
      {actionButton && (
        <div className={styles.footer}>
          {showSecondaryButton && (
            <Button
              disabled={buttonDisabled}
              onClick={handleSecondaryButtonClick}
              appearance='primary'
            >
              <div className={styles.launchButton}>{secondaryButtonText}</div>
            </Button>
          )}
          <Button
            data-testid='submit-button'
            disabled={buttonDisabled}
            onClick={handleClick}
            appearance='primary'
          >
            <div className={styles.launchButton}>{buttonText}</div>
          </Button>
        </div>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  route: PropTypes.string,
  showChannelDetails: PropTypes.bool,
  actionButton: PropTypes.bool,
  handleClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  currentChannel: PropTypes.string,
  hideChannelName: PropTypes.bool,
  buttonText: PropTypes.string,
  backButtonText: PropTypes.string,
  chatName: PropTypes.string,
  customBackButtonAction: PropTypes.bool,
  backButtonAction: PropTypes.func,
  showSecondaryButton: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  handleSecondaryButtonClick: PropTypes.func
};

Layout.defaultProps = {
  children: null,
  className: null,
  route: null,
  actionButton: false,
  handleClick: () => null,
  buttonDisabled: false,
  showChannelDetails: false,
  buttonText: 'Launch',
  currentChannel: null,
  hideChannelName: false,
  backButtonText: 'Back',
  chatName: null,
  customBackButtonAction: false,
  backButtonAction: () => null,
  showSecondaryButton: false,
  secondaryButtonText: 'Edit',
  handleSecondaryButtonClick: () => null
};

export default Layout;
