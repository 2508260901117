import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import PropTypes from 'prop-types';
import { GetUpcomingSchedules } from 'redux/actions/schedule';
import { toast } from 'react-toastify';
import { useAppContext } from 'context';
import ShortcutsPage from './ShortcutsPage';

const Shortcuts = (props) => {
  const {
    deviceType,
    teamId,
    tenantId,
    setTs,
    setStartedVia,
    setPlan,
    setServiceUrl,
    chatId,
    meetingId,
    setCurrentUserName
  } = useAppContext();
  const navigate = useNavigate();
  const { GetUpcomingSchedules: getUpcomingSchedules, upcoming } = props;
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updating the context param variables
  useEffect(() => {
    setTs((prev) => prev || null);
    setStartedVia((prev) => prev || 'Trivia Shortcuts messaging extension');
    setPlan((prev) => prev || params.get('plan') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setPlan, setServiceUrl, setCurrentUserName]);

  useEffect(() => {
    const getSchedulesData = async () => {
      try {
        if (teamId || chatId) {
          microsoftTeams.app.getContext().then(async (context) => {
            await getUpcomingSchedules(tenantId, teamId, chatId, context.user?.id);
          });
        }
      } catch (err) {
        toast.error(err);
      }
    };
    if ((teamId && tenantId) || chatId) getSchedulesData();
  }, [teamId, tenantId, chatId]);

  const handleAction = (id) => {
    try {
      if (id === 'scheduler') {
        if (!teamId && !chatId && !meetingId) {
          navigate('/error?cause=scheduler_in_personal_chat');
        } else {
          navigate('/task-module/scheduler');
        }
      } else if (id === 'home') {
        microsoftTeams.app.openLink(
          `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`
        );
      } else if (id === 'virtual_coffee') {
        if (teamId) {
          navigate('/task-module/virtual-coffee');
        } else {
          navigate('/error?cause=virtualCoffee');
        }
      } else if (!teamId && !chatId && !meetingId) {
        navigate(`/error?cause=trivia_hub_in_personal_chat`);
      } else {
        navigate('/task-module/all-games');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <ShortcutsPage
      handleAction={handleAction}
      deviceType={deviceType}
      totalAutomations={upcoming?.length}
    />
  );
};

Shortcuts.propTypes = {
  GetUpcomingSchedules: PropTypes.func.isRequired,
  upcoming: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = ({ schedules }) => {
  const upcoming = schedules?.upcoming;
  return {
    upcoming
  };
};

export default connect(mapStateToProps, {
  GetUpcomingSchedules
})(Shortcuts);
