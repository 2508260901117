import _ from 'lodash';
import {
  GET_LEADERBOARD_DATA,
  GET_PAGE_DATA,
  SET_INVALID_WORKSPACE,
  SET_ALLOWED_TO_VIEW,
  GET_ADMIN_DATA
} from '../types';

const initState = {
  leaderboard: [],
  categories: [],
  currentPage: [],
  workspaceName: '',
  admins: [],
  invalidWorkspace: null,
  allowedToView: true
};

const leaderboardReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case GET_LEADERBOARD_DATA:
      return {
        ...state,
        leaderboard: action.payload,
        workspaceName: action.workspaceName,
        invalidWorkspace: false
      };

    case GET_PAGE_DATA: {
      const page = action.payload;
      const { leaderboard } = state;
      if (leaderboard.length === 0) return { ...state, currentPage: [] };
      const currentPage = _.slice(leaderboard, (page - 1) * 10, page * 10);
      return { ...state, currentPage };
    }
    case SET_INVALID_WORKSPACE:
      return { ...state, invalidWorkspace: true };
    case SET_ALLOWED_TO_VIEW:
      return { ...state, allowedToView: false };
    case GET_ADMIN_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default leaderboardReducer;
