import {
  CREATE_VIRTUAL_COFFEE,
  CREATE_VIRTUAL_COFFEE_LOADING,
  DELETE_VIRTUAL_COFFEE,
  DELETE_VIRTUAL_COFFEE_LOADING,
  GET_CHANNEL_MEMBERS,
  GET_VIRTUAL_COFFEE_DATA,
  SET_CHANNEL_MEMBERS_LOADING,
  SET_VIRTUAL_COFFEE_LOADING,
  TOGGLE_VIRTUAL_COFFEE,
  TOGGLE_VIRTUAL_COFFEE_LOADING,
  UPDATE_VIRTUAL_COFFEE,
  UPDATE_VIRTUAL_COFFEE_LOADING
} from '../types';

const INITIAL_STATE = {
  isAdmin: false,
  isCreator: false,
  virtualCoffee: null,
  loading: true,
  channelMembers: [],
  channelMembersLoading: true,
  createVirtualCoffeeLoading: false,
  deleteVirtualCoffeeLoading: false,
  updateVirtualCoffeeLoading: false,
  toggleVirtualCoffeeLoading: false
};

const virtualCoffeeReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_VIRTUAL_COFFEE_DATA:
      return { ...state, ...action.payload };
    case SET_VIRTUAL_COFFEE_LOADING:
      return { ...state, loading: action.payload };
    case GET_CHANNEL_MEMBERS:
      return { ...state, channelMembers: action.payload };
    case SET_CHANNEL_MEMBERS_LOADING:
      return { ...state, channelMembersLoading: action.payload };
    case CREATE_VIRTUAL_COFFEE:
      return { ...state, virtualCoffee: action.payload, isCreator: true };
    case CREATE_VIRTUAL_COFFEE_LOADING:
      return { ...state, createVirtualCoffeeLoading: action.payload };
    case DELETE_VIRTUAL_COFFEE:
      return { ...state, virtualCoffee: null, isCreator: false };
    case DELETE_VIRTUAL_COFFEE_LOADING:
      return { ...state, deleteVirtualCoffeeLoading: action.payload };
    case UPDATE_VIRTUAL_COFFEE:
      return { ...state, virtualCoffee: action.payload };
    case UPDATE_VIRTUAL_COFFEE_LOADING:
      return { ...state, updateVirtualCoffeeLoading: action.payload };
    case TOGGLE_VIRTUAL_COFFEE:
      return { ...state, virtualCoffee: action.payload };
    case TOGGLE_VIRTUAL_COFFEE_LOADING:
      return { ...state, toggleVirtualCoffeeLoading: action.payload };
    default:
      return state;
  }
};

export default virtualCoffeeReducer;
