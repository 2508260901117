import { GET_UNPOPULAR_OPINION_DATA, SET_UNPOPULAR_OPINION_LOADING } from '../types';

const initState = {
  unpopularOpinion: {},
  isCustomPollAllowed: false,
  loading: true
};

const unpopularOpinionReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case GET_UNPOPULAR_OPINION_DATA:
      return {
        ...state,
        unpopularOpinion: action.payload.unpopularOpinion,
        isCustomPollAllowed: action.payload.isCustomPollAllowed,
        loading: false
      };
    case SET_UNPOPULAR_OPINION_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default unpopularOpinionReducer;
