import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { Button } from '@fluentui/react-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const HomeTabButton = ({ title, className }) => (
  <Button
    className={classNames(styles.HomeTabButton, className)}
    onClick={() =>
      microsoftTeams.app.openLink(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`
      )
    }
    appearance='primary'
  >
    {title}
  </Button>
);

HomeTabButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

HomeTabButton.defaultProps = {
  title: 'Learn more',
  className: null
};

export default HomeTabButton;
