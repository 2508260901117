/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GetLeaderboardData, GetPageData, GetCategories } from 'redux/actions/leaderboard';
import { GetUserInfo } from 'redux/actions/user';
import { GetTeams } from 'redux/actions/schedule';
import serializeTeamsChannels from 'utils/serialization';
import { ReactComponent as EmptyIcon } from 'assets/images/empty-state.svg';
import Loading from 'components/Loading';
import Section from 'components/Section';
import RestrictedAccess from 'components/RestrictedAccess';
import Avatar from 'assets/images/avatar.svg';
import { ReactComponent as Sad } from 'assets/images/sad.svg';
import { Pagination, Row, Header } from 'components/Table';
import UserRank from 'components/UserRank';
import { getMedalWithRank } from 'utils';
import history from 'createHistory';
import Cards from './Card';
import './standings.scss';

const NO_TRIVIA_MSG = {
  WEEK: ' in the past week',
  MONTH: ' last month',
  ALL_TIME: ' ever'
};

const NO_PARTICIPATION_MSG = {
  WEEK: ' this week',
  MONTH: ' last month',
  ALL_TIME: ' ever'
};

const TIME_SPAN = [
  { name: 'Last 7 Days', value: 'WEEK' },
  { name: 'Last 30 Days', value: 'MONTH' },
  { name: 'All Time', value: 'ALL_TIME' }
];
class Standings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedChannel: 0,
      selectedCategory: 'All',
      selectedTimeSpan: 'WEEK',
      currentPage: 1
    };
  }

  componentDidMount() {
    const {
      workspace,
      GetCategories: getCategories,
      GetTeams: fetchTeams,
      categories,
      GetLeaderboardData: getLeaderboardData,
      GetPageData: getPageData,
      GetUserInfo: getUserInfo
    } = this.props;
    this.setState({ loading: true }, async () => {
      if (!workspace) fetchTeams(true);
      await getCategories({ workspaceId: workspace });
      const urlParams = new URLSearchParams(window.location.search);
      let category = urlParams.get('category');
      if (category) {
        category = categories.find((cat) => cat.name === category);
        if (category) {
          await getLeaderboardData({ timespan: 'WEEK', category: category.id }, workspace);
          this.setState({ selectedCategory: category.id });
        } else await getLeaderboardData({ timespan: 'WEEK', category: 'All' }, workspace);
      } else {
        await getLeaderboardData({ timespan: 'WEEK', category: 'All' }, workspace);
      }
      getPageData(1);
      getUserInfo();
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    const { currentPage } = this.state;
    const { GetPageData: getPageData } = this.props;
    const { currentPage: prevPage } = prevState;
    if (prevPage !== currentPage) {
      getPageData(currentPage);
    }
  }

  handleChannelChange = (event) => {
    // eslint-disable-next-line eqeqeq
    const channel = event.target.value == 0 ? null : event.target.value;
    this.setState({ selectedChannel: channel, loading: true }, () => {
      this.getLeaderboard();
    });
  };

  handleTimeSpanChange = (event) => {
    const timespan = event.target ? event.target.value : event;
    this.setState({ selectedTimeSpan: timespan, loading: true }, () => {
      this.getLeaderboard();
    });
  };

  getLeaderboard = async () => {
    const { selectedTimeSpan, selectedCategory, selectedChannel } = this.state;
    const {
      workspace,
      GetLeaderboardData: getLeaderboardData,
      GetPageData: getPageData
    } = this.props;
    await getLeaderboardData(
      {
        timespan: selectedTimeSpan,
        category: selectedCategory,
        channel: selectedChannel
      },
      workspace
    );
    getPageData(1);
    this.setState({ loading: false });
  };

  render() {
    const {
      invalidWorkspace,
      search,
      allUsers,
      channels,
      noTrivia,
      isTab,
      pages,
      currentUserData,
      noParticipation,
      allowedToView
    } = this.props;
    const { currentPage, selectedTimeSpan, loading } = this.state;
    if (invalidWorkspace) {
      history.replace('/public/404');
      return <div />;
    }
    let leaderboard = !search
      ? // eslint-disable-next-line react/destructuring-assignment
        this.props.leaderboard
      : allUsers?.filter((user) => user?.name?.toLowerCase().includes(search.toLowerCase()));

    const topThree = leaderboard?.slice(0, 3);
    leaderboard = !search && currentPage <= 1 ? leaderboard?.slice(3) : leaderboard;
    if (!allowedToView) {
      return (
        <Section className='standings-section' style={{ marginBottom: '5rem' }}>
          <RestrictedAccess withoutButton />
        </Section>
      );
    }

    const groupedChannels = {};
    channels.forEach((channel) => {
      const group = channel.group || 'All Conversations';
      if (!groupedChannels[group]) {
        groupedChannels[group] = [];
      }
      groupedChannels[group].push(channel);
    });

    return (
      <>
        <div className='filter-parent'>
          <div className='timespan-switch'>
            <div
              className={selectedTimeSpan === 'WEEK' ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                this.handleTimeSpanChange('WEEK');
              }}
            >
              Last 7 Days
            </div>
            <div
              className={selectedTimeSpan === 'MONTH' ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                this.handleTimeSpanChange('MONTH');
              }}
            >
              Last 30 Days
            </div>
            <div
              className={selectedTimeSpan === 'ALL_TIME' ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                this.handleTimeSpanChange('ALL_TIME');
              }}
            >
              All Time
            </div>
          </div>
          <div className='filters'>
            <div className='mobile-timespan-switch'>
              <select
                id='timeSpanSelect'
                name='timeSpanSelect'
                className='customSelectChannel'
                placeholder='Select the time span'
                onChange={this.handleTimeSpanChange}
              >
                {TIME_SPAN.map((option, index) => (
                  <option
                    key={index}
                    data-value={option.value}
                    className='customSelectChannelOption'
                    value={option.value}
                    onChange={this.handleTimeSpanChange}
                    selected={selectedTimeSpan === option.value}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                className='customSelectChannel'
                list='answers'
                id='SelectChannel'
                name='SelectChannel'
                placeholder='Search for channels'
                onChange={this.handleChannelChange}
              >
                {Object.keys(groupedChannels).map((group, index) => (
                  <optgroup label={group} key={index} className='customSelectChannelOption'>
                    {groupedChannels[group].map((channel, channelIndex) => {
                      // Limit the length of the channel label
                      const label =
                        channel.label.length > 18
                          ? `${channel.label.substring(0, 18)}....`
                          : channel.label;

                      return (
                        <option
                          key={channelIndex}
                          data-value={channel.value}
                          className='customSelectChannelOption'
                          value={channel.value}
                          onChange={this.handleTimeSpanChange}
                          selected={selectedTimeSpan === channel.value}
                        >
                          {label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='leaderboard-content-wrapper'>
          {loading ? (
            <Loading height={100} width={100} />
          ) : (
            noTrivia && <NoTrivia noTrivia={noTrivia} timespan={selectedTimeSpan} />
          )}
          {!noTrivia && !loading && (
            <div className='leaderboard-table-container'>
              {currentPage <= 1 && !search && <Cards users={topThree} />}
              {!isTab && search === '' && (
                <UserRank
                  userData={{
                    name: currentUserData.name,
                    photo: currentUserData.photo,
                    rank: currentUserData.rank,
                    data: {
                      played: currentUserData.played || 0,
                      score: currentUserData.score || 0
                    },
                    error: noParticipation && (
                      <>
                        <Sad />
                        <div className='error-message'>
                          You did not participate in any of the Trivia&apos;s held{' '}
                          {NO_PARTICIPATION_MSG[selectedTimeSpan]}
                        </div>
                      </>
                    )
                  }}
                />
              )}
              {(leaderboard?.length > 0 && (
                <>
                  <div className='leaderboard-table'>
                    <Header
                      columns={[
                        { label: 'Rank', size: 2, center: true },
                        { label: 'Name', size: 6 },
                        { label: 'Played', size: 2, center: true },
                        { label: 'Score', size: 2, center: true }
                      ]}
                    />
                    {leaderboard.map((user) => (
                      <Row
                        key={user.userId}
                        rank={currentUserData.rank}
                        columns={[
                          {
                            label: <div>{getMedalWithRank(user.rank)}</div>,
                            size: 2,
                            center: true,
                            bold: true
                          },
                          {
                            label: (
                              <div className='user-phone-name'>
                                <img src={user.photo || Avatar} alt='user' />
                                <div>{user.name}</div>
                              </div>
                            ),
                            size: 6
                          },
                          { label: user.played, size: 2, center: true, bold: true },
                          { label: user.score, size: 2, center: true, bold: true }
                        ]}
                      />
                    ))}
                  </div>

                  <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    pagesToShow={3}
                    onNext={() => this.setState({ currentPage: currentPage + 1 })}
                    onPrev={() => this.setState({ currentPage: currentPage - 1 })}
                    onPageClick={(page) => this.setState({ currentPage: page })}
                  />
                </>
              )) ||
                (noParticipation && (
                  <NoTrivia noTrivia={!search && !noTrivia} timespan={selectedTimeSpan} />
                ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

const NoTrivia = ({ noTrivia, timespan }) => (
  <div className='no-trivia'>
    <EmptyIcon />
    <div className='message'>
      {noTrivia
        ? `We couldn't find you having fun with Trivia ${NO_TRIVIA_MSG[timespan]}`
        : "Couldn't find anyone with that name."}
    </div>
  </div>
);

Standings.propTypes = {
  workspace: PropTypes.string.isRequired,
  GetCategories: PropTypes.func.isRequired,
  GetTeams: PropTypes.func.isRequired,
  GetUserInfo: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.string.isRequired,
  invalidWorkspace: PropTypes.bool,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  noTrivia: PropTypes.bool.isRequired,
  isTab: PropTypes.bool,
  pages: PropTypes.arrayOf(PropTypes.number),
  currentUserData: PropTypes.shape().isRequired,
  noParticipation: PropTypes.bool.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  GetPageData: PropTypes.func.isRequired,
  leaderboard: PropTypes.arrayOf(PropTypes.object).isRequired,
  GetLeaderboardData: PropTypes.func.isRequired,
  allowedToView: PropTypes.bool.isRequired
};

Standings.defaultProps = {
  invalidWorkspace: false,
  isTab: false,
  pages: [1]
};

NoTrivia.propTypes = {
  noTrivia: PropTypes.bool.isRequired,
  timespan: PropTypes.string.isRequired
};

const mapStateToProps = ({ dashboard, leaderboard, channelData, categories }) => {
  const totalPages = leaderboard?.leaderboard && Math.ceil(leaderboard.leaderboard.length / 10);
  const currentUserId = dashboard?.user?.userId || null;
  const currentUserData = leaderboard?.leaderboard?.find((user) => user.userId === currentUserId);
  const channelOptions = serializeTeamsChannels(channelData?.teams, true);

  return {
    photo: dashboard?.user?.image,
    allUsers: leaderboard?.leaderboard,
    leaderboard: leaderboard?.currentPage,
    categories: categories?.trivia,
    currentUserId,
    pages: Array.from(Array(totalPages), (_, i) => i + 1),
    currentUserData: currentUserData || {},
    noTrivia: leaderboard?.leaderboard.length === 0,
    noParticipation: !currentUserData,
    invalidWorkspace: leaderboard?.invalidWorkspace,
    channels: channelOptions,
    allowedToView: leaderboard?.allowedToView
  };
};

export default connect(mapStateToProps, {
  GetLeaderboardData,
  GetCategories,
  GetPageData,
  GetTeams,
  GetUserInfo
})(Standings);
