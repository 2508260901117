/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Option } from '@fluentui/react-components';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import dropdowns from 'data/dropdowns';
import { _12hFormats } from 'data/time';
import { alphabeticalTimezones as timezones } from 'data/timezones';
import classNames from 'classnames';
import styles from './styles.module.scss';

const VCForm = (props) => {
  const {
    users,
    handleChange,
    formFields,
    handleSubmit,
    disable,
    edit,
    handleBack,
    handleExcludeUsers
  } = props;
  const { theme } = useAppContext();

  return (
    <Layout
      customBackButtonAction
      backButtonAction={handleBack}
      showChannelDetails
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
      buttonText={edit ? 'Update' : 'Enable'}
      hideChannelName
    >
      <div
        className={classNames(styles.VCFormContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>
          {edit ? 'Edit VirtualCoffee' : 'Setup up VirtualCoffee'}
        </div>
        <div className={classNames(styles.VCFormContainer, styles.darkTheme)}>
          <div className={styles.labelContainer}>
            <label>Select frequency</label>
            <Dropdown
              placeholder='Select frequency'
              defaultValue={formFields?.frequency ?? undefined}
              defaultSelectedOptions={[formFields?.frequency ?? undefined]}
              onOptionSelect={(e, value) => handleChange(value, 'frequency')}
            >
              {dropdowns.virtualCoffeeFrequency.map((value) => (
                <Option key={value.content}>{value.content}</Option>
              ))}
            </Dropdown>
          </div>
          <div className={styles.labelContainer}>
            <label>Select day</label>
            <Dropdown
              placeholder='Select day'
              defaultSelectedOptions={[formFields?.day ?? undefined]}
              defaultValue={formFields?.day ?? undefined}
              onOptionSelect={(e, value) => handleChange(value, 'day')}
            >
              {dropdowns.days.map((day) => (
                <Option key={day}>{day}</Option>
              ))}
            </Dropdown>
          </div>
          <div className={styles.labelContainer}>
            <label>Select time to meet</label>
            <Dropdown
              placeholder='Select time'
              defaultSelectedOptions={[formFields?.time]}
              defaultValue={formFields?.time}
              onOptionSelect={(e, value) => handleChange(value, 'time')}
            >
              {_12hFormats.map((time) => (
                <Option key={time}>{time}</Option>
              ))}
            </Dropdown>
          </div>
          <div className={styles.labelContainer}>
            <label>Select timezone</label>
            <Dropdown
              placeholder='Search timezone'
              defaultSelectedOptions={[formFields?.timezone]}
              defaultValue={
                timezones.find((timezone) => timezone.value === formFields.timezone)?.name
              }
              onOptionSelect={(e, value) =>
                handleChange(
                  timezones.find((timezone) => timezone.name === value.optionValue)?.value,
                  'timezone'
                )
              }
            >
              {timezones.map((timezone) => (
                <Option key={timezone.name}>{timezone.name}</Option>
              ))}
            </Dropdown>
          </div>
          <div className={`${styles.labelContainer} ${styles.wideCombobox}`}>
            <label>Exclude users</label>
            <Dropdown
              placeholder='Select user'
              multiselect
              onOptionSelect={(e, value) => handleExcludeUsers.onAdd(value)}
              defaultValue={formFields?.users?.join(', ')}
              defaultSelectedOptions={formFields.users}
            >
              {users.map((user) => (
                <Option key={user.id}>{user.header}</Option>
              ))}
            </Dropdown>
          </div>
        </div>
      </div>
    </Layout>
  );
};

VCForm.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    })
  ),
  handleChange: PropTypes.func.isRequired,
  formFields: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.string).isRequired,
    frequency: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
  }).isRequired,
  handleSubmit: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  edit: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleExcludeUsers: PropTypes.func.isRequired
};

VCForm.defaultProps = {
  users: [],
  edit: false,
  disable: false
};

export default VCForm;
