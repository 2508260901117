import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAppContext } from 'context';
import {
  Image,
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@fluentui/react-components';
import { GetChannelMembers } from 'redux/actions/virtualCoffee';
import dropdowns from 'data/dropdowns';
import Layout from 'components/TaskModule/Layout';
import calender from 'assets/images/virtualCoffee/calender.svg';
import clock from 'assets/images/virtualCoffee/clock.svg';
import people from 'assets/images/virtualCoffee/people.svg';
import disabled from 'assets/images/virtualCoffee/disabled.svg';
import deleteIcon from 'assets/images/virtualCoffee/deleteIcon.svg';
import pause from 'assets/images/virtualCoffee/pause.svg';
import resume from 'assets/images/virtualCoffee/resume.svg';
import classNames from 'classnames';
import Footer from '../../Footer';
import styles from './styles.module.scss';

const EditVirtualCoffee = (props) => {
  const {
    virtualCoffee,
    isAdmin,
    isCreator,
    handleClick,
    handleDelete,
    channelMembers,
    disable,
    GetChannelMembers: fetchChannelMembers,
    handleToggle,
    showDoneCTA,
    handleSecondaryButtonClick
  } = props;
  const { active, frequency, nextEventAt, excludedUsers } = virtualCoffee;
  const {
    theme,
    entryPoint,
    channelId,
    chatId,
    serviceUrl,
    tenantId,
    currentUserName,
    currentUserId
  } = useAppContext();

  useEffect(() => {
    if (virtualCoffee && channelMembers.length === 0) {
      const conversationId = channelId || chatId;
      fetchChannelMembers(conversationId, serviceUrl, tenantId);
    }
  }, []);

  const getExcludedUsersText = () => {
    let excludedUsersText = '';
    excludedUsers.forEach((userId, index) => {
      const user = channelMembers.find((member) => member.userId === userId);
      // To make sure that the user is still part of the team
      if (user) {
        if (index === excludedUsers.length - 1) {
          excludedUsersText += `${user.userName}`;
        } else {
          excludedUsersText += `${user.userName}, `;
        }
      }
    });
    return excludedUsersText;
  };

  const getDescriptionText = () => {
    let descriptionText = 'You have VirtualCoffee enabled here';
    if (!active) {
      if (currentUserId === virtualCoffee.user) {
        descriptionText = (
          <>
            You have <b>paused</b> VirtualCoffee pairings
          </>
        );
      } else {
        descriptionText = (
          <>
            VirtualCoffee pairings are <b>paused</b> currently
          </>
        );
      }
    }
    return descriptionText;
  };

  return (
    <Layout
      route='shortcuts'
      backButtonText={entryPoint === 'Virtual Coffee' ? 'View all Trivia formats' : 'Back'}
      showChannelDetails
      actionButton={isAdmin || isCreator}
      buttonText={showDoneCTA ? 'Done' : 'Edit'}
      handleClick={handleClick}
      buttonDisabled={disable}
      showSecondaryButton={showDoneCTA}
      handleSecondaryButtonClick={handleSecondaryButtonClick}
      hideChannelName
    >
      <div
        className={classNames(styles.virtualCoffeeFormContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Hey {currentUserName}!</div>
        <div className={styles.description}>{getDescriptionText()}</div>
        <span className={styles.virtualCoffeeDetails}>
          <div className={styles.setupStepsWrapper}>
            <div className={styles.stepText}>
              <Image src={calender} alt='calender icon' />
              <div className={styles.text}>
                Intros&nbsp;
                {dropdowns.virtualCoffeeFrequency
                  .find((vc) => vc.key === frequency.toString())
                  .content.toLowerCase()}
                .
              </div>
            </div>
            <div className={styles.stepText}>
              <Image src={clock} alt='clock icon' />
              <div className={styles.text}>
                Next round of intros go on <b>{nextEventAt}.</b>
              </div>
            </div>
            <div className={styles.stepText}>
              <Image src={people} alt='people icon' />
              <div className={styles.text}>
                {excludedUsers.includes(currentUserId)
                  ? 'You have been excluded from pairings.'
                  : 'You are in queue to be matched.'}
              </div>
            </div>
            {excludedUsers.length > 0 && channelMembers.length > 0 && (
              <div className={styles.stepText}>
                <Image src={disabled} alt='disabled icon' />
                <div className={styles.text}>
                  {getExcludedUsersText()}
                  &nbsp;{excludedUsers.length > 1 ? 'are' : 'is'} excluded from the pairings.
                </div>
              </div>
            )}
          </div>
          {!active && <div className={styles.overlay} />}
        </span>
        {(isAdmin || isCreator) && (
          <div className={styles.actionButtons}>
            {active ? (
              <Button
                icon={<Image src={pause} alt='pause icon' />}
                appearance='transparent'
                className={styles.pauseButton}
                onClick={handleToggle}
              >
                Pause Pairings
              </Button>
            ) : (
              <Button
                icon={<Image src={resume} alt='resume icon' />}
                appearance='transparent'
                className={styles.resmueButton}
                onClick={handleToggle}
              >
                Resume Pairings
              </Button>
            )}
            <Dialog className={styles.deleteDialog}>
              <DialogTrigger>
                <Button
                  icon={<Image src={deleteIcon} alt='delete icon' />}
                  appearance='transparent'
                  className={styles.deleteButton}
                >
                  Delete the schedule
                </Button>
              </DialogTrigger>
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Are you sure you want to delete this schedule?</DialogTitle>
                  <DialogContent>
                    All the upcoming VC pairing rounds will also be deleted.
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance='secondary'>Go back</Button>
                    </DialogTrigger>
                    <Button className={styles.deleteButton} onClick={handleDelete}>
                      Delete
                    </Button>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        )}
        <Footer />
      </div>
    </Layout>
  );
};

EditVirtualCoffee.propTypes = {
  virtualCoffee: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    frequency: PropTypes.number.isRequired,
    nextEventAt: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    excludedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.string.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  channelMembers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired
    })
  ).isRequired,
  handleToggle: PropTypes.bool.isRequired,
  disable: PropTypes.bool.isRequired,
  GetChannelMembers: PropTypes.func.isRequired,
  showDoneCTA: PropTypes.bool.isRequired,
  handleSecondaryButtonClick: PropTypes.func.isRequired
};

export default connect(null, {
  GetChannelMembers
})(EditVirtualCoffee);
