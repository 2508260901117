import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { GetUpoData } from 'redux/actions/unpopularOpinion';
import { useAppContext } from 'context';
import Layout from 'components/TaskModule/Layout';
import SelectOpinion from './SelectOpinion';

const UnpopularOpinion = (props) => {
  const { unpopularOpinion, GetUpoData: getUpoData, isCustomPollAllowed, loading } = props;
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const { tenantId, ts, startedVia, setTs, setStartedVia, setCurrentUserName } = useAppContext();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia]);

  const handleSubmit = () => {
    setDisableButton(true);
    const formData = {};
    if (unpopularOpinion) {
      formData.opinionDoc = unpopularOpinion;
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    microsoftTeams.dialog.url.submit(
      { value: 'upo_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  const fetchUpoData = (tid = null) => {
    getUpoData(tenantId || tid);
  };

  const handleShowOpinion = () => {
    navigate('/task-module/custom-poll');
  };

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      fetchUpoData(context.user.tenant.id);
    });
    microsoftTeams.app.notifySuccess();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      route='all-games'
      showChannelDetails
      actionButton
      buttonDisabled={disableButton || loading}
      handleClick={handleSubmit}
    >
      <SelectOpinion
        loading={loading}
        isCustomPollAllowed={isCustomPollAllowed}
        handleShuffle={fetchUpoData}
        opinion={unpopularOpinion?.opinion}
        answers={unpopularOpinion?.answers}
        handleShowOpinion={handleShowOpinion}
      />
    </Layout>
  );
};

UnpopularOpinion.propTypes = {
  unpopularOpinion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    _id: PropTypes.string,
    opinion: PropTypes.string
  }).isRequired,
  GetUpoData: PropTypes.func.isRequired,
  isCustomPollAllowed: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  unpopularOpinion: { unpopularOpinion, isCustomPollAllowed, loading }
}) => ({
  unpopularOpinion,
  isCustomPollAllowed,
  loading
});

export default connect(mapStateToProps, {
  GetUpoData
})(UnpopularOpinion);
