// User related action types
export const LOGOUT = 'LOGOUT';
export const LOADING_USER = 'LOADING_USER';
export const GET_ADMIN_DATA = 'GET_ADMIN_DATA';

// Scheduler action types
export const GET_UPCOMING_SCHEDULES = 'GET_UPCOMING_SCHEDULES';
export const GET_ALL_SCHEDULER_CHANNELS = 'GET_ALL_SCHEDULER_CHANNELS';
export const TEAM_NAME = 'TEAM_NAME';
export const IS_USER_ADMIN = 'IS_USER_ADMIN';
export const GET_ALL_ADMINS = 'GET_ALL_ADMINS';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const MODIFY_SCHEDULE = 'MODIFY_SCHEDULE';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';

// Categories action types
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_GROUPED_CATEGORIES = 'GET_GROUPED_CATEGORIES';

// Leaderboard action types
export const GET_LEADERBOARD_DATA = 'GET_LEADERBOARD_DATA';
export const GET_PAGE_DATA = 'GET_PAGE_DATA';
export const SET_INVALID_WORKSPACE = 'SET_INVALID_WORKSPACE';
export const SET_ALLOWED_TO_VIEW = 'SET_ALLOWED_TO_VIEW';

// Channel Data types
export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_TEAMS = 'GET_TEAMS';
export const GET_ALL_TEAMS = 'GET_ALL_TEAMS';
export const CHAT_NAME = 'CHAT_NAME';

// Dashboard action types
export const GET_USER = 'GET_USER';

// Watercooler action types
export const GET_WATERCOOLER_PROMPT = 'GET_WATERCOOLER_PROMPT';
export const SET_WATERCOOLER_LOADING = 'SET_WATERCOOLER_LOADING';

// Gotcha action types
export const GET_CHANNEL_USER_COUNT = 'GET_CHANNEL_USER_COUNT';
export const SET_GOTCHA_LOADING = 'SET_GOTCHA_LOADING';

// Unpopular opinion action types
export const GET_UNPOPULAR_OPINION_DATA = 'GET_UNPOPULAR_OPINION_DATA';
export const SET_UNPOPULAR_OPINION_LOADING = 'SET_UNPOPULAR_OPINION_LOADING';

// Virtual Coffee action types
export const GET_VIRTUAL_COFFEE_DATA = 'GET_VIRTUAL_COFFEE_DATA';
export const SET_VIRTUAL_COFFEE_LOADING = 'SET_VIRTUAL_COFFEE_LOADING';
export const GET_CHANNEL_MEMBERS = 'GET_CHANNEL_MEMBERS';
export const SET_CHANNEL_MEMBERS_LOADING = 'SET_CHANNEL_MEMBERS_LOADING';
export const CREATE_VIRTUAL_COFFEE = 'CREATE_VIRTUAL_COFFEE';
export const CREATE_VIRTUAL_COFFEE_LOADING = 'CREATE_VIRTUAL_COFFEE_LOADING';
export const DELETE_VIRTUAL_COFFEE = 'DELETE_VIRTUAL_COFFEE';
export const DELETE_VIRTUAL_COFFEE_LOADING = 'DELETE_VIRTUAL_COFFEE_LOADING';
export const UPDATE_VIRTUAL_COFFEE = 'UPDATE_VIRTUAL_COFFEE';
export const UPDATE_VIRTUAL_COFFEE_LOADING = 'UPDATE_VIRTUAL_COFFEE_LOADING';
export const TOGGLE_VIRTUAL_COFFEE = 'TOGGLE_VIRTUAL_COFFEE';
export const TOGGLE_VIRTUAL_COFFEE_LOADING = 'TOGGLE_VIRTUAL_COFFEE_LOADING';

// Home actions types
export const GET_HOME_PAGE_DATA = 'GET_HOME_PAGE_DATA';
export const PLAN_NAME = 'PLAN_NAME';
export const SUBSCRIPTION_DATA = 'SUBSCRIPTION_DATA';
export const ENGAGEMENT_DATA = 'ENGAGEMENT_DATA';
export const USER_DETAILS = 'USER_DETAILS';

// constants
export const BASIC = 'BASIC';
export const STANDARD = 'STANDARD';
export const PRO = 'PRO';
