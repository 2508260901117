import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'context';
import * as microsoftTeams from '@microsoft/teams-js';
import Layout from 'components/TaskModule/Layout';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Form from './Form';

const CustomPoll = () => {
  const [formFields, setFormFields] = useState({
    opinion: '',
    answers: ['', '', '', '']
  });
  const [disableButton, setDisableButton] = useState(false);
  const [errorState, setErrorState] = useState('');
  const { ts, startedVia, setStartedVia, setTs, setCurrentUserName } = useAppContext();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setCurrentUserName]);

  const handleChange = (key, value, index = null) => {
    if (disableButton) {
      setDisableButton(false);
    }
    if (key === 'answers') {
      if (errorState === 'answers') {
        setErrorState('');
      }
      const temp = { ...formFields };
      temp.answers[index] = value;
      setFormFields(temp);
    } else {
      if (errorState === 'opinion') {
        setErrorState('');
      }
      setFormFields((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleSubmit = () => {
    setDisableButton(true);
    const formData = {};
    if (formFields.opinion) {
      formData.opinion = formFields.opinion;
    } else {
      setErrorState('opinion');
      return;
    }
    if (formFields.answers) {
      formData.answers = formFields.answers.filter((answer) => answer);
    }
    if (formData.answers.length < 2) {
      setErrorState('answers');
      return;
    }
    if (_.uniq(formData.answers).length !== formData.answers.length) {
      toast.error(
        'Some options are the same. Please try providing different values for those options.'
      );
      return;
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    formData.custom = true;
    microsoftTeams.dialog.url.submit(
      { value: 'upo_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  const navigate = useNavigate();
  const backButtonAction = () => {
    navigate('/task-module/all-games');
  };

  return (
    <Layout
      route='unpopular-opinion'
      showChannelDetails
      actionButton
      customBackButtonAction
      backButtonAction={backButtonAction}
      buttonDisabled={disableButton}
      handleClick={handleSubmit}
    >
      <Form formFields={formFields} errorState={errorState} handleChange={handleChange} />
    </Layout>
  );
};

CustomPoll.propTypes = {
  unpopularOpinion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    _id: PropTypes.string.isRequired,
    opinion: PropTypes.string.isRequired
  }).isRequired
};

export default CustomPoll;
