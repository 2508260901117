import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NoAccess from 'assets/images/errortape.svg';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import Avatar from 'assets/images/avatar.svg';
import { connect } from 'react-redux';
import { GetAdmins } from 'redux/actions/leaderboard';
import Tooltip from 'components/Tooltip';

const RestrictedAccess = ({
  message = 'You are on the basic plan',
  adminRestriction = false,
  isTenSpot = false,
  withoutButton,
  ...props
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { GetAdmins: getAdmins } = props;
    if (adminRestriction) {
      getAdmins(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='restricted-access-container'>
      <img src={NoAccess} alt='no-access' className='no-access-img' />
      <div className='message-wrapper'>
        <div className='title'>{message}</div>
        {!isTenSpot &&
          (!adminRestriction ? (
            <div className='subtitle'>Upgrade plan to access this section</div>
          ) : (
            <>
              <div className='subtitle'>Contact your workspace admins for access to this page</div>
              <div className='admins-photos'>
                {props.admins?.map((admin) => (
                  <Tooltip key={admin.id} title={admin.name}>
                    <img src={admin.photo || Avatar} alt={admin.name} />
                  </Tooltip>
                ))}
              </div>
            </>
          ))}
      </div>
      {!adminRestriction && !withoutButton && (
        <Button onClick={() => navigate('/billing')}>View plans</Button>
      )}
    </div>
  );
};

RestrictedAccess.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object).isRequired,
  message: PropTypes.string,
  adminRestriction: PropTypes.bool,
  isTenSpot: PropTypes.bool,
  GetAdmins: PropTypes.func.isRequired,
  withoutButton: PropTypes.bool
};

RestrictedAccess.defaultProps = {
  message: 'You are on the basic plan',
  adminRestriction: false,
  isTenSpot: false,
  withoutButton: false
};

export const mapStateToProps = ({ settings }) => ({
  admins: settings?.admins || []
});

export default connect(mapStateToProps, { GetAdmins })(RestrictedAccess);
