import { triviaTeams } from 'api';
import { toast } from 'react-toastify';

import {
  ENGAGEMENT_DATA,
  PLAN_NAME,
  SUBSCRIPTION_DATA,
  GET_ALL_ADMINS,
  USER_DETAILS
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export const GetHomePageData =
  (tenantId, userId = null) =>
  async (dispatch) => {
    const data = await triviaTeams.get('/home', { params: { tenantId, userId } });
    if (data.ok) {
      dispatch({ type: PLAN_NAME, payload: data.plan });
      dispatch({ type: SUBSCRIPTION_DATA, payload: data.subscriptionDetails });
      if (data.enagagementData) {
        dispatch({ type: ENGAGEMENT_DATA, payload: data.enagagementData });
      }
      if (data.userDetails) {
        dispatch({ type: USER_DETAILS, payload: data.userDetails });
      }
      if (data.admins) {
        dispatch({ type: GET_ALL_ADMINS, payload: data.admins });
      }
    } else {
      toast.error(data.error || 'Failed to get home page data');
    }
    return data;
  };
