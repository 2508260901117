/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import PropTypes from 'prop-types';
import { ReactComponent as Woman } from 'assets/images/woman-with-clipboard.svg';
import { ReactComponent as Woman1 } from 'assets/images/women-with-dashboard.svg';
import Button from 'components/Button';
import classNames from 'classnames';
import styles from './Footer.module.scss';

const Footer = (props) => {
  const { darkTheme, freeTrial } = props;
  return (
    <div className={classNames(styles.bodyWrapper, { [styles.darkTheme]: darkTheme })}>
      {freeTrial ? <Woman /> : <Woman1 />}
      <div className={styles.detailsText}>
        <div className={darkTheme ? styles.lightText : styles.helpText}>
          <div className={styles.img}>
            {freeTrial ? 'Need help?' : 'Want to see how your team is doing?'}
          </div>
        </div>
        {!freeTrial && (
          <div className={darkTheme ? styles.lightVisitDashboardText : styles.visitDashboardText}>
            {`Visit Trivia's Dashboard to manage Billing and Licenses, view`}
            <br />
            Team Analytics, Leaderboard and much more!
          </div>
        )}
        <Button
          className={styles.demoButton}
          onClick={() =>
            freeTrial
              ? microsoftTeams.app.openLink('https://calendly.com/trivia-1/trivia-demo/')
              : window.open(process.env.REACT_APP_DASHBOARD_URL)
          }
        >
          {freeTrial ? 'Book a demo' : 'View Dashboard'}
        </Button>
        {freeTrial && (
          <>
            <div className={styles.orText}>OR</div>
            <span className={darkTheme ? styles.lightMailText : styles.mailText}>
              Contact us at{' '}
              <a onClick={() => microsoftTeams.app.openLink('mailto:trivia@springworks.in?')}>
                trivia@springworks.in
              </a>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  darkTheme: PropTypes.bool,
  freeTrial: PropTypes.bool.isRequired
};

Footer.defaultProps = {
  darkTheme: false
};

export default Footer;
