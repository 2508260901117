import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { triviaTeams } from 'api';
import { Button, Image, Spinner } from '@fluentui/react-components';
import Warning from 'assets/images/triangle-warning.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const LandingPage = () => {
  const [dataInfo, setDataInfo] = useState([]);
  const [url, setUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [switchPlanLoading, setIsSwitchPlanLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [existingStripeSubscription, setExistingStripeSubscription] = useState(false);
  const { search } = useLocation();
  const token = new URLSearchParams(search)?.get('token');
  const isPurchaserDetailExist =
    dataInfo?.purchaser?.email ??
    dataInfo?.purchaser?.alternateEmail ??
    dataInfo?.purchaser?.username;

  useEffect(() => {
    const resolveToken = async () => {
      setIsLoading(true);
      const data = await triviaTeams.post('/auth/validate-token', {
        token: decodeURIComponent(token)
      });

      setIsLoading(false);

      if (!data?.ok) {
        setIsError(true);
        setDataInfo(data.data);
        setUrl(data.loginPageUrl);
        setExistingStripeSubscription(data.data?.existingStripeSubscription);
        return;
      }
      window.open(data?.loginPageUrl, '_self');
    };
    resolveToken();
  }, []);

  const onSwitchNowClick = async () => {
    setIsSwitchPlanLoading(true);
    const data = await triviaTeams.post(
      `/subscription/convert-to-monetization`,
      {
        workspaceId: dataInfo?.workspaceId,
        subscriptionId: dataInfo?.teamsSubscription?.subscriptionId
      }
    );

    setIsSwitchPlanLoading(false);

    if (data?.ok) {
      return window.open(data?.loginPageUrl, '_self');
    }

    return setErrorMessage(data?.message);
  };

  return (
    <div data-testid='success-element' className={styles.wrapper}>
      {isLoading && (
        <div>
          <Spinner size='extra-large' data-testid='loader-element' className={styles.loader} />
          <div className={styles.waitText}>Hang on! we are processing</div>
        </div>
      )}
      {isError && !existingStripeSubscription && (
        <div data-testid='error-container' className={styles.errorContainer}>
          <div className={styles.errorText}>
            <div className={styles.errorHeader}>Thanks for choosing Trivia!</div>
            <br />
            You already have an active subscription with Trivia:
            <br />
            <br />
            <b>Active plan:&nbsp;&nbsp;</b>
            {dataInfo?.plan}
            <br />
            <b>Subscribed by:&nbsp;&nbsp;</b>
            {dataInfo?.purchaser?.fullname}&nbsp;
            {isPurchaserDetailExist &&
              `(${
                dataInfo?.purchaser?.email ??
                dataInfo?.purchaser?.alternateEmail ??
                dataInfo?.purchaser?.username
              })`}
            <br />
            <br />
            To avoid duplicate subscriptions, your new subscription was not activated and you won’t
            be billed for it.
            <br />
            <br />
            To view more details about your active subscription or manage user licenses, go to your
            Billing page.
          </div>

          <Button
            className={styles.button}
            onClick={() => window.open(url, '_self')}
            appearance='primary'
          >
            Open Billing page
          </Button>
        </div>
      )}
      {isError && existingStripeSubscription && (
        <div data-testid='error-container' className={styles.stripeErrorContainer}>
          <div>
            <div className={styles.heading}>Yayy! Thanks for choosing Trivia!</div>
            <div className={styles.subHeading}>
              You already have an active subscription with us.
            </div>
          </div>

          <div className={styles.planDetailsContainer}>
            <div className={styles.planDetail}>
              Current Plan
              <b className={classNames(styles.info, styles.customInfoStyles)}>
                {dataInfo?.stripe?.plan}
              </b>
            </div>
            <div className={styles.planDetail}>
              Billing name
              <b className={classNames(styles.info, styles.customInfoStyles)}>
                {dataInfo?.stripe?.name}
              </b>
            </div>
            <div className={styles.planDetail}>
              Billing email
              <b className={styles.info}>{dataInfo?.stripe?.email}</b>
            </div>
          </div>

          <div className={styles.description}>
            <Image className={styles.warning} width='20' src={Warning} />

            <div>
              Trivia&apos;s billing has been updated to deeply integrate with Microsoft and let you
              pay for your subscription by the same method used to pay for other Microsoft services.
              <br />
              <br />
              Click on the &apos;Switch Now&apos; button and you will be automatically shifted to
              our new billing plan. Clicking on &apos;Continue existing plan&apos; will allow you to
              keep paying for Trivia in the same way as before.&nbsp;
              <div
                onClick={() =>
                  window.open(
                    'https://support.springworks.in/portal/en/kb/trivia/trivia-for-microsoft-teams/plans-pricing-subscriptions/subscribing-to-trivia'
                  )
                }
                className={styles.readMore}
              >
                Read more about it.
              </div>
            </div>
          </div>

          <div className={styles.subHeading}>Do you wish to switch to our new billing plan?</div>
          <div className={styles.buttonContainer}>
            <Button
              className={classNames(styles.button, styles.continue)}
              onClick={() => window.open(url, '_self')}
              appearance='primary'
            >
              Continue existing plan
            </Button>
            <Button
              className={classNames(styles.button)}
              appearance='primary'
              onClick={onSwitchNowClick}
            >
              <div className={styles.switchBtnInfo}>
                {switchPlanLoading && <Spinner size='extra-small' className={styles.loader} />}
                Switch Now
              </div>
            </Button>
          </div>

          {errorMessage && (
            <div className={styles.subHeading}>
              Something went wrong, Please mail to: trivia@springworks.in
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LandingPage;
