import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlayIcon } from 'assets/images/play-icon.svg';
import classNames from 'classnames';
import styles from './PlayList.module.scss';

const PlayList = (props) => {
  const { darkTheme, active, title } = props;
  return (
    <div
      className={classNames(styles.listWrapper, {
        [styles.darkBg]: darkTheme,
        [styles.activeBg]: active
      })}
    >
      <div
        className={classNames(styles.titleText, {
          [styles.activeText]: active,
          [styles.lightText]: darkTheme
        })}
      >
        {title}
      </div>
      <PlayIcon />
    </div>
  );
};

PlayList.propTypes = {
  darkTheme: PropTypes.bool,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired
};

PlayList.defaultProps = {
  darkTheme: false,
  active: false
};

export default PlayList;
