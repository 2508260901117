import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@fluentui/react-components';
import tickMark from 'assets/images/tick-mark.svg';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const Form = (props) => {
  const { handleSubmit, disable } = props;
  const { theme } = useAppContext();

  return (
    <Layout
      showChannelDetails
      route='all-games'
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.formContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch Pictionary</div>
        <div className={styles.text}>
          Right from digital whiteboards, moderators to score calculators, we offer everything you
          need to play a perfect round of Pictionary without any hassle.
        </div>
        <div className={styles.gameRulesListWrapper}>
          <div className={styles.gameRuleText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>You will be the host for this round</div>
          </div>
          <div className={styles.gameRuleText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>As a host you need to join the room before others</div>
          </div>
          <div className={styles.gameRuleText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>
              Don&apos;t forget to invite your teammates, after you enter the room
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired
};

export default Form;
