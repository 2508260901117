import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalenderIcon } from 'assets/images/scheduler/calender.svg';
import { maybePluralize } from 'utils';
import { useAppContext } from 'context';
import classNames from 'classnames';
import styles from './styles.module.scss';

const EmptyState = ({ isAdmin, admins }) => {
  const { theme, teamId } = useAppContext();

  const convertListToText = (arr) => arr.join(', ').replace(/,([^,]*)$/, ' and$1');

  return (
    <div className={classNames(styles.emptyStateWrapper)}>
      <div className={classNames(styles.pageTitle, { [styles.dark]: theme === 'dark' })}>
        Automate Trivia
      </div>
      {isAdmin ? (
        <>
          <div
            className={classNames(styles.emptyScheduleText, { [styles.dark]: theme === 'dark' })}
          >
            Set Trivia to launch automatically! Select a {teamId && <>channel, </>}time, frequency,
            and I will host Trivia for you 😎
          </div>
          <CalenderIcon className={classNames(styles.calenderIcon, styles.bounce)} />
        </>
      ) : (
        <div className={classNames(styles.emptyScheduleText, { [styles.dark]: theme === 'dark' })}>
          Want to have Games launch automatically so the fun never stops?🤩 Ask your Trivia{' '}
          {maybePluralize(admins.length, 'Admin')}{' '}
          <b>{convertListToText(admins.map((admin) => admin.name))}</b> to set up an Automation
          Schedule for everyone today!🤗
        </div>
      )}
    </div>
  );
};

EmptyState.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  admins: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EmptyState;
