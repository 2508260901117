import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import momentTz from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import gameFormats from 'data/formats';
import { _12hFormats } from 'data/time';
import moment from 'moment';
import * as microsoftTeams from '@microsoft/teams-js';
import { alphabeticalTimezones as timezones, toTzValue } from 'data/timezones';
import { GetGroupedCategories } from 'redux/actions/categories';
import { AddSchedule, EditSchedule } from 'redux/actions/schedule';
import { useAppContext } from 'context';
import Loading from 'components/Loading';
import Form from './Form';

const timeBetween = () => {
  const time = moment('04:30', 'hh:mm')
    .add(5 * Math.floor(Math.random() * 13), 'minutes')
    .format('HH:mm');

  return `${time} pm`;
};

const INITIAL_STATE = {
  gameTypes: ['random'],
  channel: '',
  team: '',
  daysOfTheWeek: [],
  time: '',
  timezone: toTzValue(momentTz.tz.guess()),
  category: [],
  gameSpeed: '',
  difficulty: '',
  loading: true
};

const difficultyData = [
  { value: 'Easy', name: 'Easy' },
  { value: 'Medium', name: 'Medium' },
  { value: 'Hard', name: 'Hard' }
];

const prefillFormFields = ({ data, props, currentSchedule, type, channelName }) => {
  const dataCopy = cloneDeep(data);

  dataCopy.team = props.team;
  dataCopy.time = currentSchedule?.time || timeBetween();
  dataCopy.channel = currentSchedule?.channelName ?? channelName;
  dataCopy.daysOfTheWeek = currentSchedule.days || ['Monday', 'Wednesday', 'Friday'];

  if (type === 'edit') {
    dataCopy.id = currentSchedule?.id;
    dataCopy.gameTypes = currentSchedule.gameTypes ?? ['random'];
  } else {
    dataCopy.gameTypes = ['random'];
  }

  if (currentSchedule?.timezone) {
    dataCopy.timezone = currentSchedule?.timezone;
  }

  if (typeof dataCopy.timezone === 'string') {
    dataCopy.timezone = toTzValue(dataCopy.timezone);
  }

  if (dataCopy.teamId) {
    dataCopy.team = dataCopy.teamId;
  }

  if (currentSchedule?.speed) {
    dataCopy.gameSpeed = currentSchedule?.speed;
  }
  dataCopy.difficulty = currentSchedule?.difficulty || '';
  dataCopy.category =
    props.categories
      ?.filter((cat) => currentSchedule?.category?.includes(cat.id))
      .map((c) => c.header) || [];

  return dataCopy;
};

const formatSubmitValues = ({ data }) => {
  if (!data) {
    return null;
  }
  const form = cloneDeep(data);
  if (form.gameTypes?.includes('Surprise Me!')) {
    form.gameTypes = ['random'];
  } else if (!form.gameTypes?.includes('random')) {
    const selectedFormats = gameFormats.filter((format) => form.gameTypes.includes(format.name));
    form.gameTypes = selectedFormats.map((format) => format.value);
  }
  form.timezone = timezones.find((timezone) => timezone.value === parseInt(form.timezone, 10)).name;
  if (!form.time) {
    [form.time] = _12hFormats;
  }

  if (!form.daysOfTheWeek.length || !form.time || !form.timezone) {
    return null;
  }

  if (form.gameTypes.includes('trivia') && form.category.length > 5) {
    toast.error('Please choose a maximum of 5 categories only.');
    return null;
  }

  if (form.difficulty === '' || !form?.gameTypes?.includes('trivia')) {
    delete form.difficulty;
  }
  if (form.gameSpeed === '') {
    delete form.gameSpeed;
  }
  if (form.category.length === 0 || !form?.gameTypes?.includes('trivia')) {
    delete form.category;
  }

  delete form.loading;
  delete form.channel;
  delete form.team;

  return form;
};

const handleToast = (resp, type) => {
  if (resp) {
    toast.success(`Automation ${type === 'edit' ? 'edited' : 'created'}`);
  }
};
const buttonTitle = (type) => (type === 'edit' ? 'Save' : 'Create');

let userObjectId;
const SchedulerForm = (props) => {
  const handleClose = (e) => {
    const { setCurrentSchedule, setShowForm } = props;
    e.preventDefault();
    setCurrentSchedule({});
    setShowForm(false);
  };

  const { type, channelData, currentSchedule, categories, chat } = props;
  const [data, setData] = useState({
    ...INITIAL_STATE,
    channel: type === 'edit' ? currentSchedule.channelName : channelData?.allChannels[0]?.name
  });
  const [disable, setDisable] = useState(false);
  const { theme, tenantId, teamId, chatId } = useAppContext();
  const [channels, setChannels] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const [maxCategories, setMaxCategories] = useState(false);
  const channelId = channelData?.allChannels?.find((item) =>
    item.name === data.channel ? item.id : ''
  )?.id;
  const categoryId = data.category?.map((cat) => categories.find((c) => c.header === cat)?.id);

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then(async (context) => {
      setData({ ...data, channel: context.channel?.displayName });
      userObjectId = context.user.id;
    });

    if (channelData.allChannels) {
      const tempChannels = channelData?.allChannels.map((channel) => channel.name);
      setChannels(tempChannels);
    }

    // eslint-disable-next-line
  }, [channelData?.allChannels]);

  useEffect(() => {
    const { GetGroupedCategories: getGroupedCategories } = props;
    if (tenantId !== '') {
      getGroupedCategories(tenantId);
    }

    // eslint-disable-next-line
  }, [tenantId]);

  useEffect(() => {
    const dataCopy = prefillFormFields({
      data,
      props,
      currentSchedule,
      type,
      channelName: data.channel
    });
    setData({ ...dataCopy, loading: false });
    // eslint-disable-next-line
  }, [categories]);

  // logic to handle category selection
  const handleCategorySelection = {
    onAdd: (item) => {
      // do not add category if the count is more than 5
      if (item.selectedOptions?.length <= 5) {
        setData({
          ...data,
          category: item.selectedOptions
        });
        setMaxCategories(false);
      } else {
        setMaxCategories(true);
      }
    },
    onRemove: (item) => {
      setData({
        ...data,
        category: data.category.filter((i) => i !== item)
      });
      setMaxCategories(false);
    }
  };

  const timeChange = (value) => {
    const [hours, minutes] = value.split(':');
    let period = 'am';

    let convertedHours = parseInt(hours, 10);
    if (convertedHours >= 12) {
      period = 'pm';
      if (convertedHours > 12) {
        convertedHours -= 12;
      }
    }
    return `${convertedHours}:${minutes} ${period}`;
  };

  const handleSelectChange = (event, filter) => {
    if (['daysOfTheWeek', 'category'].includes(filter)) {
      setData({ ...data, [filter]: [...event.selectedOptions] });
    } else if (filter === 'team') {
      setData({ ...data, [filter]: event, channel: '' });
    } else if (filter === 'gameTypes') {
      if (event?.optionValue?.includes('random') || event?.includes('random')) {
        setData({ ...data, [filter]: ['random'] });
      } else if (type === 'edit') {
        setData({ ...data, [filter]: event });
      } else if (event.length > 0) {
        setData({ ...data, [filter]: event });
      } else {
        setData({ ...data, [filter]: [] });
      }
    } else if (filter === 'timezone') {
      setData({ ...data, [filter]: event });
    } else if (filter === 'time') {
      setData({ ...data, [filter]: timeChange(event.value) });
    } else {
      setData({ ...data, [filter]: event.optionValue });
    }
  };

  const handleSubmit = async (e) => {
    setDisable(true);
    setCheckData(true);
    const { EditSchedule: editSchedule, AddSchedule: addSchedule } = props;
    const form = await formatSubmitValues({ data, props, channels });
    form.channelId = channelId ?? chatId;
    form.userId = userObjectId;
    if (teamId) {
      form.teamId = teamId;
    }

    if (categoryId.length > 0) {
      form.category = categoryId;
    }

    let resp;
    if (form) {
      if (type === 'edit') {
        const { id } = form;
        delete form.id;
        resp = await editSchedule(form, id, tenantId);
      } else resp = await addSchedule(form, tenantId);
    }
    setDisable(false);
    handleToast(resp, type);
    if (resp) {
      handleClose(e);
    }
  };

  if (data.loading) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  return (
    <Form
      data={data}
      chatName={chat}
      handleSelectChange={handleSelectChange}
      buttonTitle={buttonTitle}
      categories={categories}
      handleSubmit={handleSubmit}
      type={type}
      theme={theme}
      channelData={channelData}
      checkData={checkData}
      difficultyData={difficultyData}
      handleCategorySelection={handleCategorySelection}
      maxCategories={maxCategories}
      disable={disable}
    />
  );
};

SchedulerForm.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  specialCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  AddSchedule: PropTypes.func.isRequired,
  EditSchedule: PropTypes.func.isRequired,
  GetGroupedCategories: PropTypes.func.isRequired,
  setCurrentSchedule: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
  type: PropTypes.func,
  channelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSchedule: PropTypes.objectOf(PropTypes.any),
  chat: PropTypes.string
};

SchedulerForm.defaultProps = {
  categories: [],
  specialCategory: null,
  type: 'add',
  currentSchedule: null,
  chat: null
};

const mapStateToProps = ({
  categories: { categories, specialCategory },
  channelData,
  schedules
}) => {
  // To remove the label for more categories
  const groupedCategories = [];
  categories?.forEach((og) => {
    if (og.labelText !== '🔥 Trending') {
      og.options.forEach((cat) => {
        groupedCategories.push({
          header: cat.name,
          content: og.labelText === '🧩 More categories' ? '' : og.labelText,
          id: cat.id
        });
      });
    }
  });

  return {
    categories: groupedCategories,
    specialCategory,
    channelData,
    schedules: schedules?.upcoming
  };
};

export default connect(mapStateToProps, {
  AddSchedule,
  EditSchedule,
  GetGroupedCategories
})(SchedulerForm);
