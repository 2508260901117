import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context';
import { Image } from '@fluentui/react-components';
import Layout from 'components/TaskModule/Layout';
import tickMark from 'assets/images/tick-mark.svg';
import classNames from 'classnames';
import Footer from '../../Footer';
import styles from './styles.module.scss';

const SetupVirtualCoffee = (props) => {
  const { handleClick } = props;
  const { theme, entryPoint, currentUserName } = useAppContext();

  return (
    <Layout
      route='shortcuts'
      backButtonText={entryPoint === 'Virtual Coffee' ? 'View all Trivia formats' : 'Back'}
      showChannelDetails
      actionButton
      buttonText='Set up VirtualCoffee'
      handleClick={handleClick}
      hideChannelName
    >
      <div
        className={classNames(styles.virtualCoffeeFormContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Hey {currentUserName}!</div>
        <div className={styles.description}>
          Do you want to get to know your teammates better?
          <br />
          With VirtualCoffee, introduce teammates, skip the tension of awkward small talk and spark
          casual conversations.
        </div>
        <div className={styles.setupStepsWrapper}>
          <div className={styles.stepText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>Enable VirtualCoffee on a team with your people.</div>
          </div>
          <div className={styles.stepText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>
              I will automatically introduce teammates and prompt them to get to know each other
              better.
            </div>
          </div>
          <div className={styles.stepText}>
            <Image src={tickMark} alt='tick mark icon' />
            <div className={styles.text}>
              I will initiate virtual catch-ups with conversation starters. You can manage the
              frequency of this to be weekly, monthly or quarterly.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

SetupVirtualCoffee.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default SetupVirtualCoffee;
