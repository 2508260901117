import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  FluentProvider as FluentUi,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme
} from '@fluentui/react-components';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const AppContext = createContext({ theme: 'light' });

export const AppWrapper = ({ children }) => {
  // params variables
  const [startedVia, setStartedVia] = useState(null);
  const [ts, setTs] = useState(null);
  const [serviceUrl, setServiceUrl] = useState(null);
  const [plan, setPlan] = useState(null);
  const [entryPoint, setEntryPoint] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);

  // context variables
  const [theme, setTheme] = useState('light');
  const [deviceType, setDeviceType] = useState('web');
  const [channelName, setChannelName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [channelId, setChannelId] = useState('');
  const [tenantId, setTenantId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [fluentTheme, setFluentTheme] = useState(teamsLightTheme);
  const [currentUserId, setCurrentUserId] = useState(null);

  // Set the theme for the fluent UI and the context
  const changeTheme = (thm) => {
    setTheme(thm);
    if (thm === 'dark') {
      setFluentTheme(teamsDarkTheme);
    }
    if (thm === 'contrast') {
      setFluentTheme(teamsHighContrastTheme);
    }
  };

  // Fetching the theme so no need to fetch in all the components or pages
  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.notifySuccess();
    microsoftTeams.app.getContext().then(async (context) => {
      changeTheme(context.app.theme);
      setDeviceType(context.app.host.clientType);
      setChannelName(context.channel?.displayName || null);
      setTeamName(context.team?.displayName || null);
      setChannelId(context.channel?.id || null);
      setTenantId(context.user.tenant.id || null);
      setTeamId(context.team?.groupId || null);
      setMeetingId(context.meeting?.id || null);
      setChatId(context.chat?.id || null);
      setCurrentUserId(context.user?.id || null);
    });
    microsoftTeams.app.notifySuccess();
  }, []);

  const contextValues = useMemo(
    () => ({
      theme,
      deviceType,
      channelName,
      teamName,
      channelId,
      tenantId,
      teamId,
      chatId,
      meetingId,
      startedVia,
      setStartedVia,
      ts,
      setTs,
      serviceUrl,
      setServiceUrl,
      plan,
      setPlan,
      entryPoint,
      setEntryPoint,
      currentUserId,
      currentUserName,
      setCurrentUserName
    }),
    [
      theme,
      deviceType,
      channelName,
      teamName,
      channelId,
      tenantId,
      teamId,
      chatId,
      meetingId,
      startedVia,
      setStartedVia,
      ts,
      setTs,
      serviceUrl,
      setServiceUrl,
      plan,
      setPlan,
      entryPoint,
      setEntryPoint,
      currentUserId,
      currentUserName,
      setCurrentUserName
    ]
  );

  return (
    <AppContext.Provider value={contextValues}>
      <FluentUi theme={fluentTheme}>
        {children}
        {/* Setting theme for the toast */}
        <ToastContainer
          toastStyle={`${theme}` === 'dark' ? { backgroundColor: '#212121', color: 'white' } : null}
          position='bottom-left'
          autoClose={3500}
          draggable={false}
          theme={`${theme}` === 'dark' ? 'dark' : 'light'}
        />
      </FluentUi>
    </AppContext.Provider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.element.isRequired
};

export function useAppContext() {
  return useContext(AppContext);
}
