import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from '@fluentui/react-components';
import dashboard from 'assets/images/dashboard.svg';
import lightDashboard from 'assets/images/light-dashboard.svg';
import blueDashboard from 'assets/images/blue-dashboard.svg';
import phone from 'assets/images/phone.svg';
import lightPhone from 'assets/images/light-phone.svg';
import bluePhone from 'assets/images/blue-phone.svg';
import successStories from 'assets/images/success-stories.svg';
import lightSuccessStories from 'assets/images/light-success-stories.svg';
import classNames from 'classnames';
import { BASIC, PRO, STANDARD } from 'redux/types';
import useWindowSize from 'utils/hooks';
import { maybePluralize } from 'utils';
import styles from './Header.module.scss';

const Header = (props) => {
  const { darkTheme, plan, freeTrial, trialExpiresIn } = props;
  const dashboardUrl = process.env.REACT_APP_DASHBOARD_URL;
  const demoUrl = 'https://calendly.com/trivia-1/trivia-demo?month=2023-04';

  const displayPlanName = (pln) => {
    switch (pln) {
      case BASIC:
        return 'Basic Plan';
      case PRO:
        return 'Pro Plan';
      case STANDARD:
        return 'Standard Plan';
      default:
        return 'Free Trial ';
    }
  };

  const { width } = useWindowSize();
  return (
    <div className={classNames(styles.headerWrapper, { [styles.darkTheme]: darkTheme })}>
      <div className={styles.flexContainer}>
        <div className={styles.currentPlan}>
          <span>Current plan: </span>
          <span className={styles.plan}>{displayPlanName(plan)}</span>
          <span>
            {freeTrial && `${trialExpiresIn} ${maybePluralize(trialExpiresIn, 'day')} left`}{' '}
          </span>
        </div>
        {width > 768 && (
          <>
            <Button
              icon={
                freeTrial || plan === BASIC ? (
                  <Image src={darkTheme ? lightDashboard : dashboard} alt='dashboard icon' />
                ) : (
                  <Image src={darkTheme ? lightPhone : phone} alt='phone icon' />
                )
              }
              className={darkTheme ? styles.iconTitle : styles.lightIconTitle}
              appearance='transparent'
              onClick={() =>
                freeTrial || plan === BASIC ? window.open(dashboardUrl) : window.open(demoUrl)
              }
            >
              {freeTrial || plan === BASIC ? 'Dashboard' : 'Book a demo'}
            </Button>

            <Button
              icon={
                <Image
                  src={darkTheme ? lightSuccessStories : successStories}
                  alt='success Stories icon'
                />
              }
              className={darkTheme ? styles.iconTitle : styles.lightIconTitle}
              appearance='transparent'
              onClick={() => window.open(`${process.env.REACT_APP_HOME_PAGE}customers`)}
            >
              Success Stories
            </Button>

            <Button
              icon={
                freeTrial || plan === BASIC ? (
                  <Image src={bluePhone} alt='phone icon' />
                ) : (
                  <Image src={blueDashboard} alt='dashboard icon' />
                )
              }
              className={styles.demoButton}
              onClick={() =>
                freeTrial || plan === BASIC ? window.open(demoUrl) : window.open(dashboardUrl)
              }
              appearance='primary'
            >
              {freeTrial || plan === BASIC ? 'Book a demo' : 'View Dashboard'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  darkTheme: PropTypes.bool,
  plan: PropTypes.string.isRequired,
  freeTrial: PropTypes.bool.isRequired,
  trialExpiresIn: PropTypes.bool.isRequired
};

Header.defaultProps = {
  darkTheme: false
};

export default Header;
