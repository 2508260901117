import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Image } from '@fluentui/react-components';
import accountSuspended from 'assets/images/account-suspended.svg';
import arrowWhite from 'assets/images/arrow-white.svg';
import warningYellow from 'assets/images/warning-yellow.svg';
import useWindowSize from 'utils/hooks';
import styles from './SuspendedState.module.scss';

const SuspendedState = (props) => {
  const { darkTheme, isAdmin, admins } = props;
  const { width } = useWindowSize();

  const payNowUrl =
    'https://portal.office.com/adminportal/home?#/subscriptions/assets/{subscriptionId}';
  const adminsNames = admins.map((admin, index) => (
    <React.Fragment key={admin.id}>
      {index > 0 && (index === admins.length - 1 ? ' & ' : ', ')}
      <span className={styles.red}>{admin.name}</span>
    </React.Fragment>
  ));
  return (
    <div className={classNames(styles.wrapper, { [styles.darkTheme]: darkTheme })}>
      <Image src={accountSuspended} alt='account suspended icon' />
      <div className={styles.titleDiv}>
        <Image src={warningYellow} alt='warning icon' />
        <span className={styles.title}>Account Suspended</span>
      </div>
      {!isAdmin ? (
        <div>
          Please ask your admin to pay the pending invoice to resume your services immediately{' '}
          <br />
          <div>Trivia Admins - {adminsNames}</div>
        </div>
      ) : (
        <div>
          <div>
            Your team&apos;s plan has been put on the Basic Plan temporarily due to the failure of
            payment for your subscription.
            {width > 768 && <br />}
            Please pay your {isAdmin ? 'pending' : ''} invoice to resume your services immediately.
          </div>
          <Button
            icon={<Image src={arrowWhite} alt='arrow' />}
            iconPosition='after'
            className={styles.payButtonWithArrow}
            appearance='primary'
            onClick={() => window.open(payNowUrl)}
          >
            Pay now
          </Button>
        </div>
      )}
    </div>
  );
};
SuspendedState.propTypes = {
  darkTheme: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  )
};

SuspendedState.defaultProps = {
  darkTheme: false,
  admins: []
};

export default SuspendedState;
