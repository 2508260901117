import addingTrivia1 from 'assets/images/addingTrivia1.svg';
import addingTrivia2 from 'assets/images/addingTrivia2.svg';
import addingTrivia3 from 'assets/images/addingTrivia3.svg';
import launchingTrivia1 from 'assets/images/launchingTrivia1.svg';
import launchingTrivia2 from 'assets/images/launchingTrivia2.svg';
import launchingTrivia3 from 'assets/images/launchingTrivia3.svg';
import launchingTrivia4 from 'assets/images/launchingTrivia4.svg';
import launchingTrivia5 from 'assets/images/launchingTrivia5.svg';
import launchingTrivia6 from 'assets/images/launchingTrivia6.svg';
import automatingTrivia5 from 'assets/images/automatingTrivia5.svg';
import automatingTrivia6 from 'assets/images/automatingTrivia6.svg';
import enablingVirtual5 from 'assets/images/enablingVirtual5.svg';
import enablingVirtual4 from 'assets/images/enablingVirtual4.svg';
import enablingVirtual7 from 'assets/images/enablingVirtual7.svg';
import enablingVirtual8 from 'assets/images/enablingVirtual8.svg';

const addingTriviaImages = [
  {
    id: '1',
    image: addingTrivia1
  },
  { id: '2', image: addingTrivia2 },
  { id: '3', image: addingTrivia3 }
];
const launchingTriviaImages = [
  { id: '1', image: launchingTrivia1 },
  { id: '2', image: launchingTrivia2 },
  { id: '3', image: launchingTrivia3 },
  { id: '4', image: launchingTrivia4 },
  { id: '5', image: launchingTrivia5 },
  { id: '6', image: launchingTrivia6 }
];
const automatingTriviaImages = [
  { id: '1', image: launchingTrivia1 },
  { id: '2', image: launchingTrivia2 },
  { id: '3', image: launchingTrivia3 },
  { id: '4', image: launchingTrivia4 },
  { id: '5', image: automatingTrivia5 },
  { id: '6', image: automatingTrivia6 }
];
const enablingVirtualImages = [
  { id: '1', image: launchingTrivia1 },
  { id: '2', image: launchingTrivia2 },
  { id: '3', image: launchingTrivia3 },
  { id: '4', image: enablingVirtual4 },
  { id: '5', image: enablingVirtual5 },
  { id: '7', image: enablingVirtual7 },
  { id: '8', image: enablingVirtual8 }
];
const carouselItems = [
  {
    id: 0,
    title: 'Adding Trivia',
    images: addingTriviaImages
  },
  {
    id: 1,
    title: 'Launching Trivia',
    images: launchingTriviaImages
  },
  {
    id: 2,
    title: 'Automating Trivia',
    images: automatingTriviaImages
  },
  {
    id: 3,
    title: 'Enabling VirtualCoffee',
    images: enablingVirtualImages
  }
];

export default carouselItems;
