import React from 'react';
import PropTypes from 'prop-types';

const ColumnHeader = ({ columns }) => (
  <div className='column-header-container'>
    {columns.map((col, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={col.label === 'Name' && 'userNameLabel'}
        span={col.size || 3}
      >
        {col.label}
      </div>
    ))}
  </div>
);

ColumnHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default ColumnHeader;
