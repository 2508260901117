const colors = {
  dark_blue: '#1a1c42',
  grey: '#2e3452',
  dark_grey: '#2c2f5c',
  purple: '#9277ff',
  grey_blue: '#37386a',
  light_purple: '#e0e2ff',
  lilac: '#cbcce7',
  white: '#fefeff',
  tag_pink: '#ff77d1',
  tag_blue: '#93edfc',
  blue: '#5168fe',
  orange: '#ffb129',
  red: '#e75c62',
  green: '#30af38',
  error: '#ff2200',
  switch_green: '#62ffaa',
  border_blue: '#2b3788',
  gold: '#ffac33',
  silver: '#ccd6dd',
  bronze: '#ff8a3b',
  beige: '#fff8f1',
  blue_shadow: '#394ab7',
  disable_grey: '#C6C6C6',
  darkest_blue: '#10133E',
  border_orange: '#eb7634',
  light_orange: '#feecda',
  blackish: '#271B05',
  purplish: '#8E94FF',
  highlight_dark_blue: '#24275C',
  highlight_teal: '#04CFE6',
  lilac_white: '#f4f2ff',
  moon_grey: '#cccdf7;',
  slider_thumb_purple: '#8083ee',
  tooltip_background: '#4E4B66',
  pure_white: '#ffffff',
  dark_black: '#141414',
  white_smoke: '#f5f5f5',
  $light_gray: '#e0e0e0'
};
export default colors;
