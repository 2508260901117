import React from 'react';
import { useAppContext } from 'context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Image } from '@fluentui/react-components';
import shuffleIcon from 'assets/images/shuffle-icon.svg';
import styles from './Styles.module.scss';

const SelectOpinion = (props) => {
  const { handleShowOpinion, isCustomPollAllowed, handleShuffle, opinion, answers, loading } =
    props;
  const { theme } = useAppContext();

  return (
    <div
      className={classNames(styles.opinionContainer, {
        [styles.darkTheme]: theme === 'dark'
      })}
    >
      <div
        className={classNames(styles.pageTitle, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        Select Opinion
      </div>

      <div
        className={classNames(styles.questionWrapper, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div
          className={classNames(styles.questionText, {
            [styles.darkTheme]: theme === 'dark'
          })}
        >
          {opinion}
        </div>

        <div className={styles.optionsContainer}>
          {answers?.map((option, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames(styles.option, {
                [styles.darkTheme]: theme === 'dark'
              })}
            >
              {`${String.fromCharCode(index + 65)}. ${option}`}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          loading={loading}
          disable={loading}
          onClick={handleShuffle}
          icon={<Image src={shuffleIcon} alt='suffle icon' />}
        >
          Shuffle
        </Button>
      </div>

      {isCustomPollAllowed && (
        <>
          <div className={styles.orContainer}>
            <hr className={classNames(styles.line, styles.lineRight)} />
            <div
              className={classNames(styles.orText, { [styles.orDarkThemeText]: theme === 'dark' })}
            >
              or
            </div>
            <hr className={classNames(styles.line, styles.lineLeft)} />
          </div>
          <div onClick={handleShowOpinion} className={styles.launchText}>
            Launch your own poll!
          </div>
        </>
      )}
    </div>
  );
};

SelectOpinion.propTypes = {
  handleShuffle: PropTypes.func.isRequired,
  handleShowOpinion: PropTypes.func.isRequired,
  isCustomPollAllowed: PropTypes.bool.isRequired,
  opinion: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool.isRequired
};

SelectOpinion.defaultProps = {
  opinion: '',
  answers: []
};

export default SelectOpinion;
