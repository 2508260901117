import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context';
import * as microsoftTeams from '@microsoft/teams-js';
import Form from './Form';

const Pictionary = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { ts, startedVia, setTs, setStartedVia, setCurrentUserName } = useAppContext();
  const [disable, setDisable] = useState(false);

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setCurrentUserName]);

  const handleSubmit = () => {
    // disable the launch
    setDisable(true);

    // prepare the data to be sent to the task-module
    const formData = {};
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }
    // sending the form data to backend
    microsoftTeams.dialog.url.submit(
      { value: 'pictionary_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  return <Form handleSubmit={handleSubmit} disable={disable} />;
};

export default Pictionary;
