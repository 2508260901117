import { triviaTeams } from 'api';
import localStorage from 'utils/localStorage';
import { toast } from 'react-toastify';
import { LOGOUT, LOADING_USER, GET_USER } from '../types';

export const SetUserLoading = (loading) => async (dispatch) => {
  dispatch({ type: LOADING_USER, payload: loading });
};

export const Logout = () => async (dispatch) => {
  const data = await triviaTeams.post('/logout');
  if (data.ok) {
    localStorage.clearStorage();
    if (window.location.pathname.includes('/tabs')) window.location.href = `/tabs/login?origin=tab`;
    else window.location.href = '/login';
    dispatch({ type: LOGOUT });
  }
};

export const GetUserInfo = () => async (dispatch) => {
  dispatch({ type: LOADING_USER, payload: true });
  const data = await triviaTeams.get('/user');
  if (data.ok) {
    dispatch({ type: GET_USER, payload: data.info });
    dispatch({ type: LOADING_USER, payload: false });
  } else {
    toast.error('Failed to get user information');
  }
};

export const ValidateUserForTabs = async (code, tenantId) => {
  const data = await triviaTeams.get('/auth/redirect', {
    params: {
      code,
      origin: 'tab',
      state: `install|${process.env.REACT_APP_DEFAULT_KEY}|||||${tenantId}`
    }
  });
  return data;
};

export const ValidateUserForTaskModule = async (code, tenantId) => {
  const data = await triviaTeams.get('/auth/redirect', {
    params: {
      code,
      origin: 'taskModule',
      state: `install|${process.env.REACT_APP_DEFAULT_KEY}|||||${tenantId}`
    }
  });
  return data;
};

export const flagCheck = () => async () => {
  const data = await triviaTeams.get('/flag/check');
  return data.ok;
};
