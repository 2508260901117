import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import AutomateTrivia from 'components/TaskModule/AutomateTrivia';
import classNames from 'classnames';
import styles from './ImageQuiz.module.scss';

const Form = (props) => {
  const { handleSubmit, disable, showCheckbox, onCheckboxClick, autoScheduleOptIn } = props;
  const { theme, entryPoint } = useAppContext();

  return (
    <Layout
      route='all-games'
      backButtonText={entryPoint === 'Image Quiz' ? 'View all Trivia formats' : 'Back'}
      showChannelDetails
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.imageQuizFormContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch Image Quiz</div>
        <div className={styles.description}>
          Test how well you recall some of the most popular images of landmarks, brand logos,
          fictional characters, movie posters and more.
          <br />
          <br />
          Be the first to answer the image-based questions and take home the points!
        </div>
      </div>
      {showCheckbox && <AutomateTrivia value={autoScheduleOptIn} handleChange={onCheckboxClick} />}
    </Layout>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  autoScheduleOptIn: PropTypes.bool.isRequired
};

export default Form;
