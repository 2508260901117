import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BASIC } from 'redux/types';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel
} from '@fluentui/react-components';
import useWindowSize from 'utils/hooks';
import carouselItems from 'data/Home/carouselConstants';
import classNames from 'classnames';
import CarouselPlayList from './CarouselPlayList';
import Header from './Header';
import Footer from './Footer';
import styles from './Home.module.scss';
import Banner from './Banner';
import PlayList from './PlayList';
import SuspendedState from './SuspendedState';
import MobileHeader from './MobileHeader';
import UnassignedLicense from './UnassignedLicense';

const HomeView = (props) => {
  const [activeItem, setActiveItem] = useState(0);
  const { darkTheme, userDetails, subscriptionDetails, admins, planName, enagagementData } = props;
  const { freeTrial, trialExpiresIn, status, quantity, licensedCount } = subscriptionDetails;
  const { leftOut } = enagagementData;
  const { isAdmin, name } = userDetails;
  const { width } = useWindowSize();

  return (
    <>
      {width < 768 && (
        <MobileHeader
          plan={planName}
          freeTrial={freeTrial}
          trialExpiresIn={trialExpiresIn}
          darkTheme={darkTheme}
        />
      )}
      <Header
        plan={planName}
        freeTrial={freeTrial}
        trialExpiresIn={trialExpiresIn}
        darkTheme={darkTheme}
      />
      <div className={classNames(styles.pageWrapper, { [styles.darkPage]: darkTheme })}>
        {(status === 'Suspended' && (
          <SuspendedState isAdmin={isAdmin} admins={admins} darkTheme={darkTheme} />
        )) ||
        (licensedCount === 0 && quantity > 0) ? (
          <UnassignedLicense
            admins={admins}
            isAdmin={isAdmin}
            quantity={quantity}
            userName={name}
            darkTheme={darkTheme}
          />
        ) : (
          <div>
            <div className={styles.heading}>
              <div
                className={classNames(styles.welcomeText, { [styles.darkThemeWelcome]: darkTheme })}
              >
                Welcome to Trivia
              </div>
              <div
                className={classNames(styles.mantraText, { [styles.lightMantraText]: darkTheme })}
              >
                My Mantra is simple. Teams that play together, bond better.
              </div>
            </div>

            {(planName === BASIC || freeTrial) && (
              <Banner
                darkTheme={darkTheme}
                plan={planName}
                freeTrial={freeTrial}
                trialExpiresIn={trialExpiresIn}
                leftOutNumber={leftOut}
              />
            )}

            {width > 768 ? (
              <div className={styles.content}>
                <div className={styles.left}>
                  <div
                    className={classNames(styles.quickTutorial, {
                      [styles.quickTutorialDark]: darkTheme
                    })}
                  >
                    Quick tutorials
                  </div>
                  <div className={styles.playList}>
                    {carouselItems.map((item, index) => (
                      <div onClick={() => setActiveItem(index)} key={item.id}>
                        <PlayList
                          title={item.title}
                          darkTheme={darkTheme}
                          active={activeItem === index}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.right}>
                  <CarouselPlayList
                    key={carouselItems[activeItem].id}
                    images={carouselItems[activeItem].images}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.accordionWrapper}>
                <div
                  className={classNames(styles.quickTutorial, {
                    [styles.quickTutorialDark]: darkTheme
                  })}
                >
                  Quick tutorials
                </div>
                <Accordion defaultOpenItems='0' className={styles.accordion}>
                  {carouselItems.map((item, index) => (
                    <AccordionItem key={item.id} value={`${item.id}`}>
                      <AccordionHeader expandIcon=''>
                        {' '}
                        {/* expandIcon is set to null string to prevent the default icon from showing */}
                        <div
                          onClick={() => setActiveItem(index)}
                          key={item.id}
                          data-aa-class='AccordionHeaderButton'
                        >
                          <PlayList
                            title={item.title}
                            darkTheme={darkTheme}
                            active={activeItem === index}
                          />
                        </div>
                      </AccordionHeader>
                      <AccordionPanel>
                        <div className={styles.Carousel}>
                          <CarouselPlayList key={item.id} images={item.images} />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            )}
            <Footer darkTheme={darkTheme} plan={planName} freeTrial={freeTrial} />
          </div>
        )}
      </div>
    </>
  );
};

HomeView.propTypes = {
  planName: PropTypes.string.isRequired,
  admins: PropTypes.array.isRequired,
  subscriptionDetails: PropTypes.shape({
    trialExpiresIn: PropTypes.number,
    freeTrial: PropTypes.bool,
    status: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    licensedCount: PropTypes.number.isRequired
  }),
  darkTheme: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({ isAdmin: PropTypes.bool, name: PropTypes.string }),
  enagagementData: PropTypes.shape({ leftOut: PropTypes.number, limit: PropTypes.number })
    .isRequired
};

HomeView.defaultProps = {
  subscriptionDetails: PropTypes.shape({
    freeTrial: false,
    trialExpiresIn: 0
  }),
  userDetails: PropTypes.shape({ isAdmin: false, name: '' })
};

export default HomeView;
