import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAppContext } from 'context';
import { useNavigate } from 'react-router-dom';
import { DeleteVirtualCoffee, ToggleVirtualCoffee } from 'redux/actions/virtualCoffee';
import EditVirtualCoffee from './EditVirtualCoffee';
import SetupVirtualCoffee from './SetupVirtualCofee';

const VirtualCoffeePage = (props) => {
  const {
    virtualCoffee,
    isAdmin,
    isCreator,
    setOpenVCSetupModal,
    DeleteVirtualCoffee: removeVirtualCoffee,
    ToggleVirtualCoffee: toggleVirtualCoffee,
    channelMembers,
    loading,
    showDoneCTA
  } = props;
  const { tenantId } = useAppContext();
  const navigate = useNavigate();

  const handleClick = () => {
    // If we are showing the Done CTA, then primary button should open trivia-hub
    if (showDoneCTA) {
      navigate('/task-module/shortcuts');
    } else {
      setOpenVCSetupModal(true);
    }
  };

  const handleSecondaryButtonClick = () => {
    // If we are showing the Done CTA, then secondary button should open edit virtual coffee form
    setOpenVCSetupModal(true);
  };

  const handleDelete = async () => {
    removeVirtualCoffee(tenantId, virtualCoffee._id);
  };

  const handleToggle = async () => {
    toggleVirtualCoffee(tenantId, virtualCoffee._id);
  };

  if (virtualCoffee) {
    return (
      <EditVirtualCoffee
        virtualCoffee={virtualCoffee}
        isAdmin={isAdmin}
        isCreator={isCreator}
        handleClick={handleClick}
        handleDelete={handleDelete}
        handleToggle={handleToggle}
        channelMembers={channelMembers}
        disable={loading}
        showDoneCTA={showDoneCTA}
        handleSecondaryButtonClick={handleSecondaryButtonClick}
      />
    );
  }

  return <SetupVirtualCoffee handleClick={handleClick} />;
};

VirtualCoffeePage.propTypes = {
  virtualCoffee: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    frequency: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    excludedUsers: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  isAdmin: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool.isRequired,
  setOpenVCSetupModal: PropTypes.func.isRequired,
  DeleteVirtualCoffee: PropTypes.func.isRequired,
  ToggleVirtualCoffee: PropTypes.func.isRequired,
  channelMembers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  showDoneCTA: PropTypes.bool.isRequired
};

VirtualCoffeePage.defaultProps = {
  virtualCoffee: null
};

const mapStateToProps = ({
  virtualCoffee: {
    isAdmin,
    isCreator,
    virtualCoffee,
    channelMembers,
    deleteVirtualCoffeeLoading,
    toggleVirtualCoffeeLoading
  }
}) => ({
  isAdmin,
  isCreator,
  virtualCoffee,
  channelMembers,
  loading: deleteVirtualCoffeeLoading || toggleVirtualCoffeeLoading
});

export default connect(mapStateToProps, { DeleteVirtualCoffee, ToggleVirtualCoffee })(
  VirtualCoffeePage
);
