import { GET_CATEGORIES, GET_GROUPED_CATEGORIES } from '../types';

const INITIAL_STATE = { trivia: [], categories: [], specialCategory: {} };

const categoriesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, trivia: action.payload };
    case GET_GROUPED_CATEGORIES: {
      return {
        ...state,
        categories: action.payload.categories,
        specialCategory: action.payload.specialCategory,
        customCategory: action.payload.customCategory
      };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
