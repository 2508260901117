import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Label } from '@fluentui/react-components';
import dropdowns from 'data/dropdowns';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import AutomateTrivia from 'components/TaskModule/AutomateTrivia';
import { getDropdownArray } from 'utils';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const Form = (props) => {
  const {
    formFields,
    handleSubmit,
    disable,
    limitFlag,
    showCheckbox,
    autoScheduleOptIn,
    onCheckboxClick,
    handleChange
  } = props;
  const { theme } = useAppContext();

  return (
    <Layout
      showChannelDetails
      route='all-games'
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.formContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch True or False</div>
        <Label>Speed</Label>
        <Dropdown
          placeholder='20s per question (Default)'
          defaultSelectedOptions={['20s per question']}
          onOptionSelect={(e, value) => handleChange(value)}
        >
          {getDropdownArray(dropdowns.speed, '20s per question', formFields.plan)}
        </Dropdown>
        {limitFlag && <div className={styles.error}>⚠️ You cannot select speed on your plan</div>}
        {showCheckbox && (
          <AutomateTrivia value={autoScheduleOptIn} handleChange={onCheckboxClick} />
        )}
      </div>
    </Layout>
  );
};

Form.propTypes = {
  formFields: PropTypes.shape({
    speed: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    channelName: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired
  }).isRequired,
  handleSubmit: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  limitFlag: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  autoScheduleOptIn: PropTypes.bool.isRequired
};

export default Form;
