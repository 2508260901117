import React from 'react';
import { useAppContext } from 'context';
import { Divider } from '@fluentui/react-components';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Footer = () => {
  const { theme } = useAppContext();

  return (
    <div className={classNames(styles.footerWrapper, { [styles.darkTheme]: theme === 'dark' })}>
      <Divider className={styles.divider} />
      <div className={styles.title}>Don&apos;t Know how VirtualCoffee works?</div>
      <div className={styles.description}>
        VirtualCoffee is a brilliant way to bond with your teammates and make friends at work.
        <span
          onClick={() =>
            window.open(
              'https://support.springworks.in/portal/en/kb/trivia/trivia-for-microsoft-teams/virtualcoffee'
            )
          }
          className={styles.learnMore}
        >
          &nbsp;Learn more.
        </span>
      </div>
    </div>
  );
};

export default Footer;
