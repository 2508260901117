import React from 'react';
import './styles.scss';
import { ReactComponent as TriviaBranding } from 'assets/images/trivia-logo.svg';
import * as microsoftTeams from '@microsoft/teams-js';
import localStorage from 'utils/localStorage';
import Login from '../Login';

class Config extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyAuthed: false,
      error: null
    };
    this.signInUser = this.signInUser.bind(this);
  }

  componentDidMount() {
    microsoftTeams.app.initialize();
    microsoftTeams.app.notifySuccess();
  }

  signInUser() {
    const authenticateParameters = {
      url: `${process.env.REACT_APP_TAB_AUTH_START}?origin=tab`,
      width: 600,
      height: 535
    };

    microsoftTeams.authentication
      .authenticate(authenticateParameters)
      .then((result) => {
        this.setState({ alreadyAuthed: true });
        microsoftTeams.pages.config.setValidityState(true);
        microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
          localStorage.setFlag(decodeURIComponent(result.flag));
          localStorage.set('userId', result.userId);

          microsoftTeams.pages.config.setConfig({
            websiteUrl: process.env.REACT_APP_BASE_URL,
            contentUrl: `${process.env.REACT_APP_BASE_URL}/tabs/leaderboard`,
            entityId: 'trivia-tab',
            suggestedDisplayName: 'Trivia Leaderboard'
          });
          saveEvent.notifySuccess();
        });
      })
      .catch((reason) => {
        this.setState({ error: reason });
      });
  }

  render() {
    const { alreadyAuthed, error } = this.state;
    return (
      <div className='config-container'>
        <div className='trivia-logo'>
          <TriviaBranding width={200} height={50} data-testid='trivia-logo' />
        </div>
        <div className='content-wrapper'>
          {error && <div className='error'>Failed to authenticate user due to reason: {error}</div>}
          {alreadyAuthed ? (
            <div className='hint'>
              Add Trivia tab to keep track of your team&apos;s real time leaderboard
            </div>
          ) : (
            <Login signInUser={this.signInUser} origin='config' />
          )}
        </div>
      </div>
    );
  }
}

export default Config;
