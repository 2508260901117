import axios from 'axios';
import localStorage from 'utils/localStorage';
import { toast } from 'react-toastify';

const web = axios.create({
  baseURL: `${process.env.REACT_APP_WEB_API_URL}`,
  headers: {
    Accept: 'application/json'
  }
});

const teams = axios.create({
  baseURL: `${process.env.REACT_APP_TEAMS_API_URL}`,
  headers: {
    Accept: 'application/json'
  }
});

const forceLogout = () => {
  localStorage.clearStorage();
  const origin = window.location.pathname.includes('/task-module') ? 'taskModule' : 'tab';
  window.location.href = `/tabs/login?origin=${origin}`;
  toast.info('Session expired!');
};
const requestInterceptor = (config) => {
  const configCopy = config;

  if (configCopy.url.includes('/task-module') || configCopy.url.includes('/home')) {
    configCopy.headers.Authorization = process.env.REACT_APP_TEAMS_AUTH_TOKEN;
  } else if (configCopy.url.includes('/validate-token')) {
    // TODO add internal auth token for prod
    configCopy.headers.Authorization = process.env.REACT_APP_INTERNAL_AUTH_TOKEN;
  } else {
    configCopy.headers.Authorization = localStorage.getToken();
  }

  return configCopy;
};
const successResponseInterceptor = (response) => response.data;
const failureResponseInterceptor = (error) => {
  if (
    error?.response?.status === 403 ||
    (error?.response?.status === 401 && error?.response?.data?.msg !== 'Unauthorized access')
  ) {
    forceLogout();
  } else if (![400, 404, 409].includes(error.response.status)) {
    toast.error('Something went wrong!');
    return { ok: false };
  } else {
    return error.response.data;
  }
  return Promise.reject(error);
};

web.interceptors.request.use(requestInterceptor);
teams.interceptors.request.use(requestInterceptor);
web.interceptors.response.use(successResponseInterceptor, failureResponseInterceptor);
teams.interceptors.response.use(successResponseInterceptor, failureResponseInterceptor);

export default web;
export const triviaTeams = teams;
