import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, useId } from '@fluentui/react-components';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const Form = (props) => {
  const { handleSubmit, disable, handleChange, formFields, erroMessage, usersCount } = props;
  const { theme } = useAppContext();
  const inputId = useId('input-with-placeholder');

  return (
    <Layout
      showChannelDetails
      route='all-games'
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.formContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch Gotcha!</div>
        <Label htmlFor={inputId}>Enter the number of responses players can enter</Label>
        <Input
          className={erroMessage ? styles.errorInput : styles.input}
          placeholder={` Number of responses (1${
            usersCount > 1 ? ` - ${usersCount < 20 ? usersCount : 20}` : ''
          })`}
          value={formFields.number}
          contentAfter={<Label className={styles.errorMessage}>{erroMessage}</Label>}
          onChange={(e, { value }) => handleChange(value)}
          appearance='outline'
          data-testid='number-input'
        />
      </div>
    </Layout>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  erroMessage: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  formFields: PropTypes.shape({
    number: PropTypes.number
  }).isRequired,
  usersCount: PropTypes.number.isRequired
};

export default Form;
