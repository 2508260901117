import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as microsoftTeams from '@microsoft/teams-js';
import { Button, Image } from '@fluentui/react-components';
import { BASIC } from 'redux/types';
import warning from 'assets/images/warning.svg';
import { maybePluralize } from 'utils';
import styles from './Banner.module.scss';

const Banner = (props) => {
  const { darkTheme, plan, trialExpiresIn, freeTrial, leftOutNumber } = props;

  const openPurchaseExperience = () => {
    microsoftTeams.app.initialize();
    microsoftTeams.monetization.openPurchaseExperience(() => Promise.resolve());
    microsoftTeams.app.notifySuccess();
  };

  const trialExpirationMessage = freeTrial
    ? `${trialExpiresIn}  ${maybePluralize(
        trialExpiresIn,
        'day'
      )} left for your Free Trial to expire.`
    : '';

  const basicLimitationsMessage =
    !freeTrial && leftOutNumber > 0
      ? `${leftOutNumber} ${
          leftOutNumber === 1 ? 'user' : 'users'
        } couldn't play due to limitations`
      : '';

  return (
    <div className={classNames(styles.wrapper, { [styles.darkTheme]: darkTheme })}>
      <div className={styles.plan}>
        {(freeTrial && 'Free Trial') || (plan === BASIC && 'Basic Plan')}
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.textDiv}>
          {trialExpirationMessage}
          <div>{basicLimitationsMessage}</div>
          <div className={styles.subTitle}>
            {freeTrial ? (
              'You will be shifted to a Basic Plan after your Free Trial expires. Upgrade to remove all limitations'
            ) : (
              <div>
                Your current plan lets only <b>3 players</b> per Trivia game. Upgrade to remove all
                the limitations.
              </div>
            )}
          </div>
          {plan === BASIC && (
            <div className={styles.limit}>
              <Image className={styles.image} src={warning} alt='warning' />
              <span>
                Basic plan limits: <b>3 users</b> per Trivia game
              </span>
            </div>
          )}
        </div>
        <Button
          className={styles.upgradeButton}
          onClick={openPurchaseExperience}
          appearance='primary'
        >
          Upgrade now
        </Button>
      </div>
    </div>
  );
};

Banner.propTypes = {
  darkTheme: PropTypes.bool,
  plan: PropTypes.string.isRequired,
  trialExpiresIn: PropTypes.number.isRequired,
  freeTrial: PropTypes.bool.isRequired,
  leftOutNumber: PropTypes.number.isRequired
};

Banner.defaultProps = {
  darkTheme: false
};
export default Banner;
