const DAYS = [
  { name: 'Mon', value: 'Monday' },
  { name: 'Tue', value: 'Tuesday' },
  { name: 'Wed', value: 'Wednesday' },
  { name: 'Thu', value: 'Thursday' },
  { name: 'Fri', value: 'Friday' },
  { name: 'Sat', value: 'Saturday' },
  { name: 'Sun', value: 'Sunday' }
];

export const daysToNaturalLanguage = (daysArray) => {
  let days = daysArray?.map((day) => day.substring(0, 3)).join(', ');
  const index = days.lastIndexOf(', ');
  days = `${days.slice(0, index)}${days.slice(index).replace(', ', ' & ')}`;
  return days;
};

export default DAYS;
