import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Row = ({ columns }) => (
  <div className='row-container'>
    <div className='row'>
      {columns.map((col, index) => (
        <div
          span={col.size || 3}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {col.label}
        </div>
      ))}
    </div>
  </div>
);

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default Row;
