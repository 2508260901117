import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useAppContext } from 'context';
import Loading from 'components/Loading';
import {
  GetChannelMembers,
  SetupVirtualCoffee,
  UpdateVirtualCoffee
} from 'redux/actions/virtualCoffee';
import dropdowns from 'data/dropdowns';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { alphabeticalTimezones as timezones, toTzValue } from 'data/timezones';
import { toast } from 'react-toastify';
import VCForm from './VCForm';

const timeBetween = () => {
  const time = moment('04:30', 'hh:mm')
    .add(5 * Math.floor(Math.random() * 13), 'minutes')
    .format('HH:mm');

  return `${time} pm`;
};

const Form = (props) => {
  const {
    setOpenVCSetupModal,
    channelMembers,
    GetChannelMembers: fetchChannelMembers,
    channelMembersLoading,
    SetupVirtualCoffee: addVirtualCoffee,
    loading,
    virtualCoffee,
    UpdateVirtualCoffee: updateVirtualCoffee,
    setShowDoneCTA
  } = props;

  const [formFields, setFormFields] = useState({
    users: virtualCoffee
      ? virtualCoffee.excludedUsers?.map((userId) => {
          const userObject = channelMembers.find((user) => user.userId === userId);
          return userObject.userName;
        })
      : [],
    time: virtualCoffee ? virtualCoffee.time : timeBetween(),
    day: virtualCoffee ? virtualCoffee.dayOfTheWeek : 'Monday',
    frequency: virtualCoffee
      ? dropdowns.virtualCoffeeFrequency.find((vc) => vc.key === virtualCoffee.frequency.toString())
          .content
      : 'Every two weeks',
    timezone: virtualCoffee ? toTzValue(virtualCoffee.timezone) : toTzValue(momentTz.tz.guess())
  });

  const {
    teamId,
    serviceUrl,
    teamName,
    channelId,
    tenantId,
    chatId,
    currentUserName,
    currentUserId
  } = useAppContext();

  useEffect(() => {
    const conversationId = channelId || chatId;
    fetchChannelMembers(conversationId, serviceUrl, tenantId);
  }, []);

  const handleChange = (value, key) => {
    if (key === 'timezone') {
      setFormFields({ ...formFields, [key]: value });
      return;
    }
    setFormFields({ ...formFields, [key]: value.optionValue });
  };

  const handleExcludeUsers = {
    onAdd: (item) => {
      setFormFields({
        ...formFields,
        users: item.selectedOptions
      });
    },
    onRemove: (item) => {
      setFormFields({
        ...formFields,
        users: formFields.users.filter((i) => i.id !== item.id)
      });
    }
  };

  if (channelMembersLoading) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  const handleSubmit = async () => {
    if (channelMembers.length < 2) {
      toast.error('You need at least two people to setup a virtual coffee in your team');
      return;
    }
    const body = {
      dayOfTheWeek: formFields.day,
      time: formFields.time,
      serviceUrl,
      teamId,
      teamName,
      channel: channelId,
      user: currentUserId,
      userName: currentUserName
    };

    if (formFields.timezone) {
      body.timezone = timezones.find(
        (timezone) => timezone.value === parseInt(formFields.timezone, 10)
      ).name;
    }

    body.frequency = dropdowns.virtualCoffeeFrequency.find(
      (frequency) => frequency.content === formFields.frequency
    ).key;

    if (formFields.users.length) {
      body.excludedUsers = formFields.users.map(
        (user) => channelMembers.find((u) => u.userName === user)?.userId
      );
    }

    let response;

    if (virtualCoffee) {
      response = await updateVirtualCoffee(tenantId, virtualCoffee._id, body);
      if (response.ok) {
        setOpenVCSetupModal(false);
        toast.success('VirtualCoffee updated successfully');
      }
    } else {
      response = await addVirtualCoffee(tenantId, body);
      if (response.ok) {
        setOpenVCSetupModal(false);
        toast.success('VirtualCoffee setup successfully');
      }
    }
    setShowDoneCTA(true);
  };

  return (
    <VCForm
      formFields={formFields}
      handleChange={handleChange}
      users={channelMembers.map((member) => ({ header: member.userName, id: member.userId }))}
      handleBack={() => setOpenVCSetupModal(false)}
      handleExcludeUsers={handleExcludeUsers}
      handleSubmit={handleSubmit}
      disable={loading}
      edit={!!virtualCoffee}
    />
  );
};

Form.propTypes = {
  channelMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  GetChannelMembers: PropTypes.func.isRequired,
  setOpenVCSetupModal: PropTypes.func.isRequired,
  channelMembersLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  SetupVirtualCoffee: PropTypes.func.isRequired,
  virtualCoffee: PropTypes.shape({
    excludedUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
    time: PropTypes.string.isRequired,
    dayOfTheWeek: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired
  }),
  UpdateVirtualCoffee: PropTypes.func.isRequired,
  setShowDoneCTA: PropTypes.func.isRequired
};

Form.defaultProps = {
  virtualCoffee: null
};

const mapStateToProps = ({
  virtualCoffee: {
    channelMembers,
    channelMembersLoading,
    createVirtualCoffeeLoading,
    virtualCoffee
  }
}) => ({
  channelMembers,
  channelMembersLoading,
  loading: createVirtualCoffeeLoading,
  virtualCoffee
});

export default connect(mapStateToProps, {
  GetChannelMembers,
  SetupVirtualCoffee,
  UpdateVirtualCoffee
})(Form);
