import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import localStorage from 'utils/localStorage';
import styles from './styles.module.scss';

class AuthStart extends React.Component {
  componentDidMount() {
    microsoftTeams.app.initialize();

    microsoftTeams.app.getContext().then((context) => {
      const params = new URLSearchParams(window.location.search);
      const state = localStorage.setTabState();
      localStorage.set('origin', params.get('origin'));
      localStorage.clearError();

      let queryParams = {
        client_id: process.env.REACT_APP_TEAMS_CLIENT_ID,
        response_type: 'code',
        response_mode: 'query',
        scope:
          'https://graph.microsoft.com/User.Read https://graph.microsoft.com/User.ReadBasic.All https://graph.microsoft.com/Team.ReadBasic.All https://graph.microsoft.com/Channel.ReadBasic.All https://graph.microsoft.com/AppCatalog.Read.All https://graph.microsoft.com/Chat.ReadBasic offline_access',
        redirect_uri: process.env.REACT_APP_TAB_AUTH_END,
        state,
        login_hint: context.user.loginHint
      };
      queryParams = new URLSearchParams(queryParams).toString();
      const authorizeEndpoint = `https://login.microsoftonline.com/${context.user.tenant.id}/oauth2/v2.0/authorize?${queryParams}`;
      window.location.assign(authorizeEndpoint);
    });
  }

  render() {
    return (
      <div className={styles.auth}>
        <div>Starting Authentication...</div>
      </div>
    );
  }
}

export default AuthStart;
