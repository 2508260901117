import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import localStorage from 'utils/localStorage';
import { ValidateUserForTabs, ValidateUserForTaskModule } from 'redux/actions/user';
import styles from './styles.module.scss';

const AuthEnd = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const notifyMSTeams = (resp) => {
    if (!resp.ok) {
      microsoftTeams.authentication.notifyFailure(resp.error);
    } else {
      microsoftTeams.authentication.notifySuccess({
        flag: resp.flag,
        userId: resp.userId,
        origin: resp.origin
      });
    }
  };
  useEffect(() => {
    microsoftTeams.app.initialize();
    localStorage.clearError();

    const params = new URLSearchParams(location.search);
    if (params.get('error')) {
      localStorage.setError(params.toString());
      microsoftTeams.authentication.notifyFailure(params.get('error'));
    } else if (params.get('code')) {
      const expectedState = localStorage.getTabState();
      if (!expectedState || expectedState !== params.get('state')) {
        localStorage.setError(params.toString());
        microsoftTeams.authentication.notifyFailure('StateDoesNotMatch');
      } else {
        microsoftTeams.app.getContext().then(async (context) => {
          const code = params.get('code');
          const origin = localStorage.get('origin');
          const tenantId = context.user.tenant.id;
          const resp = await (origin === 'tab'
            ? ValidateUserForTabs(code, tenantId)
            : ValidateUserForTaskModule(code, tenantId));
          setLoading(false);
          notifyMSTeams(resp);
        });
      }
    } else {
      localStorage.setError(params.toString());
      microsoftTeams.authentication.notifyFailure('UnexpectedFailure');
    }
  }, [location.search]);
  return loading ? (
    <div className={styles.auth}>
      <div className={styles.spinner} />
      Authenticating...
    </div>
  ) : (
    <div className={styles.auth}>Authentication finished.</div>
  );
};

export default AuthEnd;
