import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/TaskModule/Layout';
import classNames from 'classnames';
import controller from 'assets/images/shortcuts/controller-icon.svg';
import home from 'assets/images/shortcuts/home-icon.svg';
import task from 'assets/images/shortcuts/task-icon.svg';
import cup from 'assets/images/shortcuts/vc-icon.svg';
import Card from 'pages/task.modules/Shortcuts/Card';
import { useAppContext } from 'context';
import styles from './Shortcuts.module.scss';

const ShortcutsPage = (props) => {
  const { handleAction, totalAutomations } = props;
  const { theme } = useAppContext();
  const [defaultHighlightedState, setDefaultHighlightedState] = useState(true);

  return (
    <Layout
      className={classNames(styles.shortcutsContainer, { [styles.darkTheme]: theme === 'dark' })}
    >
      {/* Header/Banner Card */}
      <div className={classNames(styles.toastWrapper)}>
        <div className={styles.title}>Welcome to Trivia</div>
        <div className={styles.slogan}>
          My mantra is simple. Teams that play together, bond better.
        </div>
      </div>

      {/* Redirection Card */}
      <div data-testid='cards' className={styles.cardsContainer}>
        <Card
          setHighlightedState={setDefaultHighlightedState}
          image={controller}
          title='Launch Trivia'
          description='Quizzes, True or False & more!'
          theme={theme}
          handleClick={() => handleAction('start_all_games')}
          className={classNames({ [styles.triviaGameCard]: defaultHighlightedState })}
        />
        <Card
          image={task}
          automate
          totalAutomations={totalAutomations}
          setHighlightedState={setDefaultHighlightedState}
          title='Automate Trivia'
          description='Switch to autopilot'
          theme={theme}
          handleClick={() => handleAction('scheduler')}
        />
        <Card
          setHighlightedState={setDefaultHighlightedState}
          image={cup}
          title='VirtualCoffee'
          description='Introduce teammates over coffee'
          theme={theme}
          handleClick={() => handleAction('virtual_coffee')}
        />
        <Card
          setHighlightedState={setDefaultHighlightedState}
          image={home}
          title='Home'
          description='Take control of Trivia'
          theme={theme}
          handleClick={() => handleAction('home')}
        />
      </div>
    </Layout>
  );
};

ShortcutsPage.propTypes = {
  handleAction: PropTypes.func.isRequired,
  totalAutomations: PropTypes.number.isRequired
};

export default ShortcutsPage;
