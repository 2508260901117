/* eslint-disable no-case-declarations */
import { cloneDeep } from 'lodash';
import {
  GET_UPCOMING_SCHEDULES,
  TEAM_NAME,
  ADD_SCHEDULE,
  MODIFY_SCHEDULE,
  REMOVE_SCHEDULE,
  CHAT_NAME,
  IS_USER_ADMIN,
  GET_ALL_ADMINS
} from '../types';

const INITIAL_STATE = {
  upcoming: [],
  schedules: [],
  teamName: null,
  chatName: null,
  isAdmin: null,
  admins: []
};

const schedulesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_UPCOMING_SCHEDULES:
      return { ...state, upcoming: cloneDeep(action.payload) };
    case TEAM_NAME:
      return { ...state, teamName: action.payload };
    case IS_USER_ADMIN:
      return { ...state, isAdmin: action.payload };
    case GET_ALL_ADMINS:
      return { ...state, admins: action.payload };
    case CHAT_NAME:
      return { ...state, chatName: action.payload };
    case ADD_SCHEDULE:
    case MODIFY_SCHEDULE:
      const { id } = action.payload;
      const upcoming = cloneDeep(state.upcoming);
      const index = upcoming.findIndex((schedule) => schedule.id === id);
      if (index > -1) {
        upcoming[index] = action.payload;
      } else {
        upcoming.push(action.payload);
      }
      return { ...state, upcoming };
    case REMOVE_SCHEDULE: {
      const schedules = cloneDeep(state.upcoming);
      const deleteIndex = schedules.findIndex((schedule) => schedule.id === action.payload);
      if (deleteIndex > -1) schedules.splice(deleteIndex, 1);
      return { ...state, upcoming: schedules };
    }
    default:
      return state;
  }
};

export default schedulesReducer;
