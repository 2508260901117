/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo } from 'react';
import Layout from 'components/TaskModule/Layout';
import { Image } from '@fluentui/react-components';
import classNames from 'classnames';
import { useAppContext } from 'context';
import { useLocation, useNavigate } from 'react-router-dom';
import TrueOrFalse from 'assets/images/allgames/TrueOrFalse.svg';
import WaterCooler from 'assets/images/allgames/WaterCooler.svg';
import UnpopularOpinions from 'assets/images/allgames/UnpopularOpinions.svg';
import Gotcha from 'assets/images/allgames/Gotcha.svg';
import Pictionary from 'assets/images/allgames/Pictionary.svg';
import ImageQuizzes from 'assets/images/allgames/ImageQuizzes.svg';
import CustomPoll from 'assets/images/allgames/CustomPoll.svg';
import controller from 'assets/images/shortcuts/controller-icon.svg';
import styles from './Styles.module.scss';
import Card from './Card';

const allGamesData = [
  {
    name: 'Quizzes',
    icon: <Image width='30' src={controller} />,
    recommended: true,
    route: '/task-module/trivia-quizzes'
  },
  {
    name: 'Image Quizzes',
    icon: <Image width='21' src={ImageQuizzes} />,
    recommended: true,
    route: '/task-module/image-quiz'
  },
  {
    name: 'True Or False',
    icon: <Image width='24' src={TrueOrFalse} />,
    recommended: true,
    route: '/task-module/true-or-false'
  },
  {
    name: 'Water Cooler',
    icon: <Image width='24' src={WaterCooler} />,
    recommended: false,
    route: '/task-module/watercooler'
  },
  {
    name: 'Unpopular Opinions',
    icon: <Image width='24' src={UnpopularOpinions} />,
    recommended: false,
    route: '/task-module/unpopular-opinion'
  },
  {
    name: 'Gotcha!',
    icon: <Image width='20' src={Gotcha} />,
    recommended: false,
    route: '/task-module/gotcha'
  },
  {
    name: 'Pictionary',
    icon: <Image width='20' src={Pictionary} />,
    recommended: false,
    route: '/task-module/pictionary'
  },
  {
    name: 'Custom Poll',
    icon: <Image width='30' src={CustomPoll} />,
    recommended: false,
    route: '/task-module/custom-poll'
  }
];

const AllGames = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const {
    theme,
    setTs,
    setStartedVia,
    setPlan,
    setServiceUrl,
    entryPoint,
    setEntryPoint,
    setCurrentUserName
  } = useAppContext();
  const navigate = useNavigate();

  // Updating the context param variables
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setEntryPoint((prev) => prev || params.get('entryPoint') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [params, setTs, setStartedVia, setPlan, setServiceUrl, setEntryPoint, setCurrentUserName]);

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <Layout
      route='shortcuts'
      backButtonText={
        ['Instant Quiz', 'All My Games'].includes(entryPoint) ? 'Open Trivia Hub' : 'Back'
      }
    >
      <div
        className={classNames(styles.allGamesWrapper, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.header}>
          <div
            className={classNames(styles.pageTitle, {
              [styles.darkTheme]: theme === 'dark'
            })}
          >
            Launch Trivia
          </div>

          <div
            className={classNames(styles.subHeading, {
              [styles.darkTheme]: theme === 'dark'
            })}
          >
            Select the Trivia format to play
          </div>
        </div>

        <div className={styles.gridContainer}>
          {allGamesData.map((game, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <Card key={index} handleClick={handleClick} game={game} theme={theme} />;
          })}
        </div>
      </div>
    </Layout>
  );
};

export default AllGames;
