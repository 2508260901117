/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import styles from './Styles.module.scss';

const Card = ({ game, theme, handleClick }) => (
  <div
    data-testid={game.name}
    onClick={() => handleClick(game.route)}
    className={classNames(styles.CardContainer, {
      [styles.darkTheme]: theme === 'dark'
    })}
  >
    {game.recommended && <div className={styles.recommended}>Recommended</div>}
    <div className={styles.icon}>{game.icon}</div>
    <div
      className={classNames(styles.name, {
        [styles.darkTheme]: theme === 'dark'
      })}
    >
      {game.name}
    </div>
  </div>
);

export default Card;
