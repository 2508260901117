import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, Spinner } from '@fluentui/react-components';
import { useAppContext } from 'context';
import useWindowSize from 'utils/hooks';
import styles from './Card.module.scss';

const Card = (props) => {
  const {
    image,
    title,
    description,
    className,
    setHighlightedState,
    handleClick,

    automate,
    totalAutomations
  } = props;
  const { theme } = useAppContext();
  const { width } = useWindowSize();

  return (
    <div
      className={classNames(styles.cardContainer, className, {
        [styles.darkTheme]: theme === 'dark'
      })}
      onMouseEnter={() => setHighlightedState(false)}
      onClick={handleClick}
      data-testid='card-container'
    >
      <div
        className={classNames(styles.icon, {
          [styles.noOfAutomations]: automate
        })}
      >
        <Image src={image} data-testid='image' />

        {width > 450 && automate && (
            [undefined, null].includes(totalAutomations) ? (
              <Spinner size='extra-small'/>
            ) : (
              <div
                className={classNames(styles.noOfAutomationsText, {
                  [styles.automationsDarkThemeText]: theme === 'dark'
                })}
              >
                <div
                  className={classNames(styles.circle, styles.greenCircle, {
                    [styles.redCircle]: !totalAutomations
                  })}
                />{' '}
                {totalAutomations
                  ? `${totalAutomations} active automations`
                  : 'No automations active'}
              </div>
            )
        )}
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  setHighlightedState: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  automate: PropTypes.bool.isRequired,
  totalAutomations: PropTypes.number.isRequired
};

Card.defaultProps = {
  className: null
};

export default Card;
